import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "../constants";
import { useSearchParams } from "react-router-dom";

const useURL = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const getURLLanguage = async () => {
    const lang = searchParams.get("lang")!;

    if (SUPPORTED_LANGUAGES.includes(lang)) {
      await i18n.changeLanguage(lang);
    }
  };

  const hasParameter = (parameter: URLParams) => {
    return searchParams.has(parameter);
  }

  return {
    getURLLanguage,
    hasParameter
  }
};

export default useURL;
