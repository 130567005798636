import { ElementRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../../App.css";
import "./style.css";
import { isMobile } from "react-device-detect";
import {
  Annotations,
  HelpModal,
  QRModal,
  Error,
  ARButton,
  ModelDimensions,
  ModelViewer,
  DropodownMenu,
  LanguageSelector,
  CallToActionButton,
} from "../../components";
import { useTranslation } from "react-i18next";
import { HelpMessages } from "../../utils";
import { DEFAULT_COLOR } from "../../constants";
import { Close, SettingsBackupRestore } from "@mui/icons-material";
import { useProduct, useURL,  } from "../../hooks";

const ProductViewer = () => {
  const { model_id } = useParams();
  const { t, ready } = useTranslation();

  const { product, getModel, addVisit, addAugmentation, visitAdded } =
    useProduct(model_id!);
  const url = useURL()

  const [modalOpen, setModalOpen] = useState({
    qr: false,
    instructions: false,
  });
  const [error, setError] = useState<any>();

  const [isSmallWindow, setIsSmallWindow] = useState(false);
  const [buttonsAreVisible, setButtonsAreVisible] = useState(false);

  const maxWidth = 300;
  const maxHeight = 250;

  const [lastSync, setLastSync] = useState(new Date().getTime());

  const [originalMaterials, setOriginalMaterial] = useState<
    Array<ProductMaterial>
  >([]);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

  const [visibility, setVisibility] = useState("show");

  const [showMeasurements, setShowMeasurements] = useState<boolean>(false);
  const [openVariants, setOpenVariants] = useState<boolean>(false);

  const [hideOptions, setHideOptions] = useState<boolean>(false);

  const [canemeraDidMove, setCameraDidMove] = useState<boolean>(false);

  const hotspotXYRef = useRef<ElementRef<"button">>(null);
  const hotspotYZRef = useRef<ElementRef<"button">>(null);
  const hotspotXZRef = useRef<ElementRef<"button">>(null);

  useEffect(() => {
    if (product) {
      if (url.hasParameter("hideoptions")) {
        setHideOptions(true);
      }

      document.getElementById("model-viewer")?.addEventListener("load", () => {
        if (document.getElementById("selected-variant")) {
          document.getElementById("selected-variant")!.style.backgroundColor =
            product.variants[0].color;
          changeMaterials(product.variants[0].materials);
        }
      });
      localStorage.setItem("color", product.augmentationButton?.color!);

      if (!visitAdded) {
        addVisit();
      }
    }
  }, [product]);

  useEffect(() => {
    if (ready) {
      checkWindowSize();
      if (!model_id) {
        setError(t("error.you_must_indicate_a_product"));
      }
    }
  }, [ready]);

  useEffect(() => {
    const hide = () => setVisibility("hide");
    const show = () => setVisibility("show");

    const handleVisibilityChange = () => {
      const timeThreshold = 2;
      //limite de minimizado para refrescar pagina
      if (document.hidden) {
        hide();
      } else {
        show();
        if ((new Date().getTime() - lastSync) / 1000 > timeThreshold) {
          if (model_id) {
            getModel();
          }
        } else {
          setLastSync(new Date().getTime());
        }
      }
    };

    window.addEventListener("focus", show, false);
    window.addEventListener("blur", hide, false);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", show);
      window.removeEventListener("blur", hide);
    };
  }, [setVisibility]);

  window.onresize = () => {
    checkWindowSize();
  };

  const checkWindowSize = () => {
    setIsSmallWindow(
      window.innerWidth <= maxWidth || window.innerHeight <= maxHeight
    );
  };

  const handleClose = () => {
    window.parent.postMessage({ "qr-open": false }, "*");
    setModalOpen({ ...modalOpen, qr: false });
  };

  const getSrc = () => {
    let src = product!.androidModelUrl;
    if (product!.productUrl) {
      src += "?title=" + product!.store + "&link=" + product!.productUrl;
    }
    return src;
  };

  const listVariants = () => {
    const variantElements: Array<JSX.Element> = [];
    if (product!.variants && product!.variants.length > 1) {
      product!.variants.map((variant, index) => {
        variantElements.push(
          <div
            id={`variant-selector-${index}`}
            key={`variant-selector-${index}`}
            className={selectedVariantIndex == index ? "variant-btn" : ""}
            onClick={() => {
              setSelectedVariantIndex(index);
              changeMaterials(variant.materials);
              document.getElementById(
                "selected-variant"
              )!.style.backgroundColor = variant.color;
            }}
            style={{
              background: variant.color,
              height: 35,
              width: 35,
              borderRadius: 30,
              cursor: "pointer",
              borderStyle: "solid",
              borderWidth: 0.2,
              borderColor: "rgb(214, 214, 214)",
              marginBottom: "10px",
            }}
          ></div>
        );
      });
    }
    return variantElements;
  };

  const changeMaterials = (materials: Array<ProductMaterial>) => {
    const viewer: any = document.getElementById("model-viewer");
    if (originalMaterials.length <= 0) {
      const mats: Array<ProductMaterial> = [];
      viewer?.model.materials.forEach((material: any, index: number) => {
        if (!materialIsGroup(material)) {
          mats.push({
            index: index,
            color: material.pbrMetallicRoughness.baseColorFactor,
          });
        }
        setOriginalMaterial(mats);
      });
    } else {
      originalMaterials.forEach((material) => {
        viewer.model.materials[
          material.index
        ].pbrMetallicRoughness.setBaseColorFactor(material.color);
      });
    }

    materials.forEach((material) => {
      viewer.model.materials[
        material.index
      ].pbrMetallicRoughness.setBaseColorFactor(hextoRGB(material.color));
    });
  };

  const hextoRGB = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
          parseInt(result[1], 16) / 255,
          parseInt(result[2], 16) / 255,
          parseInt(result[3], 16) / 255,
          1,
        ]
      : null;
  };

  const materialIsGroup = (material: any) => {
    let isGroup = false;
    for (var [key, value] of material[
      Object.getOwnPropertySymbols(material)[2]
    ].entries()) {
      if (key.type == "Group") {
        isGroup = true;
        break;
      }
    }

    return isGroup;
  };

  return (
    <div>
      {product ? (
        <ModelViewer
          glb={getSrc()}
          usdz={product!.iosModelUrl}
          placement={product.arPlacement}
          allowResize={product.allowResize ? product.allowResize == 1 : false}
          onClick={() => {
            if (isSmallWindow && !modalOpen.qr) {
              if (isMobile) {
                //(viewerRef.current! as any).activateAR();
              } else {
                setModalOpen({ ...modalOpen, qr: true });
              }
            }
          }}
          onLoad={(e) => {
            window.parent.postMessage(true, "*");
            setButtonsAreVisible(true);
          }}
          onCameraChange={(e) => {
            if (e.detail.source === "user-interaction") {
              setCameraDidMove(true);
            }
          }}
        >
          <button
            className="close-augmentation-button"
            slot="exit-webxr-ar-button"
          >
            <Close
              style={{
                color: product.augmentationButton?.color || DEFAULT_COLOR,
                fontSize: "25px",
              }}
            />
          </button>

          <ARButton
            isVisible={buttonsAreVisible}
            isSmallWindow={isSmallWindow}
            text={product.augmentationButton?.text}
            tryOnText={product.augmentationButton?.tryOnText}
            color={
              product.augmentationButton
                ? product.augmentationButton.color
                : null
            }
            onClick={() => {
              if (product!.arPlacement === "eyes") {
                window.location.href = `face-try-on/${model_id}`;
              } else {
                window.parent.postMessage({ "qr-open": true }, "*");
                if (isMobile) {
                  addAugmentation()
                  window.parent.postMessage({ type: "stat", name: "ar-button-clicked", platform: "mobile" }, "*");
                } else {
                  setModalOpen({ ...modalOpen, qr: true });
                  window.parent.postMessage({ type: "stat", name: "ar-button-clicked", platform: "web" }, "*");
                }
              }
            }}
            placement={product.arPlacement}
          />

          {buttonsAreVisible && (
            <>
              <div className="appar-btn-container">
                {!hideOptions && (
                  <DropodownMenu
                    color={product.augmentationButton?.color || DEFAULT_COLOR}
                  >
                    <LanguageSelector
                      color={product.augmentationButton?.color || DEFAULT_COLOR}
                    />
                    <HelpModal
                      title={HelpMessages.viewer.title}
                      options={HelpMessages.viewer.body}
                      hidden={isSmallWindow}
                      color={product.augmentationButton?.color || DEFAULT_COLOR}
                    />
                  </DropodownMenu>
                )}

                {product.annotations && product.annotations.length > 0 && (
                  <>
                    <Annotations
                      annotations={product.annotations}
                      color={product.augmentationButton?.color || DEFAULT_COLOR}
                    />
                  </>
                )}

                {product && product.variants && product.variants.length > 1 && (
                  <div
                    className="select-color-button"
                    onClick={() => setOpenVariants(!openVariants)}
                  >
                    <label htmlFor="list1" id="selected-variant"></label>
                    <p className="text-switch">Color</p>
                    {openVariants && (
                      <div className="variants-menu-options">
                        <img
                          src="/images/triangle-fill.png"
                          className="triangle-more-menu"
                        ></img>
                        <p className="title-variant-container">
                          Elige un color:
                        </p>
                        <div className="list-variant-container">
                          {listVariants()}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {product!.showDimensions && (
                  <ModelDimensions
                    color={product.augmentationButton?.color || DEFAULT_COLOR}
                    onClick={(show) => {
                      setShowMeasurements(show);
                    }}
                  />
                )}
                {canemeraDidMove && !isSmallWindow && (
                  <button
                    className="restore-position-button"
                    onClick={() => {
                      const viewer = document.getElementById("model-viewer")!;
                      viewer.setAttribute("camera-target", "0 0 0");
                      viewer.setAttribute("camera-orbit", "0deg 75deg 105%");
                      setCameraDidMove(false);
                    }}
                  >
                    <SettingsBackupRestore
                      style={{
                        color:
                          product.augmentationButton?.color || DEFAULT_COLOR,
                      }}
                      fontSize="large"
                    />
                    <p className="text-switch">{t("common.recenter")}</p>
                  </button>
                )}
              </div>
            </>
          )}


          {buttonsAreVisible &&
            window.top === window.self &&
            product.productUrl && (
              <CallToActionButton
                actionUrl={product.productUrl}
                buttonColor={product.augmentationButton?.color || DEFAULT_COLOR}
                message={product.callToAction}
                displaced={false}
              />
            )}

          <div id="lazy-load-poster" slot="poster"></div>
          <p className="sty-p display-p">
            Powered by
            <a
              href="https://appar.io/"
              target="_blank"
              rel="noopener"
              className="appar-url-ref"
            >
              AppAR SpA
            </a>
          </p>

          {modalOpen.qr && (
            <QRModal onClose={() => handleClose()} storeId={product.storeId} />
          )}

          {buttonsAreVisible && (
            <>
              <button
                ref={hotspotXYRef}
                hidden={!showMeasurements}
                style={{
                  color: product.augmentationButton?.color || DEFAULT_COLOR,
                  borderColor:
                    product.augmentationButton?.color || DEFAULT_COLOR,
                }}
                slot="hotspot-dim+X-Y"
                className="annotation-centred"
                data-position="1 -1 0"
                data-normal="1 0 0"
              />
              <button
                ref={hotspotYZRef}
                hidden={!showMeasurements}
                style={{
                  color: product.augmentationButton?.color || DEFAULT_COLOR,
                  borderColor:
                    product.augmentationButton?.color || DEFAULT_COLOR,
                }}
                slot="hotspot-dim+Y-Z"
                className="annotation-centred"
                data-position="0 -1 -1"
                data-normal="0 1 0"
              />
              <button
                ref={hotspotXZRef}
                hidden={!showMeasurements}
                style={{
                  color: product.augmentationButton?.color || DEFAULT_COLOR,
                  borderColor:
                    product.augmentationButton?.color || DEFAULT_COLOR,
                }}
                slot="hotspot-dim-X-Z"
                className="annotation-centred"
                data-position="-1 0 -1"
                data-normal="1 0 0"
              />
            </>
          )}
        </ModelViewer>
      ) : (
        error && <Error message={error} />
      )}
    </div>
  );
};

export default ProductViewer;
