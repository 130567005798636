import React, { useEffect, useState } from "react";
import "./style.css";

interface Props {
    value: number
    onChange: (value: number) => void
    maxNumber: number
}

const NumericStepperInput = ({ onChange, value, maxNumber }: Props) => {

    useEffect(() => {
        if (value >= maxNumber) {
            onChange(maxNumber)
        } else if (maxNumber > 0) {
            onChange(1)
        }
    }, [maxNumber])

    const increment = () => {
        if (value <= maxNumber) {
            onChange(value + 1)
        }
    };

    const decrement = () => {
        if (value > 1) {
            onChange(value - 1)
        }
    };

    return (
        <>
            <div className="appar-numeric-stepper-container">
                <button
                    onClick={decrement}
                    className="quantity-btn"
                    disabled={value <= 1 || !maxNumber}
                    style={{ background: (value <= 1 || !maxNumber) ? '#e3e4e6' : '' }}>-</button>
                <input
                    type="number"
                    value={value}
                    className="quantity-input"
                    onChange={(e) => {
                        onChange(Math.round(Math.min(Math.max(e.target.valueAsNumber, 0), maxNumber)));
                    }} />
                <button
                    onClick={increment}
                    className="quantity-btn"
                    disabled={value === maxNumber || !maxNumber}
                    style={{ background: (value === maxNumber || !maxNumber) ? '#e3e4e6' : '' }}>+</button>
            </div>

        </>
    );
};

export default NumericStepperInput;
