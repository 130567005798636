
import {
    Scene,
    MeshBasicMaterial,
    FrontSide,
    MeshNormalMaterial,
    Mesh,
    Material
} from 'three';
import { makeGeometry } from './landmarkHelpers';
import { NormalizedLandmarkList } from '@mediapipe/face_mesh';

export class FaceMask {
  scene: Scene;
    needsUpdate: boolean;
    landmarks: NormalizedLandmarkList | null;
    faces: Mesh | null;
    width: number;
    height: number;
    material: Material;
  constructor(scene: Scene, width: number, height: number, show: boolean) {
    this.scene = scene;
    this.needsUpdate = false;
    this.landmarks = null;
    this.faces = null;
    this.width = width;
    this.height = height;
    
    if (!show) {
      this.material = new MeshBasicMaterial({color: 0x000000});
      this.material.colorWrite = false;
      this.material.transparent = true;
      this.material.side = FrontSide;
      this.material.clipIntersection = true;
      this.material.opacity = 0;
    } else {
      this.material = new MeshNormalMaterial();
    }
  }

  updateDimensions(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.needsUpdate = true;
  }

  updateLandmarks(landmarks: NormalizedLandmarkList) {
    this.landmarks = landmarks;
    this.needsUpdate = true;
  }

  updateMaterial(material: Material) {
    this.material = material;
    this.material.needsUpdate = true;
  }

  addFaces() {
    // create faces
    let geometry = makeGeometry(this.landmarks!);
    this.faces = new Mesh(geometry, this.material);
    this.faces.position.set(0, 0, 0);
    this.faces.scale.set(this.width, this.height, this.width);

    this.faces.renderOrder = -1;

    this.scene.add(this.faces);
    
  }

  removeFaces() {
    this.scene.remove(this.faces!);
  }

  update() {
    if (this.needsUpdate) {
      if (this.faces != null) {
        this.removeFaces();
      }
      if (this.landmarks != null) {
        this.addFaces();
      }
      this.needsUpdate = false;
    }
  }
}
