export const createHotspot = (annotation: Annotation) => {
  const hotspot = document.createElement("div");
  hotspot.slot = `hotspot-${annotation.annotationId}`;
  hotspot.classList.add("hotspot");
  hotspot.id = `hotspot-${annotation.annotationId}`;
  hotspot.dataset.position = annotation.position.toString();
  hotspot.dataset.normal = annotation.normal.toString();
  return hotspot;
};

export const createAnnotation = (
  identifier: number,
  hotspot: HTMLDivElement,
  defaultMessage = "Default Message"
) => {
  const annotation = document.createElement("div");
  annotation.classList.add("annotation");
  annotation.id = `annotation-${identifier}`;
  annotation.appendChild(document.createTextNode(defaultMessage));
  hotspot.appendChild(annotation);
  return annotation;
};

export const clearAnnotations = () => {
  const hotspots = document.querySelectorAll(".hotspot");
  if (hotspots) {
    hotspots.forEach((element) => {
      element.parentNode!.removeChild(element);
    });
  }
};
