import { Color, Mesh, MeshStandardMaterial, SphereGeometry, Vector3 } from "three"

export const eventDispatcher = (eventName: string, detail: any) => {
  document.dispatchEvent(new CustomEvent(eventName, { detail: detail }));
};

export const getWorldPosition = (
  mpNum: number,
  maxImage: number,
  axis: string
): number => {
  var position = maxImage / 2 - (maxImage - mpNum);
  if (axis === "y" && position < maxImage / 2) {
    position = -position;
  } else if (axis === "x" && position > maxImage / 2) {
    position = -position;
  }
  return position;
};

export const getRadAngleBetweenVectors = (
  a: Vector3,
  b: Vector3
): number => {
  return Math.acos(
    (a.x * b.x + a.y * b.y + a.z * b.z) /
    (Math.sqrt(
      Math.pow(a.x, 2) + Math.pow(a.y, 2) + Math.pow(a.z, 2)
    ) *
      Math.sqrt(
        Math.pow(b.x, 2) +
        Math.pow(b.y, 2) +
        Math.pow(b.z, 2)
      )
    )
  );
};

export const generateMesh = (color: Color, name?: string) => {
  const geometry = new SphereGeometry(1)
  const material = new MeshStandardMaterial({ color: color, metalness: 1, roughness: 0 })
  const mesh = new Mesh(geometry, material)
  mesh.name = name ? name : ''
  return mesh
}

export const getMiddlePoint = (a: Vector3, b: Vector3) => {
  var x = (a.x + b.x) / 2;
  var y = (a.y + b.y) / 2;
  var z = (a.z + b.z) / 2;

  return new Vector3(x, y, z);

}

export const getAverageVector3 = (vectors: Vector3[]) => {
  const vectorAvg = new Vector3(0, 0, 0)
  vectors.forEach((vector) => {
    vectorAvg.x += vector.x;
    vectorAvg.y += vector.y;
    vectorAvg.z += vector.z;
  });

  vectorAvg.x /= vectors.length;
  vectorAvg.y /= vectors.length;
  vectorAvg.z /= vectors.length;

  return vectorAvg
}

export const getUserMedia = async () => {
  const features = {
    audio: true,
    video: {
      width: { ideal: 4096 },
      height: { ideal: 2160 },
      facingMode: 'user'
    }
  }

  const userMedia = await navigator.mediaDevices.getUserMedia(features);


  return userMedia

}
export const calculateAspectRatioFit = (screenWidth: number, screenHeight: number, cameraWidth: number, cameraHeight: number) => {

  const wDiff = screenWidth / cameraWidth;
  const hDiff = screenHeight / cameraHeight;

  const cameraAspectRatio = cameraWidth / cameraHeight;

  let finalWidth = 0;
  let finalHeight = 0;

  if (wDiff > hDiff) {
    finalHeight = screenHeight;
    finalWidth = screenHeight * cameraAspectRatio;
  } else if (hDiff > wDiff) {
    finalWidth = screenWidth;
    finalHeight = screenWidth / cameraAspectRatio;
  }

  return { width: finalWidth, height: finalHeight };
}


