import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { TeamWearRequests } from "../requests";
import {
  TeamwearSelector,
  setActiveSection,
  setArchetype,
  setBottom,
  setBottomVariantIndex,
  setContentLoaded,
  setData,
  setEmptyFeet,
  setFeet,
  setFeetVariantIndex,
  setTop,
  setTopVariantIndex,
  setUrl,
  setVisibility,
} from "../screens/TeamwearViewer/teamwearSlice";

const useTeamWear = () => {
  const dispatch = useAppDispatch();

  const teamwear = useAppSelector(TeamwearSelector);

  const [skus, setSkus] = useState<Sku[]>([]);
  const [dataReady, setDataReady] = useState<boolean>(false);

  useEffect(() => {
    if (dataReady) {
      sendDataToParent();
    }
  }, [teamwear]);

  const createSku = async (provider: string, productInfo: any) => {
    await TeamWearRequests.createSKU(provider, productInfo)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.error("e", e);
        return e;
      });
  };

  const getSKUs = async (provider: string) => {
    try {
      const res = await TeamWearRequests.getSKUs(provider);
      if (Array.isArray(res.data)) {
        setSkus(res.data);
        //dispatch(setData(res.data))
      }
      return res.data;
    } catch (e) {
      console.error("e", e);
      return e;
    }
  };

  const getSKUsIDs = async (provider: string): Promise<any[] | any> => {
    try {
      const res = await TeamWearRequests.getSKUs(provider);
      if (res.data) {
        return Array.from(res.data, (product: any) => product.sku);
      } else if (res.error) {
        return res.error;
      }
    } catch (e) {
      console.error("e", e);
      return e;
    }
  };

  const addQuantities = (data: any[]) => {
    const newDataArray = skus.map((item) => {
      return {
        ...item,
        variants: item.variants.map((variant: any) => {
          const matchingQuantityObj = data.find(
            (qItem) => qItem.itemId == variant.prodId
          );
          return matchingQuantityObj
            ? {
                ...variant,
                quantity: matchingQuantityObj.availableQuantity,
                price: matchingQuantityObj.price,
                listPrice: matchingQuantityObj.listPrice,
              }
            : {
                ...variant,
                quantity: 0,
                price: 0,
                listPrice: 0,
              };
        }),
      };
    });
    
    if (newDataArray.length > 0) {
      setSkus(newDataArray);
      dispatch(setData(newDataArray));
    }
    dispatch(setContentLoaded(true));
  };

  const createVariant = (item: any) => {
    const name =
      item.variations.length > 1
        ? item.variations[1].values.json[0]
        : item.nameComplete;
    const size =
      item.variations.length > 0 ? item.variations[0].values.json[0] : "NN";

    return {
      name,
      color: "",
      prodId: item.itemId,
      preview: item.images[0].imageUrl,
      size,
      sellerId: item.sellers[0].sellerId,
      preset: 0,
      quantity: 0,
    };
  };

  const createSkuForProduct = (account: string, product: any) => {
    const variants = product.items.map(createVariant);
    const skuData = {
      archetype: "",
      modelUrl: null,
      section: null,
      name: product.productName,
      preview: product.items[0].images[0].imageUrl,
      sku: product.productId,
      variants,
      presets: [],
    };

    createSku(account, skuData);
  };

  const reviewProductsFromStore = async (data: any) => {
    const [skus, prodIDs] = await Promise.all([
      getSKUs(data.account),
      getSKUsIDs(data.account),
    ]);

    if (Array.isArray(prodIDs)) {
      window.top!.postMessage(
        {
          type: "SEND_PRODUCT_IDS",
          content: prodIDs,
        },
        "*"
      );
    }

    // data!.content!.products.forEach((product: any) => {
    //   const productExists = skus!.some(
    //     (teamwearProd: any) => teamwearProd.sku == product.productId
    //   );

    //   if (!productExists && product.items.length > 0) {
    //     createSkuForProduct(data.account, product);
    //   }
    // });
  };

  const setInitialSkus = (urlString: string) => {
    const url = new URL(urlString);
    const params = url.searchParams;

    const gender = params.get("gender") as Archetype;

    if (gender) {
      dispatch(setArchetype(gender));
      dispatch(setActiveSection("top"));

      const productTypes = ["shirt", "short", "sock"];
      const dispatchFunctions = [setTop, setBottom, setFeet];
      const variantDispatchFunctions = [
        setTopVariantIndex,
        setBottomVariantIndex,
        setFeetVariantIndex,
      ];

      productTypes.forEach((type, index) => {
        const product = teamwear.data.find(
          (product) =>
            product.sku == params.get(type) && product.archetype == gender
        );
        if (product) {
          dispatch(dispatchFunctions[index](product));
          const variantIndex = params.get(`${type}Var`)
            ? parseInt(params.get(`${type}Var`)!)
            : 0;
          dispatch(variantDispatchFunctions[index](variantIndex));
        }
      });
    } else {
      setInitialData(teamwear.selectedArchetype, teamwear.data);
    }
    setDataReady(true);
  };

  const sendDataToParent = () => {
    window.top?.postMessage(
      {
        type: "URL_TO_PARENT",
        content: {
          gender: teamwear.selectedArchetype,
          top: teamwear.top.sku,
          topVariant: teamwear.selectedTopVariantIndex,
          bottom: teamwear.bottom.sku,
          bottomVariant: teamwear.selectedBottomVariantIndex,
          feet: teamwear.feet.sku,
          feetVariant: teamwear.selectedFeetVariantIndex,
        },
      },
      "*"
    );
  };

  const setInitialData = (archetype: Archetype, skus: Sku[]) => {
    const sectionFilter = (section: string) => (sku: Sku) =>
      archetype === sku.archetype && sku.section === section;

    const topSku = skus.filter(sectionFilter("top"))[0];
    const bottomSku = skus.filter(sectionFilter("bottom"))[0];
    const feetSku = skus.filter(sectionFilter("feet"))[0];

    dispatch(setArchetype(archetype));
    dispatch(setActiveSection("top"));

    if (topSku) {
      dispatch(setTop(topSku!));
      dispatch(setTopVariantIndex(0));
    }
    if (bottomSku) {
      dispatch(setBottom(bottomSku!));
      dispatch(setBottomVariantIndex(0));
    }

    if (archetype === "child") {
      dispatch(setEmptyFeet());
    } else if (feetSku) {
      dispatch(setFeet(feetSku!));
      dispatch(setFeetVariantIndex(0));
    }
  };

  const setClothVisibility = (config: VisibilityObj) => {
    dispatch(setVisibility(config));
  };

  const setRawData = (data: any) => {
    dispatch(setData(data));
  };

  const setURLFromParent = (url: string) => {
    dispatch(setUrl(url));
  };

  return {
    addQuantities,
    createSku,
    getSKUs,
    getSKUsIDs,
    reviewProductsFromStore,
    setClothVisibility,
    setInitialData,
    setInitialSkus,
    setRawData,
    setURLFromParent,
    skus,
  };
};

export default useTeamWear;
