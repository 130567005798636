import "./style.css"

type Props = {
  show: boolean
}
const Backdrop = ({ show }: Props) => {
  return (
    <>
      {show &&
        <div className="backdrop-loading">
          <div className="loading-dots"></div>
          <p className="backdrop-text"> Cargando visor 3D</p>
        </div>
      }

    </>
  );
};

export default Backdrop;  
