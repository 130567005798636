import { Settings } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { DEFAULT_COLOR } from "../../constants";

type Props = {
  children: ReactNode | ReactNode[];
  color?: Color;
};

const DropodownMenu = ({ children, color = DEFAULT_COLOR }: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-button"
        style={{ color: color }}
      >
        <Settings fontSize="large" />
      </button>
      {isOpen && (
        <div className="more-menu-options">
          <img
            src="/images/triangle-fill.png"
            className="triangle-more-menu"
          ></img>
          {children}
        </div>
      )}
    </div>
  );
};

export default DropodownMenu;
