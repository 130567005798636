import "./style.css";
import { useState } from "react";

type Props = {
  children?: JSX.Element;
  open: boolean;
  onClose: () => void;
};

const SmallModal = (props: Props) => {
  const { children, open, onClose } = props;

  return (
    <>
      {open && (
        <>
          <div
            className="tw-container-backdrop"
            onClick={() => onClose()}
          ></div>

          <div className="tw-mobile-container-menu">
            <div className="tw-close-btn-container">
              <button className="tw-close-btn-modal" onClick={() => onClose()}>
                <img
                  src="teamwear/icons/close.svg"
                  alt="close-button"
                  className="tw-close-btn-infopanel"
                />
              </button>
            </div>

            {children}
          </div>
        </>
      )}
    </>
  );
};

export default SmallModal;
