import { ElementRef, JSX, useEffect, useRef, useState } from "react";
import { KeyboardDoubleArrowUp } from '@mui/icons-material';
import Proptypes from "prop-types";
import "./style.css"
import { DEFAULT_COLOR } from "../../constants";

type Props = {
  content: Product[],
  onSlideSelect: (product: Product) => void,
  onOpen: (open: boolean) => void,
  closeOnUnfocus: boolean,
  forceOpen: boolean,
  hidden: boolean,
  color?: Color
}

const BottomModal = ({
  content, onSlideSelect, onOpen, closeOnUnfocus, forceOpen, hidden, color
}: Props) => {
  const modalOutside = useRef<ElementRef<"div">>(null);
  const bottomModal = useRef<ElementRef<"div">>(null);

  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    if (forceOpen) {
      showModal();
    } else {
      hideModal();
    }
  }, [forceOpen]);

  const showModal = () => {
    if (bottomModal.current) {
      onOpen(true);
      bottomModal.current.style.bottom = "0px ";
      if (closeOnUnfocus) modalOutside.current!.style.display = "block";
    }
  };

  const hideModal = () => {
    if (bottomModal.current) {
      onOpen(false);
      bottomModal.current.style.bottom = "-200px";
      if (closeOnUnfocus) modalOutside.current!.style.display = "none";
    }
  };

  const createSlides = () => {
    let slides: JSX.Element[] = [];
    let isSelected = false;
    if (content && content.length > 0) {
      content.forEach((prod, index) => {
        slides.push(
          <div
            key={"bottom modal slide " + index}
            onClick={(e) => {
              onSlideSelect(prod);
              setSelectedIndex(index)
            }}
            className={selectedIndex === index ? "slide selected" : "slide"}
            style={{ borderColor: selectedIndex === index ? color : "#fff" }}
          >
            <img className="img-slide" src={prod.posterUrl} />
          </div>
        );
        isSelected = true;
      });
    }

    return slides;
  };

  return (
    <>
      {!hidden && (
        <div className="bottom-modal">
          <div className="dv-btn-close">
            <button
              className="btn-op  animate__animated animate__pulse animate__slow animate__infinite"
              onClick={() => showModal()}
              style={{borderColor: color}}
            >
              <KeyboardDoubleArrowUp
                className="open-modal-button-icon"
                style={{ color: color }}
              />
            </button>
          </div>
          <div>
            {closeOnUnfocus && (
              <div
                ref={modalOutside}
                className="bott-modal-content"
                style={{ cursor: "default" }}
                onMouseDown={() => hideModal()}
                onTouchStart={() => hideModal()}
              ></div>
            )}
            <div
              ref={bottomModal}
              className="bott-modal">
              <div className="slider">
                <div className="slides">{createSlides()}</div>
              </div>
              <div></div>

              <button
                className="btn-bott-modal mx-auto "
                onClick={() => hideModal()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

BottomModal.propTypes = {
  isOpen: Proptypes.func,
  onSlideSelect: Proptypes.func,
  content: Proptypes.arrayOf(Proptypes.object),
  closeOnUnfocus: Proptypes.bool,
  hidden: Proptypes.bool,
};

BottomModal.defaultProps = {
  isOpen: () => { },
  onSlideSelect: () => { },
  content: [],
  closeOnUnfocus: true,
  forceOpen: true,
  hidden: false,
};

export default BottomModal;
