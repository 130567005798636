// @ts-nocheck
import { useEffect, useRef } from "react"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";

type Props = {
    archetype: Archetype
    section: Section,
    preset: Array<Material>
    position: Array<number>
    scale: number
    modelUrl: string
}

const Cloth = (props: Props) => {
    const { preset, scale, position, modelUrl ="" } = props
    const ref = useRef();

    const { scene } = useLoader(
        GLTFLoader,
        modelUrl
    );    

    useEffect(() => {
        const childrenGroup = ref.current!.children.find((child) => child.type === "Group")
        childrenGroup.traverse((child) => {
            if (child.isMesh && preset) {
                const newMaterial = preset.find(
                    (preset, index) => preset.material == child.material.name
                );
                if (newMaterial !== undefined && child.material.name === newMaterial.material) {
                    child.material.color.setHex(newMaterial.hex);
                }
            }
        });
    }, [preset])

    return (
        <>
            <primitive object={scene} scale={scale} position={position} ref={ref} />
        </>
    );
}

export default Cloth