import { Language, Close } from "@mui/icons-material"
import "./style.css"
import { useTranslation } from "react-i18next"
import { DEFAULT_COLOR } from "../../constants"
import { useState } from "react"

type Props = {
    color?: Color
}
const LanguageSelector = ({ color = DEFAULT_COLOR }: Props) => {

    const { t, i18n } = useTranslation()

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const supportedLanguages = [
        {
            "name": "Español",
            "code": "spanish",
            "short": "es",
            "icon": "images/flags/es.png"
        },
        {
            "name": "English",
            "code": "english",
            "short": "en",
            "icon": "images/flags/uk.png"
        },
        {
            "name": "Português",
            "code": "português",
            "short": "pt",
            "icon": "images/flags/br.png"
        }
    ]

    return (
        <>
            <button className="lang-selector-btn"
                style={{ color: color }}
                onClick={() => setIsOpen(true)}
            >
                <Language
                    fontSize="medium"
                    className="lang-selector-icon"
                />
                <p className="lang-selector-text">{t('common.language')}</p>
            </button>
            {isOpen &&
                <>
                    <div className="language-selector-modal-container">
                        <button className="language-selector-close-btn"
                            onClick={() => setIsOpen(false)}
                        >
                            <Close />
                        </button>
                        <div className="language-selector-content">
                            <p className="language-selector-title">{t('common.select_language')}:</p>
                            {supportedLanguages.map((lang) => (
                                <div 
                                    key={lang.short} 
                                    className="lang-option"
                                    onClick={()=> {
                                        i18n.changeLanguage(lang.short)
                                        setIsOpen(false)
                                    }}
                                >
                                    <img src={lang.icon} alt="language icon" className="language-flag" />
                                    <p className="language-text">{t(lang.name)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default LanguageSelector