import "./style.css";
import { Backdrop, InfoPanel, MobileMenu, Selector, ThreeViewer } from "./components";
import jsonData from "../../data/teamwear.json";
import { useEffect, useState } from "react";
import { useTeamWear } from "../../hooks";
import { useAppSelector } from "../../app/hooks";
import { TeamwearSelector } from "./teamwearSlice";

const TeamWear = () => {

  const teamwear = useAppSelector(TeamwearSelector)

  const {
    addQuantities,
    reviewProductsFromStore,
    setInitialSkus,
    setRawData,
    setURLFromParent
  } = useTeamWear()

  const [dev, setDev] = useState<boolean>(false)

  useEffect(() => {
    console.log("[AppAR] TeamwearViewer Ready");

    const urlParams = new URL(window.location.href).searchParams

    if (urlParams.get("dev") == "true") {
      console.log("[TW] Dev Mode");
      
      setDev(true)
      setRawData(jsonData)
    }

    const handleMessage = async ({ data }: any) => {
      switch (data.type) {
        case "STORE_DATA_TO_CHILD": {          
          reviewProductsFromStore(data);
          break;
        }
      }
    };

    // Add event listeners
    window.addEventListener("message", handleMessage);

    return () => {
      // Cleanup: remove the event listeners when the component unmounts.
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    checkIfDataIsRecieved()

    const handleMessage = ({ data }: any) => {
      switch (data.type) {
        case "STORE_DATA_TO_CHILD": {          
          reviewProductsFromStore(data);
          break;
        }
        case "URL_TO_CHILD": {                          
          setURLFromParent(data.content)            
          break;
        }
        case "PRODUCT_QUANTITY_DATA": {          
          addQuantities(data.content);
          break;
        }
      }
    };    

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
    };
  }, [addQuantities, teamwear.data]);

  useEffect(() => {
    if (teamwear.url.length > 0) {      
      setInitialSkus(teamwear.url);
    }
  }, [teamwear.url]);


  let intervalId: NodeJS.Timeout | null = null

  const checkIfDataIsRecieved = () => {
    if (intervalId !== null) {
      clearInterval(intervalId)
    }

    intervalId = setInterval(() => {
      if (teamwear.data.length > 0) {
        console.log("[AppAR] Data received.");
        clearInterval(intervalId as NodeJS.Timeout);
        intervalId = null;
      } else {
        console.log("[AppAR] Connecting to backend...");
        
        window.parent!.postMessage({
          type: "TW_READY",
          content: true
        }, "*"); // Consider replacing "*" with specific target origin for security
      }
    }, 5000);
  }

  return (
    <>
      <div className="tw-viewer-container">
        <>
          <div className="tw-viewer-title">
            <h1 className="viewer-title">TEAMWEAR CONFIGURATION</h1>
          </div>
          {teamwear.data.length > 0 &&
            <>
              <div className="tw-settings d-desktop">
                <Selector data={teamwear.data} />
              </div>
              <div className="tw-viewer">
                <ThreeViewer />
              </div>
              <div className="tw-info d-desktop">
                <InfoPanel />
              </div>
              <div className="d-mobile">
                <MobileMenu data={teamwear.data} />
              </div>
            </>
          }
         <Backdrop show={dev ? false : !teamwear.contentLoaded} />
        </>
      </div>
    </>
  );
}

export default TeamWear;
