import {
  DirectionalLight,
  HemisphereLight,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  PlaneGeometry,
  RingBufferGeometry,
  Scene,
  ShadowMaterial,
  WebGLRenderer,
  sRGBEncoding
} from "three";
import { ARButton } from "three/examples/jsm/webxr/ARButton";

export const initializeEnvironment = (canvas) => {
  const scene = new Scene();
  const camera = new PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.01,
    100
  );
  const renderer = new WebGLRenderer({ antialias: true, alpha: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.outputEncoding = sRGBEncoding;
  renderer.shadowMap.enabled = true;

  canvas.appendChild(renderer.domElement);

  const light = new HemisphereLight(0xffffff, 0xffffff, 0.8);
  const dirLight = new DirectionalLight(0xffffff, 0.5);
  dirLight.castShadow = true;
  light.position.set(0, 1, 0);
  scene.add(light);
  scene.add(dirLight);

  return {
    scene: scene,
    renderer: renderer,
    camera: camera,
  };
};

export const createReticle = () => {
  const geometry = new RingBufferGeometry(0.15, 0.2, 32).rotateX(-Math.PI / 2);
  const material = new MeshBasicMaterial();
  const mesh = new Mesh(geometry, material);

  mesh.matrixAutoUpdate = false;
  mesh.visible = false;
  mesh.receiveShadow = false;
  mesh.name = "reticle";

  return mesh;
};

export const createShadowPlane = (mesh, size) => {
  const geometryShadowPlane = new PlaneGeometry(1, 1);
  const materialShadow = new ShadowMaterial({ opacity: 0.3 });
  const shadowReciver = new Mesh(geometryShadowPlane, materialShadow);
  shadowReciver.receiveShadow = true;
  shadowReciver.rotation.x = -Math.PI / 2;
  shadowReciver.scale.set(size, size);
  shadowReciver.name = "Shadow reciver";
  mesh.add(shadowReciver);
};

export const setUpHitTest = async (renderer) => {
  const session = renderer.xr.getSession();

  const viewerSpace = await session.requestReferenceSpace("viewer");
  const hitTestSource = await session.requestHitTestSource({
    space: viewerSpace,
  });
  const localSpace = await session.requestReferenceSpace("local");

  return {
    source: hitTestSource,
    localSpace: localSpace,
    initialized: true,
  };
};

export const setUpAR = (renderer, canvas) => {
  const button = ARButton.createButton(renderer, {
    requiredFeatures: ["hit-test"],
    optionalFeatures: ["dom-overlay"],
    domOverlay: { root: document.getElementById("overlay") },
  });
  button.className =
    "animate__animated animate__pulse animate__slow animate__infinite";
  button.hidden = true;
  renderer.xr.enabled = true;
  canvas.appendChild(button);
};
