export const viewer =
{
    title: "ar_suggestions",
    body: [
        {
            image: {
                src: "/images/placing.svg",
                maxWidth: 45,
            },
            title: "3d_model_in_your_space",
            body: "3d_model_in_your_space_instruction"
        },
        {
            image: {
                src: "/images/rotateRB.svg",
                maxWidth: 50,
            },
            title: "rotate_3d_model",
            body: "rotate_3d_model_instruction"
        },
        {
            image: {
                src: "/images/enlarge.svg",
                maxWidth: 50,
            },
            title: "resize",
            body: "resize_instruction"
        },
    ]
}

export const roomBuilder =
{
    title: "rb_suggestions",
    body: [
      
        {
            image: {
                src: "/images/tap.svg",
                maxWidth:50,
            },
            title: "add_3d_model",
            body: "add_3d_model_instruction"
        },
        {
            image: {
                src: "/images/rotateRB.svg",
                maxWidth: 50,
            },
            title: "rotate_3d_model",
            body: "rotate_3d_model_instruction_rb"
        },
        {
            image: {
                src: "/images/reset.svg",
                maxWidth: 28,
            },
            title: "remove_3d_model",
            body: "remove_3d_model_instruction"
        }
    ]
}
