import { useState, useEffect } from "react";

import InfoPanel from "../InfoPanel";
import SmallModal from "../SmallModal";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  TeamwearSelector,
  setActiveSection,
  setBottom,
  setBottomVariantIndex,
  setFeet,
  setFeetVariantIndex,
  setTop,
  setTopVariantIndex
} from "../../teamwearSlice";
import { useTranslation } from "react-i18next";
import ImageButton from "../ImageButton";
import ColorButton from "../ColorButton";
import { Option } from "../../types";
import { useTeamWear } from "../../../../hooks";

type ModalConfig = {
  open: boolean,
  mode: "" | "options" | "summary"
}

type Props = {
  data: any
}

const MobileMenu = ({ data }: Props) => {

  const { t } = useTranslation()

  const teamwear = useAppSelector(TeamwearSelector)

  const dispatch = useAppDispatch()

  const { setInitialData } = useTeamWear()

  const [modal, setModal] = useState<ModalConfig>({ open: false, mode: "" });

  useEffect(() => {
    const handleMessage = async ({ data }: any) => {
      if (data.type === "OPEN_MOBILE_SUMMARY") {
        const isOpen = Boolean(data.content);
        setModal({ ...modal, mode: "summary", open: isOpen });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (data) {
      loadColors()
    }
  }, [teamwear.selectedArchetype, data])

  const setSection = (element: Sku): void => {
    const actionMap = {
      top: () => {
        dispatch(setTop(element));
        dispatch(setTopVariantIndex(0));
        
      },
      bottom: () => {
        dispatch(setBottom(element));
        dispatch(setBottomVariantIndex(0));
      },
      feet: () => {
        if (teamwear.selectedArchetype !== "child") {
          dispatch(setFeet(element));
          dispatch(setFeetVariantIndex(0));
        }
      },
    };

    const action = actionMap[teamwear.activeSection];

    if (typeof action === 'function') {
      action();
    }
  };

  const setColor = (element: any): void => {
    const firstVariant = teamwear[teamwear.activeSection].variants.findIndex((variant) => variant.prodId == element.sizes[0].prodId)
    const actionMap = {
      top: () => dispatch(setTopVariantIndex(firstVariant)),
      bottom: () => dispatch(setBottomVariantIndex(firstVariant)),
      feet: () => dispatch(setFeetVariantIndex(firstVariant)),
    };

    if (actionMap[teamwear.activeSection]) {
      actionMap[teamwear.activeSection]();
    }
  };

  const nameIsEqual = (name: string) => {
    const variantMap = {
      bottom: () => name === teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].name,
      feet: () => name === teamwear.feet.variants[teamwear.selectedFeetVariantIndex].name,
      top: () => name === teamwear.top.variants[teamwear.selectedTopVariantIndex].name
    };

    return (variantMap[teamwear.activeSection] || (() => false))();
  };

  const loadModels = (): Array<JSX.Element> => {
    const modelOptions: Array<JSX.Element> = [];
    data.forEach((element: Sku, index: number) => {
      if (teamwear.activeSection === element.section && teamwear.selectedArchetype === element.archetype) {

        modelOptions.push(
          <ImageButton
            className="tw-selector-image-button"
            key={`model-${index}`}
            selected={teamwear[teamwear.activeSection].sku === element.sku}
            imageSrc={element.preview}
            onClick={() => {
              setSection(element)
            }}
          >{element.name}</ImageButton>
        )
      }
    })

    if (modelOptions.length < 1) {
      modelOptions.push(<h6 key={'empty'}>{t("error.no_models")}</h6>)
    }

    return modelOptions;
  }

  const loadColors = (): JSX.Element[] => {
    const colorElementOptions: JSX.Element[] = [];
    const options: Option[] = []

    const sku = teamwear[teamwear.activeSection]
    const variants: SkuVariation[] = sku.variants.reduce((acc: any, variant: SkuVariation, index: number) => {
      const { color, size, prodId, name, ...rest } = variant
      if (!acc[color]) {
        acc[color] = { name, color, sizes: [] }
      }
      acc[color].index = index
      acc[color].name = name
      acc[color].sizes.push({
        size: size,
        prodId: prodId
      })
      return acc
    }, {})

    options.push({
      name: sku.name,
      sku: sku.sku,
      section: sku.section,
      archetype: sku.archetype,
      variants: Object.values(variants)
    })

    const cloth = options.filter(
      (element: Option) =>
        element.archetype === teamwear.selectedArchetype
        && element.section === teamwear.activeSection);

    if (cloth[0]) {
      colorElementOptions.push(
        ...cloth[0].variants.map((element: any, index: number) => (
          <ColorButton
            key={`model-${index}`}
            color={element.color}
            onClick={() => {
              setColor(element)
            }}
            text={element.name}
            selected={nameIsEqual(element.name)}
            style={{
              width: '50px', height: '50px',
              cursor: 'pointer'
            }}
          />
        ))
      );
    }

    return colorElementOptions;
  };

  return (
    <>
      <div className="tw-mobile-side-preview ">
        <h5 className="tw-section-gender-title">{t('teamwear.gender')}</h5>
        <ImageButton
          className=""
          isMobile={true}
          key={`archetypes-female`}
          selected={teamwear.selectedArchetype === "female"}
          imageSrc={"https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/images/archetypes/female.jpg"}
          onClick={() => {
            setInitialData("female", data)

            window.parent.postMessage({
              type: 'SCROLL_TO_VIEW',
              content: true
            }, '*');
          }}
        >{t("teamwear.female") as string}</ImageButton>
        <ImageButton
          isMobile={true}
          className=""
          key={`archetypes-male`}
          selected={teamwear.selectedArchetype === "male"}
          imageSrc={"https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/images/archetypes/male.jpg"}
          onClick={() => {
            setInitialData("male", data)

            window.parent.postMessage({
              type: 'SCROLL_TO_VIEW',
              content: true
            }, '*');
          }}
        >{t("teamwear.male") as string}</ImageButton>
        <ImageButton
          isMobile={true}
          className=""
          key={`archetypes-children`}
          selected={teamwear.selectedArchetype === "child"}
          imageSrc={"https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/images/archetypes/child.png"}
          onClick={() => {
            setInitialData("child", data)

            window.parent.postMessage({
              type: 'SCROLL_TO_VIEW',
              content: true
            }, '*');
          }}
        >{t("teamwear.child") as string}</ImageButton>
      </div >

      <div className="tw-container-config">
        <div className="tw-container-config-options">
          <button
            className="tw-mobile-reset-button"
            onClick={() => {
              window.open(
                "https://www.underarmour.cl/153?map=productClusterIds",
                "_blank",
                "noreferrer"
              );
            }}
          >
            {t("teamwear.see_all_teamwear")}
          </button>
          <button
            className="tw-mobile-resume-btn"
            onClick={() => setModal({ open: true, mode: "summary" })}
          >
            {t("teamwear.see_summary")}
          </button>
        </div>

        <h6 className="text-config-option">{t('teamwear.configure')}</h6>
        <div className="tw-container-config-options">
          <button
            className="options-btn-base config-options-btn"
            onClick={() => {
              setModal({ open: true, mode: "options" })
              dispatch(setActiveSection("top"))
            }}
          >
            <img
              src="teamwear/images/section/top.jpg"
              className="img-config-option"
              alt=""
            />
            {t("teamwear.t-shirt") as string}
          </button>
          <button
            className="options-btn-base config-options-btn"
            onClick={() => {
              setModal({ open: true, mode: "options" })
              dispatch(setActiveSection("bottom"))
            }}
          >
            <img
              src="teamwear/images/section/bottom.png"
              className="img-config-option"
              alt=""
            />
            {t("teamwear.short") as string}
          </button>
          <button
            className="options-btn-base config-options-btn"
            onClick={() => {
              setModal({ open: true, mode: "options" })
              dispatch(setActiveSection("feet"))
            }}
          >
            <img
              src="teamwear/images/section/feet.png"
              className="img-config-option"
              alt=""
            />
            {t("teamwear.sock") as string}
          </button>
        </div>
      </div>

      <SmallModal
        open={modal.open && modal.mode === "options"}
        onClose={() => setModal({ open: false, mode: "options" })}
      >
        {
          <div className="tw-section-option-container">
            <h5 className="tw-section-title">{t('teamwear.select_clothing', { context: teamwear.activeSection })}:</h5>
            <div className="tw-section-info">
              {loadModels()}
            </div>
            {teamwear[teamwear.activeSection].modelUrl.length > 0 &&
              <>
                <h5 className="tw-section-title">{t('teamwear.select_color')}:</h5>
                <div className="tw-section-color-info">
                  {loadColors()}
                </div>
              </>
            }
            <div className="tw-container-config-options">
              <button
                className="tw-mobile-resume-btn"
                style={{ backgroundColor: "#DC0019" }}
                onClick={() => {
                  setModal({ open: false, mode: "options" })
                }}
              >
                Confirmar
              </button>
            </div>
          </div>
        }
      </SmallModal>

      <SmallModal
        open={modal.open && modal.mode === "summary"}
        onClose={() => setModal({ ...modal, open: false })}
      >
        {
          <div className="">
            <InfoPanel isMobile={true} />
          </div>
        }
      </SmallModal>
    </>
  );
};

export default MobileMenu;
