import "./style.css";

type Props = {
  color?: Color;
  text?: string;
  onClick?: () => void;
  selected?: boolean;
  style?: React.CSSProperties;
  clickable?: boolean
};

const ColorButton = (props: Props) => {
  const {
    text,
    onClick,
    color,
    selected,
    style = { height: "45px", width: "45px" },
    clickable = true
  } = props;

  return (
    <>
      <div className="tw-color-button-container" 
        onClick={onClick}
        style={{
          cursor: clickable ? "pointer": "auto"
        }}
      >
        <div
          className="tw-color-button"
          style={{
            backgroundColor: color,
            ...style,
          }}
        >
          {selected && (
            <img
              src="teamwear/icons/tick.svg"
              alt=""
              className="tw-color-button-selected-icon"
            />
          )}
        </div>
        <div className="tw-color-button-text">
          <h3 className="tw-button-color-name">{text}</h3>
        </div>
      </div>
    </>
  );
};

export default ColorButton;
