import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const defaultSku: Sku = {
  archetype: "male",
  modelUrl: "",
  name: "",
  presets: [],
  preview: "",
  provider: "",
  section: "top",
  sku: "",
  variants: [],
};

const initialState: TeamwearState = {
  selectedArchetype: "male",
  top: defaultSku,
  selectedTopVariantIndex: 0,
  bottom: defaultSku,
  selectedBottomVariantIndex: 0,
  feet: defaultSku,
  selectedFeetVariantIndex: 0,
  activeSection: "top",
  data: [],
  loading: true,
  contentLoaded: false,
  url: "",
  visibility: {
    top: true,
    bottom: false,
    feet: false,
  },
};

export const teamwearSlice = createSlice({
  name: "teamwear",
  initialState,
  reducers: {
    setArchetype: (state, action: PayloadAction<Archetype>) => {
      state.selectedArchetype = action.payload;
    },
    setTop: (state, action: PayloadAction<Sku>) => {
      state.top = action.payload;
    },
    setTopVariantIndex: (state, action: PayloadAction<number>) => {
      state.selectedTopVariantIndex = action.payload;
    },
    setBottom: (state, action: PayloadAction<Sku>) => {
      state.bottom = action.payload;
    },
    setBottomVariantIndex: (state, action: PayloadAction<number>) => {
      state.selectedBottomVariantIndex = action.payload;
    },
    setFeet: (state, action: PayloadAction<Sku>) => {
      if (action.payload) {
        state.feet = action.payload;
      }
    },
    setFeetVariantIndex: (state, action: PayloadAction<number>) => {
      state.selectedFeetVariantIndex = action.payload;
    },
    setActiveSection: (state, action: PayloadAction<Section>) => {
      state.activeSection = action.payload;
    },
    setEmptyFeet: (state) => {
      state.feet = defaultSku;
    },
    setData: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setContentLoaded: (state, action: PayloadAction<boolean>) => {
      state.contentLoaded = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setVisibility: (state, action: PayloadAction<VisibilityObj>) => {
      state.visibility = action.payload;
    },
  },
});

export const {
  setActiveSection,
  setArchetype,
  setBottom,
  setBottomVariantIndex,
  setContentLoaded,
  setData,
  setEmptyFeet,
  setFeet,
  setFeetVariantIndex,
  setTop,
  setTopVariantIndex,
  setUrl,
  setVisibility,
} = teamwearSlice.actions;

export const TeamwearSelector = (state: RootState) => state.teamwear;

export default teamwearSlice.reducer;
