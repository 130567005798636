import React, { ElementRef, useRef, useEffect } from 'react'
import {
  BufferGeometry,
  Mesh,
  Material,
  Group,
  Vector3,
  Scene,
  OrthographicCamera,
  WebGLRenderer,
  EquirectangularReflectionMapping,
  VideoTexture,
  PlaneGeometry,
  MeshBasicMaterial,
  MathUtils,
  LinearEncoding,
  HemisphereLight,
  DirectionalLight,
  Color,
} from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { FaceMask } from '../../../../utils/faceTryOn';
import {
  eventDispatcher,
  getAverageVector3,
  getMiddlePoint,
  getRadAngleBetweenVectors,
  getWorldPosition
} from '../../../../utils/tools';
import { BloubergSunrise } from '../../../../utils/skyboxes';

type CameraSettings = {
  aspectRatio: number,
  height: number,
  width: number
}

type Props = {
  videoRef: React.RefObject<HTMLVideoElement>,
  cameraSettings: CameraSettings,
  product: Product
}

const EyeElement = ({ videoRef, cameraSettings, product }: Props) => {
  const rendererRef = useRef<ElementRef<"div">>(null)

  let faceMask: FaceMask;

  const skew = 500;
  const scale = 3700;

  useEffect(() => {
    initThree()
    eventDispatcher("loadmodel", product.androidModelUrl);
  }, [])

  const initThree = () => {
    //#region variables
    let objectToTry: Mesh<BufferGeometry, Material[]> | Group = new Mesh();
    objectToTry.renderOrder = -1;

    const rotations: Vector3[] = [];

    const noseBridgePos = new Vector3(0, 0, 0);
    const rEyePos = new Vector3(0, 0, 0);
    const lEyePos = new Vector3(0, 0, 0);
    const upperLipPos = new Vector3(0, 0, 0);
    //#endregion variables

    //#region 
    const scene = new Scene();
    const camera = new OrthographicCamera(
      cameraSettings.width / -2,
      cameraSettings.width / 2,
      cameraSettings.height / 2,
      cameraSettings.height / -2,
      0.1,
      10000
    );

    camera.position.set(0, 0, 1000);
    scene.add(camera);

    const gltfLoader = new GLTFLoader();

    const renderer = new WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(cameraSettings.aspectRatio * 2);

    // const { scaledHeight, scaledWidth } = getScaledSize()

    renderer.setSize(Math.round(window.innerWidth), Math.round(window.innerWidth / cameraSettings.aspectRatio));

    renderer.outputEncoding = LinearEncoding;
    renderer.shadowMap.enabled = true;

    const rendererCanvas = renderer.domElement
    rendererCanvas.style.position = "absolute"
    rendererCanvas.style.top = "50%"
    rendererCanvas.style.left = "50%"
    rendererCanvas.style.transform = "translate(-50%, -50%)"

    rendererRef.current!.appendChild(rendererCanvas);
    //#endregion

    //#region lighting
    const light = new HemisphereLight(0xffffff, 0xffffff, 3);
    light.position.set(0, 1, 1);
    scene.add(light);

    const dirLight = new DirectionalLight(0xffffff, 2);
    dirLight.position.set(0, 0, 2.780)
    dirLight.castShadow = true;
    scene.add(dirLight);
    //#endregion

    //#region reflections
    new RGBELoader().load(
      BloubergSunrise,
      (texture) => {
        texture.mapping = EquirectangularReflectionMapping;
        scene.background = new Color(0xfff);
        scene.environment = texture;
        
      },
    );
    //#endregion reflections

    //#region events
    document.addEventListener("loadmodel", (e: any) => {
      e.stopImmediatePropagation();
      if (objectToTry) {
        const videoTexture = new VideoTexture(videoRef.current!);

        const photo = new Mesh(
          new PlaneGeometry(cameraSettings.width, cameraSettings.height),
          new MeshBasicMaterial({ map: videoTexture })
        );
        photo.position.set(0, 0, -80);

        scene.add(photo);

        gltfLoader.load(e.detail, (glb) => {
          const obj = glb.scene
          obj.position.set(0, -0.025, -0.05)

          objectToTry.add(obj);
          objectToTry.name = "object to try";

          scene.add(objectToTry);
        });
      }
    });

    faceMask = new FaceMask(scene, cameraSettings.width, cameraSettings.height, true);

    document.addEventListener("setNosePos", (e: any) => {
      e.stopImmediatePropagation();
      noseBridgePos.x = e.detail.x * cameraSettings.width;
      noseBridgePos.y = e.detail.y * cameraSettings.height;
      noseBridgePos.z = e.detail.z * skew;
    });
    document.addEventListener("setREyePos", (e: any) => {
      e.stopImmediatePropagation();
      rEyePos.x = e.detail.x * cameraSettings.width;
      rEyePos.y = e.detail.y * cameraSettings.height;
      rEyePos.z = e.detail.z * skew;
    });
    document.addEventListener("setLEyePos", (e: any) => {
      e.stopImmediatePropagation();
      lEyePos.x = e.detail.x * cameraSettings.width;
      lEyePos.y = e.detail.y * cameraSettings.height;
      lEyePos.z = e.detail.z * skew;
    });
    document.addEventListener("setUpperLipPos", (e: any) => {
      e.stopImmediatePropagation();
      upperLipPos.x = e.detail.x * cameraSettings.width;
      upperLipPos.y = e.detail.y * cameraSettings.height;
      upperLipPos.z = e.detail.z * skew;
    });
    document.addEventListener("updateLandmarks", (e: any) => {
      faceMask.updateLandmarks(e.detail)
    })
    //#endregion events

    renderer.setAnimationLoop((timestamp, frame) => {
      //#region position
      var middleEyes = getMiddlePoint(
        rEyePos,
        lEyePos
      );

      objectToTry.position.lerp(
        new Vector3(
          getWorldPosition(middleEyes.x, cameraSettings.width, "x"),
          getWorldPosition(middleEyes.y, cameraSettings.height, "y"),
          middleEyes.z
        ),
        0.6
      )

      //#endregion position

      //#region rotation
      var vEyes = new Vector3(0, 0, 0);
      var vEyesInv = new Vector3(0, 0, 0);
      var vUpperLip = new Vector3(0, 0, 0);

      vEyes.subVectors(lEyePos, rEyePos);
      vEyesInv.subVectors(rEyePos, lEyePos);
      vUpperLip.subVectors(noseBridgePos, upperLipPos);

      var rotationZ = getRadAngleBetweenVectors(
        vEyes.normalize(),
        new Vector3(0, 1, 0)
      );
      var rotationY =
        getRadAngleBetweenVectors(vEyesInv.normalize(), new Vector3(0, 0, 1)) -
        MathUtils.degToRad(90);

      var rotationX = getRadAngleBetweenVectors(
        vUpperLip.normalize(),
        new Vector3(0, 0, 1)
      );

      rotations.push(
        new Vector3(
          rotationX - MathUtils.degToRad(90),
          rotationY,
          rotationZ - MathUtils.degToRad(90)
        )
      );

      if (rotations.length >= 5) {
        var rotationAvg = getAverageVector3(rotations);
        objectToTry.rotation.set(rotationAvg.x, rotationAvg.y, rotationAvg.z);
        rotations.shift();
      }

      //#endregion rotation
      //#region scale
      var eyeDist = 0;
      const multiplier = 15

      if (Math.abs(rotationY) < Math.abs(rotationX - 1.5)) {
        eyeDist = lEyePos.distanceTo(rEyePos) * 0.78 * multiplier;
      } else {
        eyeDist = noseBridgePos.distanceTo(upperLipPos) * 1.5 * multiplier;
      }

      objectToTry.scale.lerp(new Vector3(eyeDist, eyeDist, eyeDist), 0.1);

      // Offset
      if (!isNaN(objectToTry.rotation.z) && objectToTry.rotation.z !== 0) {
        objectToTry.translateZ(13);
      }

      //#endregion scale
      renderer.render(scene, camera);
    });
  }

  return (
    <>
      <div
        id='renderer'
        ref={rendererRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    </>
  )
}

export default EyeElement