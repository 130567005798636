import "./style.css";

type Props = {
  onClick: () => void;
  children?: string;
  imageSrc?: string;
  className?: string;
  selected?: boolean;
  isMobile?: boolean;
};

const ImageButton = (props: Props) => {
  const { children, className, selected, onClick, imageSrc, isMobile } = props;

  return (
    <div className={className}>
      <div
        className={isMobile ? "tw-image-button-mobile" : "tw-image-button"}
        onClick={onClick}
      >
        <div className={isMobile ? "" : "tw-selected-image-button-icon"}>
          {selected && (
            <img
              src="teamwear/icons/tick.svg"
              alt=""
              className={isMobile ? "tw-selected-image-button-icon-mobile" : ""}
            />
          )}
        </div>
        <div className="img-container-tw">
          <img
            className={`tw-image-button-preview-img${imageSrc ? "" : "-empty"}`}
            src={imageSrc ?? "teamwear/images/x.png"}
            alt=""
          />
        </div>

        <div className="tw-image-button-text">{children}</div>
      </div>
    </div>
  );
};

ImageButton.defaultProps = {
  isMobile: false,
};

export default ImageButton;
