import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SUPPORTED_LANGUAGES } from './constants';

i18n
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: SUPPORTED_LANGUAGES,
		fallbackLng: 'es',
		debug: false,
		detection: {
			order: ['cookie', 'localStorage','navigator', 'htmlTag'],
			caches: ['localStorage','cookie'],
		},
		react: {
			useSuspense: true,
		},
		backend: {
			loadPath: '/locales/{{lng}}.json',
			allowMultiLoading: true,
		},
	});

export default i18n;
