import { isMobile } from "react-device-detect"
import { useTranslation } from 'react-i18next';
import "./style.css"
import { DEFAULT_COLOR } from "../../constants";

interface Props {
	/**
	 * Determines the color of the Button.
	 * @see Color
	 */
	color?: Color | null,
	/**
	 * This props is required for responsivity issues, determines the behaviour depending if the window is too small or not.
	 */
	isSmallWindow: boolean,
	/**
	 * Determines if the button is visible or not.
	 */
	isVisible: boolean
	/**
	 * Activates when the button is clicked.
	 * @returns void
	 */
	onClick: () => void,
	/**
	 * Text that will be displayed in the button.
	 */
	text?: string | null,
	/**
	 * Text that will be displayed in the button if the placement is "eyes".
	 */
	tryOnText?: string | null,
	/**
	 * Specify the model placement to determine the content of the button.
	 * 
	 * If the placement is "eyes" the button will change according to that content.
	 */
	placement: ARPlacement
}

const ARButton = (props: Props) => {
	const { isSmallWindow, onClick, text, color, isVisible = true, placement, tryOnText } = props

	const { t, ready } = useTranslation()

	return (
		<>
			{isVisible &&
				<>
					{
						isSmallWindow ?
							<>
								{ready &&
									<button
										className='small-ar-btn'
										slot={isMobile ? "ar-button" : ""}
										onClick={() => onClick()}
										style={{ borderColor: color || DEFAULT_COLOR, color: color || DEFAULT_COLOR }}
									>
										<svg xmlns="http://www.w3.org/2000/svg" width="22.183" height="23.875" viewBox="0 0 22.183 23.875" className="ar-button-icon">
											<g id="Grupo_14" data-name="Grupo 14" transform="translate(-17.507 -6.928)">
												<path
													id="Trazado_172"
													data-name="Trazado 172"
													d="M32.384,9.126a.345.345,0,0,0-.468.493,10.438,10.438,0,1,1-7.394-3.056,8.725,8.725,0,0,1,1.7.148l-1.528.937a.333.333,0,0,0-.1.481.357.357,0,0,0,.283.16h.2l2.243-1.43h.074V6.414a.123.123,0,0,0,0-.086h0L25.569,4.319a.333.333,0,0,0-.481,0,.32.32,0,0,0,0,.481l1.1,1.232a10.574,10.574,0,0,0-1.664-.123,11,11,0,1,0,7.863,3.217Z"
													transform="translate(4.077 2.711)"
													fill={color ? color : DEFAULT_COLOR}
												/>
												<path
													id="Trazado_173"
													data-name="Trazado 173"
													d="M30.233,18.243V13.917a1.294,1.294,0,0,0,.912-1.232,1.232,1.232,0,0,0-1.232-1.232,1.38,1.38,0,0,0-.875.345L25.34,9.64a1.146,1.146,0,0,0,0-.308,1.232,1.232,0,0,0-2.465,0,2.626,2.626,0,0,0,0,.308l-3.7,2.157a1.331,1.331,0,0,0-.875-.345,1.232,1.232,0,0,0-1.232,1.232,1.294,1.294,0,0,0,.912,1.232v4.326a1.244,1.244,0,1,0,.333,2.465,1.331,1.331,0,0,0,.875-.357l3.7,2.169a2.625,2.625,0,0,0,0,.308,1.232,1.232,0,0,0,2.465,0,1.343,1.343,0,0,0,0-.308l3.7-2.169a1.292,1.292,0,1,0,1.208-2.107Zm-11.621,0V13.917a1.479,1.479,0,0,0,.518-.308l3.7,2.157a2.981,2.981,0,0,0,0,.308,2.837,2.837,0,0,0,0,.32l-3.7,2.144A1.06,1.06,0,0,0,18.612,18.243Zm5.114,3.352a1.454,1.454,0,0,0-.53.283l-3.7-2.157a2.291,2.291,0,0,0,0-.32,2.366,2.366,0,0,0,0-.271l3.7-2.157a1.134,1.134,0,0,0,.53.3Zm0-6.716a1.454,1.454,0,0,0-.53.283L19.5,13a2.537,2.537,0,0,0,0-.32,2.625,2.625,0,0,0,0-.308l3.7-2.157a1.331,1.331,0,0,0,.53.333Zm.69-4.326a1.688,1.688,0,0,0,.53-.3l3.7,2.157a2.539,2.539,0,0,0,0,.32v.283l-3.7,2.169a1.134,1.134,0,0,0-.53-.3Zm5.139,7.653a1.331,1.331,0,0,0-.912,1.232,2.343,2.343,0,0,0,0,.308l-3.7,2.169a1.134,1.134,0,0,0-.53-.3v-4.35a1.232,1.232,0,0,0,.924-1.232,1.442,1.442,0,0,0,0-.32l3.7-2.157a1.158,1.158,0,0,0,.518.3Z"
													transform="translate(4.885 3.685)"
													fill={color ? color : DEFAULT_COLOR}
												/>
											</g>
										</svg>
										{t('common.ar')}
									</button>
								}
							</>
							:
							<>
								{ready &&
									<button
										id="ar-button"
										className="animate__animated animate__pulse animate__slow animate__infinite"
										slot={isMobile ? "ar-button" : ""}
										onClick={() => onClick()}
										style={{ borderColor: color ? color : DEFAULT_COLOR, color: color ? color : '#5C499F' }}
									>
										<svg xmlns="http://www.w3.org/2000/svg" width="22.183" height="23.875" viewBox="0 0 22.183 23.875" className="ar-button-icon">
											<g id="Grupo_14" data-name="Grupo 14" transform="translate(-17.507 -6.928)">
												<path
													id="Trazado_172"
													data-name="Trazado 172"
													d="M32.384,9.126a.345.345,0,0,0-.468.493,10.438,10.438,0,1,1-7.394-3.056,8.725,8.725,0,0,1,1.7.148l-1.528.937a.333.333,0,0,0-.1.481.357.357,0,0,0,.283.16h.2l2.243-1.43h.074V6.414a.123.123,0,0,0,0-.086h0L25.569,4.319a.333.333,0,0,0-.481,0,.32.32,0,0,0,0,.481l1.1,1.232a10.574,10.574,0,0,0-1.664-.123,11,11,0,1,0,7.863,3.217Z"
													transform="translate(4.077 2.711)"
													fill={color ? color : DEFAULT_COLOR}
												/>
												<path
													id="Trazado_173"
													data-name="Trazado 173"
													d="M30.233,18.243V13.917a1.294,1.294,0,0,0,.912-1.232,1.232,1.232,0,0,0-1.232-1.232,1.38,1.38,0,0,0-.875.345L25.34,9.64a1.146,1.146,0,0,0,0-.308,1.232,1.232,0,0,0-2.465,0,2.626,2.626,0,0,0,0,.308l-3.7,2.157a1.331,1.331,0,0,0-.875-.345,1.232,1.232,0,0,0-1.232,1.232,1.294,1.294,0,0,0,.912,1.232v4.326a1.244,1.244,0,1,0,.333,2.465,1.331,1.331,0,0,0,.875-.357l3.7,2.169a2.625,2.625,0,0,0,0,.308,1.232,1.232,0,0,0,2.465,0,1.343,1.343,0,0,0,0-.308l3.7-2.169a1.292,1.292,0,1,0,1.208-2.107Zm-11.621,0V13.917a1.479,1.479,0,0,0,.518-.308l3.7,2.157a2.981,2.981,0,0,0,0,.308,2.837,2.837,0,0,0,0,.32l-3.7,2.144A1.06,1.06,0,0,0,18.612,18.243Zm5.114,3.352a1.454,1.454,0,0,0-.53.283l-3.7-2.157a2.291,2.291,0,0,0,0-.32,2.366,2.366,0,0,0,0-.271l3.7-2.157a1.134,1.134,0,0,0,.53.3Zm0-6.716a1.454,1.454,0,0,0-.53.283L19.5,13a2.537,2.537,0,0,0,0-.32,2.625,2.625,0,0,0,0-.308l3.7-2.157a1.331,1.331,0,0,0,.53.333Zm.69-4.326a1.688,1.688,0,0,0,.53-.3l3.7,2.157a2.539,2.539,0,0,0,0,.32v.283l-3.7,2.169a1.134,1.134,0,0,0-.53-.3Zm5.139,7.653a1.331,1.331,0,0,0-.912,1.232,2.343,2.343,0,0,0,0,.308l-3.7,2.169a1.134,1.134,0,0,0-.53-.3v-4.35a1.232,1.232,0,0,0,.924-1.232,1.442,1.442,0,0,0,0-.32l3.7-2.157a1.158,1.158,0,0,0,.518.3Z"
													transform="translate(4.885 3.685)"
													fill={color ? color : DEFAULT_COLOR}
												/>
											</g>
										</svg>
										{placement === "eyes" ?
											<>
												{tryOnText ? <>{tryOnText}</> : <>{t('common.try_it_on')}</>}
											</>
											:
											<>
												{text ? <>{text}</> : <>{t('common.view_in_your_space')}</>}
											</>
										}
									</button>
								}
							</>
					}
				</>
			}
		</>
	)
}

export default ARButton


