import React, { useEffect, useState } from "react";
import { AwesomeQRCode } from "@awesomeqr/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./style.css";
import { Options } from "@awesomeqr/react";
import { HelpOutline } from "@mui/icons-material";

interface Props {
  onClose: () => void;
  storeId: ID;
}

const QRModal = (props: Props) => {
  const { onClose, storeId } = props;

  const { t, i18n, ready } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleToggleTooltip();
  };

  const [qr, setQr] = useState<Options>({
    text: "https://webviewer.appar.io/",
    margin: 1,
    correctLevel: 1,
    maskPattern: 0,
    colorDark: "#000000", //Personalizable
    colorLight: "#ffffff",
    autoColor: true,
    whiteMargin: true,
    logoImage: "", //Personalizable
    logoScale: 0.2,
    logoMargin: 0,
    logoCornerRadius: 0,
    components: {
      data: {
        scale: 0.75,
      },
      timing: {
        scale: 0.75,
        protectors: false,
      },
      alignment: {
        scale: 0.75,
        protectors: false,
      },
      cornerAlignment: {
        scale: 0.75,
        protectors: true,
      },
    },
  });

  useEffect(() => {
    if (window.innerWidth > 600) {
      setWindowWidth(window.innerWidth / 5);
    } else if (window.innerWidth <= 200) {
      setWindowWidth(window.innerWidth / 1.5);
    } else {
      setWindowWidth(window.innerWidth / 3);
    }
    getQR();
  }, []);

  const getQR = () => {
    setQr({
      ...qr,
      logoImage: "",
      colorDark: "#000000",
      text: window.location.href,
    });
    axios
      .get(process.env.REACT_APP_API_URL + "store/" + storeId + "/qr")
      .then((response) => {
        if (response.data) {
          if (response.data.logo_url) {
            toDataURL(response.data.logo_url).then((dataUrl: any) => {
              setQr({
                ...qr,
                logoImage: dataUrl,
                colorDark: response.data.color,
                text: window.location.href,
              });
            });
          } else {
            setQr({
              ...qr,
              logoImage: "",
              colorDark: response.data.color,
              text: window.location.href,
            });
          }
        } else {
          setQr({
            ...qr,
            logoImage: "",
            colorDark: "#000000",
            text: window.location.href,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toDataURL = (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  return (
    <>
      {ready && (
        <div
          id="qr-container"
          style={{ display: "flex", zIndex: 1000 }}
          onClick={() => {
            onClose();
          }}
        >
          <div className="m-message ">{t("common.scan_to_see_ar")}</div>
          <div id="qrcode" className="qr-modal-qr">
            <AwesomeQRCode options={qr} />
          </div>

          <p id="close-subtitle-sty" >{t("common.close_message")}</p>

          <div className="tooltip-container">
            <button className="tooltip-target" onClick={handleButtonClick}>
              <HelpOutline
              fontSize="large"
              className="help-btn-icon"
            />
            </button>
            {showTooltip && (
              <span className="tooltip-content">
                <p className="appar-tooltip-text">{t("common.scan_qr_code")}</p>
                <p className="appar-tooltip-text">
                  {t("common.supported_devices")}
                </p>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

QRModal.propTypes = {
  onClose: PropTypes.func,
};

QRModal.defaultProps = {
  onClose: () => {},
};

export default QRModal;
