import { useEffect, useState } from "react";
import { ThreeHelper, Math, Generic, HelpMessages } from "../utils";
import axios from "axios";
import { useParams } from "react-router";
import { HelpModal, BottomModal, Error } from "../components";
import { useTranslation } from "react-i18next";
import { Raycaster, Vector2, MeshStandardMaterial } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const RoomBuilder = () => {
  const { collection_id } = useParams();

  const { t, i18n, ready } = useTranslation();

  const [error, setError] = useState();
  const [collection, setCollection] = useState([]);
  const [forceOpenModal, setForceOpenModal] = useState(false);
  const [ARActive, setARActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [anySelected, setAnySelected] = useState(false);

  useEffect(() => {
    if (ready) {
      getCollection();
    }
  }, [ready]);

  const LoadThree = async () => {
    //#region Variables
    let modelIsLoading = false;
    let bottomModalOpen = true;
    let apparModel = null;
    let editingModel = null;
    let selectedModel = {
      original: null,
      temp: null,
    };

    const tap = {
      startedAt: null,
      startPostion: { x: null, y: null },
      lastPosition: { x: null, y: null },
    };

    const sceneModels = [];
    const canvas = document.getElementById("canvas");
    //#endregion Variables

    //#region Initialization
    const raycaster = new Raycaster();
    let hitData = { initialized: false };

    const enviroment = ThreeHelper.initializeEnvironment(canvas);

    ThreeHelper.setUpAR(enviroment.renderer, canvas);

    const loader = new GLTFLoader();

    const reticle = ThreeHelper.createReticle();
    enviroment.scene.add(reticle);

    const controller = enviroment.renderer.xr.getController(0);
    enviroment.scene.add(controller);
    //#endregion Initialization

    //#region Events

    document.addEventListener("onarstart", (e) => {
      apparModel.visible = false;
      loadNewModel(e.detail.android_model_url, e.detail.android_request_token);
      setARActive(true);
    });

    document.addEventListener("closear", () => {
      enviroment.renderer.xr.getSession().end();
      while (enviroment.scene.children.length > 0) {
        enviroment.scene.remove(enviroment.scene.children[0]);
      }
    });

    window.addEventListener("resize", () => {
      enviroment.camera.aspect = window.innerWidth / window.innerHeight;
      enviroment.camera.updateProjectionMatrix();
      enviroment.renderer.setSize(window.innerWidth, window.innerHeight);
    });

    document.addEventListener("changebotommodal", (e) => {
      bottomModalOpen = e.detail;
    });

    document.addEventListener("changemodel", (e) => {
      unselectModel();
      if (!modelIsLoading && !editingModel) {
        loadNewModel(e.detail.android_model_url);
      }
    });

    document.addEventListener("resetscene", () => {
      unselectModel();
      sceneModels.forEach((element) => {
        enviroment.scene.remove(element);
      });
    });

    document.addEventListener("removemodel", () => {
      if (selectedModel.original) {
        enviroment.scene.remove(selectedModel.original);
        enviroment.scene.remove(selectedModel.temp);

        selectedModel.original = null;
        selectedModel.temp = null;
      } else if (editingModel) {
        enviroment.scene.remove(editingModel);
        editingModel = null;
      }

      setAnySelected(false);
      setIsEditing(false);
    });

    document.addEventListener("editmodel", () => {
      setIsEditing(true);
      editingModel = selectedModel.original;
      unselectModel();
    });
    //#endregion Events

    //#region Controls
    document.addEventListener("touchstart", (e) => {
      tap.startedAt = new Date();
      tap.startPostion = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      };
      tap.lastPosition = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      };
    });

    document.addEventListener("touchmove", (e) => {
      let movement = 0;

      movement =
        Math.normalize(e.touches[0].pageX, 0, window.innerWidth) -
        Math.normalize(
          tap.lastPosition.x ? tap.lastPosition.x : tap.startPostion.x,
          0,
          window.innerWidth
        );

      if (editingModel) {
        editingModel.rotation.y += Math.clamp(movement * 3, -0.2, 0.2);
      }

      tap.lastPosition = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      };
    });

    document.addEventListener("touchend", () => {
      const normalizedX = Math.clamp(
        Math.normalize(tap.lastPosition.x, 0, window.innerWidth),
        0,
        1
      );
      const normalizedY = Math.clamp(
        Math.normalize(tap.lastPosition.y, 0, window.innerHeight),
        0,
        1
      );

      if (new Date().getTime() - tap.startedAt.getTime() < 100) {
        if (
          normalizedY < (bottomModalOpen ? 0.8 : 0.94) &&
          normalizedX < 0.77
        ) {
          if (editingModel) {
            editingModel = null;
            setIsEditing(false);
          } else {
            const pointer = new Vector2();

            pointer.x = (tap.lastPosition.x / window.innerWidth) * 2 - 1;
            pointer.y = -(tap.lastPosition.y / window.innerHeight) * 2 + 1;

            raycaster.setFromCamera(pointer, enviroment.camera);

            const intersects = raycaster.intersectObjects(
              enviroment.scene.children,
              true
            );

            if (intersects.length > 0) {
              let root = null;
              let intersection = intersects[0].object;

              if (
                intersection.name != "Shadow reciver" &&
                intersection.name != "reticle"
              ) {
                intersection.traverseAncestors((node) => {
                  if (node.parent == enviroment.scene) {
                    root = node;
                  }
                });

                if (root.name != "AppAR_Logo") {
                  if (root == selectedModel.temp) {
                    unselectModel();
                  } else {
                    setAnySelected(true);

                    if (selectedModel.original) {
                      enviroment.scene.remove(selectedModel.temp);
                      enviroment.scene.add(selectedModel.original);
                    }

                    selectedModel.original = root;
                    selectedModel.temp = root.clone();
                    selectedModel.temp.name = "selected";

                    enviroment.scene.remove(selectedModel.original);
                    enviroment.scene.add(selectedModel.temp);

                    const selectedMaterial = new MeshStandardMaterial({
                      color: 0x009fe3,
                    });

                    selectedModel.temp.traverse((node) => {
                      if (
                        node.isMesh &&
                        node.name != "Shadow reciver" &&
                        node.name != "reticle"
                      ) {
                        node.material = selectedMaterial;
                      }
                    });
                  }
                }
              }
            }
          }
        }
      }
    });
    //#endregion Controls

    loader.load("/Appar.glb", (gltf) => {
      apparModel = gltf.scene;
      apparModel.name = "AppAR_Logo";
      apparModel.castShadow = true;
      apparModel.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
        }
      });

      ThreeHelper.createShadowPlane(apparModel, 5);
      apparModel.position.set(0, -1, -3);
      apparModel.scale.set(0.5, 0.5, 0.5);
      apparModel.rotation.set(0, 80, 0);
      enviroment.scene.add(apparModel);
    });

    const loadNewModel = (modelUrl, token) => {
      modelIsLoading = true;
      apparModel.scale.set(0.1, 0.1, 0.1);
      apparModel.visible = true;
      loader.load(
        modelUrl + "?hmac=" + Generic.signModelToken(token),
        (gltf) => {
          modelIsLoading = false;
          apparModel.visible = false;
          const model = gltf.scene;
          model.castShadow = true;
          model.traverse((node) => {
            if (node.isMesh) {
              node.castShadow = true;
            }
          });

          model.name = modelUrl;
          ThreeHelper.createShadowPlane(model, 6);

          enviroment.scene.add(model);
          sceneModels.push(model);

          if (editingModel) {
            enviroment.scene.remove(editingModel);
          }

          editingModel = model;
          model.visible = false;
        }
      );
    };

    const unselectModel = () => {
      setAnySelected(false);

      enviroment.scene.add(selectedModel.original);
      enviroment.scene.remove(selectedModel.temp);

      selectedModel.original = null;
      selectedModel.temp = null;
    };

    enviroment.renderer.setAnimationLoop((timeStamp, frame) => {
      if (apparModel && apparModel.visible) {
        apparModel.rotateY(-0.01);
      }

      if (modelIsLoading) {
        apparModel.position.setFromMatrixPosition(reticle.matrix);
      }

      if (reticle.visible && editingModel) {
        editingModel.visible = true;
        editingModel.position.setFromMatrixPosition(reticle.matrix);
      }

      if (frame) {
        if (!hitData.initialized) {
          ThreeHelper.setUpHitTest(enviroment.renderer).then(
            (data) => (hitData = data)
          );
        } else {
          const hitResults = frame.getHitTestResults(hitData.source);
          if (hitResults.length > 0) {
            const hit = hitResults[0];

            const pose = hit.getPose(hitData.localSpace);

            reticle.visible = true;
            reticle.matrix.fromArray(pose.transform.matrix);
          }
        }
      }

      enviroment.renderer.render(enviroment.scene, enviroment.camera);
    });
  };

  const getCollection = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "collection/" + collection_id)
      .then((res) => {
        setCollection(res.data);
        LoadThree();
      })
      .catch((error) => {
        switch (error.response.data) {
          case "model_not_found":
            setError(t("error.product_not_found"));
            break;
          case "product_not_available":
            setError(t("error.augmentation_not_available"));
            break;
          default:
            setError(t(`error.${error.response.data}`));
            break;
        }
      });
  };

  return error ? (
    <Error message={error} />
  ) : (
    <>
      {ready && (
        <button
          id="ar-button"
          className="animate__animated animate__pulse animate__slow animate__infinite"
          onClick={() => {
            document.dispatchEvent(
              new CustomEvent("onarstart", { detail: collection.products[0] })
            );
            document.getElementById("ARButton").click();
            setForceOpenModal(true);
          }}
          style={{ borderColor: "#5C499F", maxWidth: 175 }}
        >
          <img
            className="mg-img"
            style={{ marginRight: 10 }}
            src="/images/space.svg"
          />
          {t("common.view_in_your_space")}
        </button>
      )}
      <div className="div-onboard">
        {t("common.current_language") == "ES" ? (
          <object
            data="/images/RBOnboard.svg"
            alt=""
            height="75%"
            width="100%"
            className="fade-in-image "
          />
        ) : (
          <object
            data="/images/RBOnboardEN.svg"
            alt=""
            height="75%"
            width="100%"
            className="fade-in-image "
          />
        )}
      </div>

      <div id="overlay">
        {ARActive && (
          <>
            <div
              style={{
                position: "absolute",
                top: 30,
                left: 10,
                zIndex: 1,
                background: "none",
                borderStyle: "none",
              }}
              onClick={() => {
                document.dispatchEvent(new CustomEvent("closear"));
              }}
            >
              <img
                className="help-img"
                style={{ maxWidth: 40, maxHeight: 40 }}
                src="/images/close.svg"
              />
            </div>
            <BottomModal
              classname="bottom-modal"
              content={collection.products}
              onOpen={(isOpen) =>
                document.dispatchEvent(
                  new CustomEvent("changebotommodal", { detail: isOpen })
                )
              }
              closeOnUnfocus={false}
              forceOpen={forceOpenModal}
              onSlideSelect={(product) => {
                document.dispatchEvent(
                  new CustomEvent("changemodel", { detail: product })
                );
              }}
            />
            <div
              className="round-button reset-button"
              onClick={() => {
                document.dispatchEvent(new CustomEvent("resetscene"));
              }}
            >
              <img src="/images/remove.png" className="remove-img" />
              <p className="p-margin  p-size" style={{ marginRight: "2px" }}>
                {t("common.reorder")}
              </p>
            </div>
            {anySelected && !isEditing && (
              <div
                className="round-button edit-button"
                onClick={() => {
                  document.dispatchEvent(new CustomEvent("editmodel"));
                }}
              >
                <img src="/images/move.png" className="move-img" />
                <p className="p-margin ">{t("common.move")}</p>
              </div>
            )}

            {(anySelected || isEditing) && (
              <div
                className="round-button remove-button"
                onClick={() => {
                  document.dispatchEvent(new CustomEvent("removemodel"));
                }}
              >
                <img src="/images/trash.png" className="trash-img" />
                <p className="p-margin"> {t("common.delete")}</p>
              </div>
            )}
          </>
        )}
        <HelpModal title={HelpMessages.roomBuilder.title} options={HelpMessages.roomBuilder.body} />
      </div>

      <div id="canvas" />
    </>
  );
};

export default RoomBuilder;
