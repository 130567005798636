import { useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { HelpOutline } from "@mui/icons-material";

type Props = {
  /**
   * Title that will be displayed in the modal.
   */
  title: string;
  /**
   * Array of options that includes each element or step for the help modal.
   */
  options: Array<any>;
  /**
   * Defines if the modal is hidden or not.
   */
  hidden: boolean;
  /**
   * Defines the color of the button.
   */
  color: Color;
}

const HelpModal = ({ title, options, hidden, color }: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const createOptions = () => {
    const row: Array<JSX.Element> = [];

    options.map((option) => {
      row.push(
        <tr className="text-color-white">
          <th>
            <img
              style={{
                maxWidth: option.image.maxWidth,
                marginRight: 20,
                marginTop: "22px",
              }}
              src={option.image.src}
            />
          </th>
          <th className="text-align-left">
            <th>
              <b>{t(`messages.${option.title}`)}</b>
            </th>
            {t(`messages.${option.body}`)}
          </th>
          <br></br>
        </tr>
      );
    });

    return row;
  };

  return (
    <Suspense fallback={null}>
      <div>
        {!hidden && (
          <button
            className="help-btn"
            style={{ color: color }}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            <HelpOutline
              fontSize="medium"
              className="help-btn-icon"
            />
            <p className="help-btn-text">{t("common.help")}</p>
          </button>
        )}
        {isOpen && (
          <>
            <div className="help-modal-content-container" onClick={() => setIsOpen(false)}>
              <div
                id="info-container"
                onClick={() => setIsOpen(false)}
                style={{ display: "flex", zIndex: 10, width: "100vw" }}
              >
                <p id="title-sty">{t(`messages.${title}`)}</p>
                <p id="subtitle-sty">{t("messages.subtitle_rb")}</p>

                <div className="content-description">{createOptions()}</div>
                <p id="close-subtitle-sty">{t("common.close_message")}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </Suspense>
  );
};

export default HelpModal;
