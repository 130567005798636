import { configureStore } from "@reduxjs/toolkit";

import teamwearSlice from "../screens/TeamwearViewer/teamwearSlice";

const store = configureStore({
  reducer: {
    teamwear: teamwearSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
