import axios from "axios";
import { transformKeys } from "../utils/generic";

export const getProduct = async (
  id: ID,
  lang: string = "es"
): Promise<{ data: Product | null; error: string | null }> => {
  let data: Product | null = null;
  let error: string | null = null;

  try {
    const res = await axios.get(`model/${id}?lang=${lang}`);

    if (!res.data.android_model_url && !res.data.ios_model_url) {
      error = "theres_no_model_on_this_product";
    } else {
      data = transformKeys<Product>(res.data);
    }
  } catch (e: any) {
    error = e.response.data;
  }

  return { data, error };
};

export const addVisit = async (id: ID) => {
  return await axios.put(`model/${id}/visit`);
};

export const addAugmentation = async (id: ID) => {
  return await axios.put(`model/${id}/augmentation`);
};
