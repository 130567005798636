// @ts-nocheck
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";
import { useRef } from "react";

type Props = {
  type: Archetype
}
const Archetype = (props: Props) => {
  const { type } = props;
  const ref = useRef(null);

  const { scene } = useLoader(
    GLTFLoader,
    `https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/models/archetypes/${type}.glb`
  );

  return (
    <>
      <primitive object={scene} scale={[1, 1, 1]} position={[0, -1, 0]} ref={ref} />
    </>
  );
};

export default Archetype;
