export const DEFAULT_COLOR = '#5C499F'

export const FACE_MESH_VERTEX_BUFFER = [
    0, -3.406404, 5.979507, 0.499977, 0.652534, 0, -1.126865, 7.475604, 0.500026,
    0.547487, 0, -2.089024, 6.058267, 0.499974, 0.602372, -0.463928, 0.955357,
    6.633583, 0.482113, 0.471979, 0, -0.46317, 7.58658, 0.500151, 0.527156, 0,
    0.365669, 7.24287, 0.49991, 0.498253, 0, 2.473255, 5.788627, 0.499523,
    0.401062, -4.253081, 2.577646, 3.279702, 0.289712, 0.380764, 0, 4.019042,
    5.284764, 0.499955, 0.312398, 0, 4.885979, 5.385258, 0.499987, 0.269919, 0,
    8.261778, 4.481535, 0.500023, 0.10705, 0, -3.706811, 5.864924, 0.500023,
    0.666234, 0, -3.918301, 5.56943, 0.500016, 0.679224, 0, -3.994436, 5.219482,
    0.500023, 0.692348, 0, -4.5424, 5.404754, 0.499977, 0.695278, 0, -4.745577,
    5.529457, 0.499977, 0.705934, 0, -5.019567, 5.601448, 0.499977, 0.719385, 0,
    -5.365123, 5.535441, 0.499977, 0.737019, 0, -6.149624, 5.071372, 0.499968,
    0.781371, 0, -1.501095, 7.112196, 0.499816, 0.562981, -0.416106, -1.466449,
    6.447657, 0.473773, 0.57391, -7.08796, 5.434801, 0.09962, 0.104907, 0.254141,
    -2.628639, 2.035898, 3.848121, 0.36593, 0.409576, -3.198363, 1.985815,
    3.796952, 0.338758, 0.413025, -3.775151, 2.039402, 3.646194, 0.31112, 0.40946,
    -4.465819, 2.42295, 3.155168, 0.274658, 0.389131, -2.164289, 2.189867,
    3.851822, 0.393362, 0.403706, -3.208229, 3.223926, 4.115822, 0.345234,
    0.344011, -2.673803, 3.205337, 4.092203, 0.370094, 0.346076, -3.745193,
    3.165286, 3.972409, 0.319322, 0.347265, -4.161018, 3.059069, 3.719554,
    0.297903, 0.353591, -5.062006, 1.934418, 2.776093, 0.247792, 0.41081,
    -2.266659, -7.425768, 4.389812, 0.396889, 0.842755, -4.445859, 2.663991,
    3.173422, 0.280098, 0.3756, -7.21453, 2.263009, 0.07315, 0.10631, 0.399956,
    -5.799793, 2.349546, 2.204059, 0.209925, 0.391353, -2.844939, -0.720868,
    4.43313, 0.355808, 0.534406, -0.711452, -3.329355, 5.877044, 0.471751,
    0.650404, -0.606033, -3.924562, 5.444923, 0.474155, 0.680192, -1.431615,
    -3.500953, 5.496189, 0.439785, 0.657229, -1.91491, -3.803146, 5.02893,
    0.414617, 0.666541, -1.131043, -3.973937, 5.189648, 0.450374, 0.680861,
    -1.563548, -4.082763, 4.842263, 0.428771, 0.682691, -2.650112, -5.003649,
    4.188483, 0.374971, 0.727805, -0.427049, -1.094134, 7.360529, 0.486717,
    0.547629, -0.496396, -0.475659, 7.440358, 0.485301, 0.527395, -5.253307,
    3.881582, 3.363159, 0.257765, 0.31449, -1.718698, 0.974609, 4.558359,
    0.401223, 0.455172, -1.608635, -0.942516, 5.814193, 0.429819, 0.548615,
    -1.651267, -0.610868, 5.581319, 0.421352, 0.533741, -4.765501, -0.701554,
    3.534632, 0.276896, 0.532057, -0.478306, 0.295766, 7.101013, 0.48337,
    0.499587, -3.734964, 4.50823, 4.550454, 0.337212, 0.282883, -4.588603,
    4.302037, 4.048484, 0.296392, 0.293243, -6.279331, 6.615427, 1.42585,
    0.169295, 0.193814, -1.220941, 4.142165, 5.106035, 0.44758, 0.30261,
    -2.193489, 3.100317, 4.000575, 0.39239, 0.353888, -3.102642, -4.352984,
    4.095905, 0.35449, 0.696784, -6.719682, -4.788645, -1.745401, 0.067305,
    0.730105, -1.193824, -1.306795, 5.737747, 0.442739, 0.572826, -0.729766,
    -1.593712, 5.833208, 0.457098, 0.584792, -2.456206, -4.342621, 4.283884,
    0.381974, 0.694711, -2.204823, -4.304508, 4.162499, 0.392389, 0.694203,
    -4.985894, 4.802461, 3.751977, 0.277076, 0.271932, -1.592294, -1.257709,
    5.456949, 0.422552, 0.563233, -2.644548, 4.524654, 4.921559, 0.385919,
    0.281364, -2.760292, 5.100971, 5.01599, 0.383103, 0.25584, -3.523964,
    8.005976, 3.729163, 0.331431, 0.119714, -5.599763, 5.71547, 2.724259,
    0.229924, 0.232003, -3.063932, 6.566144, 4.529981, 0.364501, 0.189114,
    -5.720968, 4.254584, 2.830852, 0.229622, 0.299541, -6.374393, 4.78559,
    1.591691, 0.173287, 0.278748, -0.672728, -3.688016, 5.737804, 0.472879,
    0.666198, -1.26256, -3.787691, 5.417779, 0.446828, 0.668527, -1.732553,
    -3.952767, 5.000579, 0.422762, 0.67389, -1.043625, -1.464973, 5.662455,
    0.445308, 0.580066, -2.321234, -4.329069, 4.258156, 0.388103, 0.693961,
    -2.056846, -4.477671, 4.520883, 0.403039, 0.70654, -2.153084, -4.276322,
    4.038093, 0.403629, 0.693953, -0.946874, -1.035249, 6.512274, 0.460042,
    0.557139, -1.469132, -4.036351, 4.604908, 0.431158, 0.692366, -1.02434,
    -3.989851, 4.926693, 0.452182, 0.692366, -0.533422, -3.993222, 5.138202,
    0.475387, 0.692366, -0.76972, -6.095394, 4.985883, 0.465828, 0.77919,
    -0.699606, -5.29185, 5.448304, 0.472329, 0.736226, -0.669687, -4.94977,
    5.509612, 0.473087, 0.717857, -0.630947, -4.695101, 5.449371, 0.473122,
    0.704626, -0.583218, -4.517982, 5.339869, 0.473033, 0.695278, -1.53717,
    -4.423206, 4.74547, 0.427942, 0.695278, -1.6156, -4.475942, 4.813632,
    0.426479, 0.70354, -1.729053, -4.61868, 4.854463, 0.423162, 0.711846,
    -1.838624, -4.828746, 4.823737, 0.418309, 0.720063, -2.36825, -3.106237,
    4.868096, 0.390095, 0.639573, -7.542244, -1.049282, -2.431321, 0.013954,
    0.560034, 0, -1.724003, 6.60139, 0.499914, 0.580147, -1.826614, -4.399531,
    4.399021, 0.4132, 0.6954, -1.929558, -4.411831, 4.497052, 0.409626, 0.701823,
    -0.597442, -2.013686, 5.866456, 0.46808, 0.601535, -1.405627, -1.714196,
    5.241087, 0.422729, 0.585985, -0.662449, -1.819321, 5.863759, 0.46308,
    0.593784, -2.34234, 0.572222, 4.294303, 0.37212, 0.473414, -3.327324,
    0.104863, 4.11386, 0.334562, 0.496073, -1.726175, -0.919165, 5.273355,
    0.411671, 0.546965, -5.133204, 7.485602, 2.660442, 0.242176, 0.147676,
    -4.538641, 6.319907, 3.683424, 0.290777, 0.201446, -3.986562, 5.109487,
    4.466315, 0.327338, 0.256527, -2.169681, -5.440433, 4.455874, 0.39951,
    0.748921, -1.395634, 5.011963, 5.316032, 0.441728, 0.261676, -1.6195,
    6.599217, 4.921106, 0.429765, 0.187834, -1.891399, 8.236377, 4.274997,
    0.412198, 0.108901, -4.195832, 2.235205, 3.375099, 0.288955, 0.398952,
    -5.733342, 1.411738, 2.431726, 0.218937, 0.435411, -1.859887, 2.355757,
    3.843181, 0.412782, 0.39897, -4.988612, 3.074654, 3.083858, 0.257135, 0.35544,
    -1.303263, 1.416453, 4.831091, 0.427685, 0.437961, -1.305757, -0.672779,
    6.415959, 0.44834, 0.536936, -6.46517, 0.937119, 1.689873, 0.17856, 0.457554,
    -5.258659, 0.945811, 2.974312, 0.247308, 0.457194, -4.432338, 0.722096,
    3.522615, 0.286267, 0.467675, -3.300681, 0.861641, 3.872784, 0.332828,
    0.460712, -2.430178, 1.131492, 4.039035, 0.368756, 0.447207, -1.820731,
    1.467954, 4.224124, 0.398964, 0.432655, -0.563221, 2.307693, 5.566789,
    0.47641, 0.405806, -6.338145, -0.529279, 1.881175, 0.189241, 0.523924,
    -5.587698, 3.208071, 2.687839, 0.228962, 0.348951, -0.242624, -1.462857,
    7.071491, 0.490726, 0.562401, -1.611251, 0.339326, 4.895421, 0.40467,
    0.485133, -7.743095, 2.364999, -2.005167, 0.019469, 0.401564, -1.391142,
    1.851048, 4.448999, 0.426243, 0.420431, -1.785794, -0.978284, 4.85047,
    0.396993, 0.548797, -4.670959, 2.664461, 3.084075, 0.26647, 0.376977,
    -1.33397, -0.283761, 6.097047, 0.439121, 0.518958, -7.270895, -2.890917,
    -2.252455, 0.032314, 0.644357, -1.856432, 2.585245, 3.757904, 0.419054,
    0.387155, -0.923388, 0.073076, 6.671944, 0.462783, 0.505747, -5.000589,
    -6.135128, 1.892523, 0.238979, 0.779745, -5.085276, -7.17859, 0.714711,
    0.198221, 0.831938, -7.159291, -0.81182, -0.072044, 0.10755, 0.540755,
    -5.843051, -5.248023, 0.924091, 0.18361, 0.740257, -6.847258, 3.662916,
    0.724695, 0.13441, 0.333683, -2.412942, -8.258853, 4.119213, 0.385764,
    0.883154, -0.179909, -1.689864, 6.573301, 0.490967, 0.579378, -2.103655,
    -0.163946, 4.566119, 0.382385, 0.508573, -6.407571, 2.236021, 1.560843,
    0.174399, 0.397671, -3.670075, 2.360153, 3.63523, 0.318785, 0.396235,
    -3.177186, 2.294265, 3.775704, 0.343364, 0.400597, -2.196121, -4.598322,
    4.479786, 0.3961, 0.710217, -6.234883, -1.94443, 1.663542, 0.187885, 0.588538,
    -1.292924, -9.29592, 4.094063, 0.430987, 0.944065, -3.210651, -8.533278,
    2.802001, 0.318993, 0.898285, -4.068926, -7.993109, 1.925119, 0.266248,
    0.869701, 0, 6.54539, 5.027311, 0.500023, 0.190576, 0, -9.403378, 4.264492,
    0.499977, 0.954453, -2.724032, 2.315802, 3.777151, 0.36617, 0.398822,
    -2.28846, 2.398891, 3.697603, 0.393207, 0.395537, -1.998311, 2.496547,
    3.689148, 0.410373, 0.39108, -6.13004, 3.399261, 2.038516, 0.194993, 0.342102,
    -2.28846, 2.886504, 3.775031, 0.388665, 0.362284, -2.724032, 2.96181,
    3.871767, 0.365962, 0.355971, -3.177186, 2.964136, 3.876973, 0.343364,
    0.355357, -3.670075, 2.927714, 3.724325, 0.318785, 0.35834, -4.018389,
    2.857357, 3.482983, 0.301415, 0.363156, -7.555811, 4.106811, -0.991917,
    0.058133, 0.319076, -4.018389, 2.483695, 3.440898, 0.301415, 0.387449, 0,
    -2.521945, 5.932265, 0.499988, 0.618434, -1.776217, -2.683946, 5.213116,
    0.415838, 0.624196, -1.222237, -1.182444, 5.952465, 0.445682, 0.566077,
    -0.731493, -2.536683, 5.815343, 0.465844, 0.620641, 0, 3.271027, 5.236015,
    0.499923, 0.351524, -4.135272, -6.996638, 2.67197, 0.288719, 0.819946,
    -3.311811, -7.660815, 3.382963, 0.335279, 0.85282, -1.313701, -8.639995,
    4.702456, 0.440512, 0.902419, -5.940524, -6.223629, -0.631468, 0.128294,
    0.791941, -1.998311, 2.743838, 3.74403, 0.408772, 0.373894, -0.901447,
    1.236992, 5.754256, 0.455607, 0.451801, 0, -8.765243, 4.891441, 0.499877,
    0.90899, -2.308977, -8.974196, 3.60907, 0.375437, 0.924192, -6.954154,
    -2.439843, -0.131163, 0.11421, 0.615022, -1.098819, -4.458788, 5.120727,
    0.448662, 0.695278, -1.181124, -4.579996, 5.189564, 0.44802, 0.704632,
    -1.255818, -4.787901, 5.237051, 0.447112, 0.715808, -1.325085, -5.106507,
    5.20501, 0.444832, 0.730794, -1.546388, -5.819392, 4.757893, 0.430012,
    0.766809, -1.953754, -4.183892, 4.431713, 0.406787, 0.685673, -2.117802,
    -4.137093, 4.555096, 0.400738, 0.681069, -2.285339, -4.051196, 4.582438,
    0.3924, 0.677703, -2.85016, -3.66572, 4.484994, 0.367856, 0.663919, -5.278538,
    -2.238942, 2.861224, 0.247923, 0.601333, -0.946709, 1.907628, 5.196779,
    0.45277, 0.42085, -1.314173, 3.104912, 4.231404, 0.436392, 0.359887, -1.78,
    2.86, 3.881555, 0.416164, 0.368714, -1.84511, -4.09888, 4.247264, 0.413386,
    0.692366, -5.436187, -4.030482, 2.109852, 0.228018, 0.683572, -0.766444,
    3.182131, 4.861453, 0.468268, 0.352671, -1.938616, -6.61441, 4.521085,
    0.411362, 0.804327, 0, 1.059413, 6.774605, 0.499989, 0.469825, -0.516573,
    1.583572, 6.148363, 0.479154, 0.442654, 0, 1.728369, 6.31675, 0.499974,
    0.439637, -1.246815, 0.230297, 5.681036, 0.432112, 0.493589, 0, -7.942194,
    5.181173, 0.499886, 0.866917, 0, -6.991499, 5.153478, 0.499913, 0.821729,
    -0.997827, -6.930921, 4.979576, 0.456549, 0.819201, -3.288807, -5.382514,
    3.795752, 0.344549, 0.745439, -2.311631, -1.566237, 4.590085, 0.378909,
    0.57401, -2.68025, -6.111567, 4.096152, 0.374293, 0.780185, -3.832928,
    -1.537326, 4.137731, 0.319688, 0.570738, -2.96186, -2.274215, 4.440943,
    0.357155, 0.60427, -4.386901, -2.683286, 3.643886, 0.295284, 0.621581,
    -1.217295, -7.834465, 4.969286, 0.44775, 0.862477, -1.542374, -0.136843,
    5.201008, 0.410986, 0.508723, -3.878377, -6.041764, 3.311079, 0.313951,
    0.775308, -3.084037, -6.809842, 3.814195, 0.354128, 0.812553, -3.747321,
    -4.503545, 3.726453, 0.324548, 0.703993, -6.094129, -3.205991, 1.473482,
    0.189096, 0.6463, -4.588995, -4.728726, 2.983221, 0.279777, 0.714658,
    -6.583231, -3.941269, 0.070268, 0.133823, 0.682701, -3.49258, -3.19582,
    4.130198, 0.336768, 0.644733, -1.255543, 0.802341, 5.307551, 0.429884,
    0.466522, -1.126122, -0.933602, 6.538785, 0.455528, 0.548623, -1.443109,
    -1.142774, 5.905127, 0.437114, 0.558896, -0.923043, -0.529042, 7.003423,
    0.467288, 0.529925, -1.755386, 3.529117, 4.327696, 0.414712, 0.33522,
    -2.632589, 3.713828, 4.364629, 0.377046, 0.322778, -3.388062, 3.721976,
    4.309028, 0.344108, 0.320151, -4.075766, 3.675413, 4.076063, 0.312876,
    0.322332, -4.62291, 3.474691, 3.646321, 0.283526, 0.33319, -5.171755,
    2.535753, 2.670867, 0.241246, 0.382786, -7.297331, 0.763172, -0.048769,
    0.102986, 0.468763, -4.706828, 1.651, 3.109532, 0.267612, 0.42456, -4.071712,
    1.476821, 3.476944, 0.297879, 0.433176, -3.269817, 1.470659, 3.731945,
    0.333434, 0.433878, -2.527572, 1.617311, 3.865444, 0.366427, 0.426116,
    -1.970894, 1.858505, 3.961782, 0.396012, 0.416696, -1.579543, 2.097941,
    4.084996, 0.420121, 0.410228, -7.664182, 0.673132, -2.435867, 0.007561,
    0.480777, -1.397041, -1.340139, 5.630378, 0.432949, 0.569518, -0.884838,
    0.65874, 6.233232, 0.458639, 0.479089, -0.767097, -0.968035, 7.077932,
    0.473466, 0.545744, -0.460213, -1.334106, 6.787447, 0.476088, 0.56383,
    -0.748618, -1.067994, 6.798303, 0.468472, 0.555057, -1.236408, -1.585568,
    5.48049, 0.433991, 0.582362, -0.387306, -1.40999, 6.957705, 0.483518,
    0.562984, -0.319925, -1.607931, 6.508676, 0.482483, 0.577849, -1.639633,
    2.556298, 3.863736, 0.42645, 0.389799, -1.255645, 2.467144, 4.2038, 0.438999,
    0.396495, -1.031362, 2.382663, 4.615849, 0.450067, 0.400434, -4.253081,
    2.772296, 3.315305, 0.289712, 0.368253, -4.53, 2.91, 3.339685, 0.27667,
    0.363373, 0.463928, 0.955357, 6.633583, 0.517862, 0.471948, 4.253081,
    2.577646, 3.279702, 0.710288, 0.380764, 0.416106, -1.466449, 6.447657,
    0.526227, 0.57391, 7.08796, 5.434801, 0.09962, 0.895093, 0.254141, 2.628639,
    2.035898, 3.848121, 0.63407, 0.409576, 3.198363, 1.985815, 3.796952, 0.661242,
    0.413025, 3.775151, 2.039402, 3.646194, 0.68888, 0.40946, 4.465819, 2.42295,
    3.155168, 0.725342, 0.389131, 2.164289, 2.189867, 3.851822, 0.60663, 0.403705,
    3.208229, 3.223926, 4.115822, 0.654766, 0.344011, 2.673803, 3.205337,
    4.092203, 0.629906, 0.346076, 3.745193, 3.165286, 3.972409, 0.680678,
    0.347265, 4.161018, 3.059069, 3.719554, 0.702097, 0.353591, 5.062006,
    1.934418, 2.776093, 0.752212, 0.410805, 2.266659, -7.425768, 4.389812,
    0.602918, 0.842863, 4.445859, 2.663991, 3.173422, 0.719902, 0.3756, 7.21453,
    2.263009, 0.07315, 0.893693, 0.39996, 5.799793, 2.349546, 2.204059, 0.790082,
    0.391354, 2.844939, -0.720868, 4.43313, 0.643998, 0.534488, 0.711452,
    -3.329355, 5.877044, 0.528249, 0.650404, 0.606033, -3.924562, 5.444923,
    0.52585, 0.680191, 1.431615, -3.500953, 5.496189, 0.560215, 0.657229, 1.91491,
    -3.803146, 5.02893, 0.585384, 0.666541, 1.131043, -3.973937, 5.189648,
    0.549626, 0.680861, 1.563548, -4.082763, 4.842263, 0.571228, 0.682692,
    2.650112, -5.003649, 4.188483, 0.624852, 0.728099, 0.427049, -1.094134,
    7.360529, 0.51305, 0.547282, 0.496396, -0.475659, 7.440358, 0.515097,
    0.527252, 5.253307, 3.881582, 3.363159, 0.742247, 0.314507, 1.718698,
    0.974609, 4.558359, 0.598631, 0.454979, 1.608635, -0.942516, 5.814193,
    0.570338, 0.548575, 1.651267, -0.610868, 5.581319, 0.578632, 0.533623,
    4.765501, -0.701554, 3.534632, 0.723087, 0.532054, 0.478306, 0.295766,
    7.101013, 0.516446, 0.499639, 3.734964, 4.50823, 4.550454, 0.662801, 0.282918,
    4.588603, 4.302037, 4.048484, 0.703624, 0.293271, 6.279331, 6.615427, 1.42585,
    0.830705, 0.193814, 1.220941, 4.142165, 5.106035, 0.552386, 0.302568,
    2.193489, 3.100317, 4.000575, 0.60761, 0.353888, 3.102642, -4.352984,
    4.095905, 0.645429, 0.696707, 6.719682, -4.788645, -1.745401, 0.932695,
    0.730105, 1.193824, -1.306795, 5.737747, 0.557261, 0.572826, 0.729766,
    -1.593712, 5.833208, 0.542902, 0.584792, 2.456206, -4.342621, 4.283884,
    0.618026, 0.694711, 2.204823, -4.304508, 4.162499, 0.607591, 0.694203,
    4.985894, 4.802461, 3.751977, 0.722943, 0.271963, 1.592294, -1.257709,
    5.456949, 0.577414, 0.563167, 2.644548, 4.524654, 4.921559, 0.614083,
    0.281387, 2.760292, 5.100971, 5.01599, 0.616907, 0.255886, 3.523964, 8.005976,
    3.729163, 0.668509, 0.119914, 5.599763, 5.71547, 2.724259, 0.770092, 0.232021,
    3.063932, 6.566144, 4.529981, 0.635536, 0.189249, 5.720968, 4.254584,
    2.830852, 0.770391, 0.299556, 6.374393, 4.78559, 1.591691, 0.826722, 0.278755,
    0.672728, -3.688016, 5.737804, 0.527121, 0.666198, 1.26256, -3.787691,
    5.417779, 0.553172, 0.668527, 1.732553, -3.952767, 5.000579, 0.577238,
    0.67389, 1.043625, -1.464973, 5.662455, 0.554692, 0.580066, 2.321234,
    -4.329069, 4.258156, 0.611897, 0.693961, 2.056846, -4.477671, 4.520883,
    0.596961, 0.70654, 2.153084, -4.276322, 4.038093, 0.596371, 0.693953,
    0.946874, -1.035249, 6.512274, 0.539958, 0.557139, 1.469132, -4.036351,
    4.604908, 0.568842, 0.692366, 1.02434, -3.989851, 4.926693, 0.547818,
    0.692366, 0.533422, -3.993222, 5.138202, 0.524613, 0.692366, 0.76972,
    -6.095394, 4.985883, 0.53409, 0.779141, 0.699606, -5.29185, 5.448304,
    0.527671, 0.736226, 0.669687, -4.94977, 5.509612, 0.526913, 0.717857,
    0.630947, -4.695101, 5.449371, 0.526878, 0.704626, 0.583218, -4.517982,
    5.339869, 0.526967, 0.695278, 1.53717, -4.423206, 4.74547, 0.572058, 0.695278,
    1.6156, -4.475942, 4.813632, 0.573521, 0.70354, 1.729053, -4.61868, 4.854463,
    0.576838, 0.711846, 1.838624, -4.828746, 4.823737, 0.581691, 0.720063,
    2.36825, -3.106237, 4.868096, 0.609945, 0.63991, 7.542244, -1.049282,
    -2.431321, 0.986046, 0.560034, 1.826614, -4.399531, 4.399021, 0.5868, 0.6954,
    1.929558, -4.411831, 4.497052, 0.590372, 0.701823, 0.597442, -2.013686,
    5.866456, 0.531915, 0.601537, 1.405627, -1.714196, 5.241087, 0.577268,
    0.585935, 0.662449, -1.819321, 5.863759, 0.536915, 0.593786, 2.34234,
    0.572222, 4.294303, 0.627543, 0.473352, 3.327324, 0.104863, 4.11386, 0.665586,
    0.495951, 1.726175, -0.919165, 5.273355, 0.588354, 0.546862, 5.133204,
    7.485602, 2.660442, 0.757824, 0.147676, 4.538641, 6.319907, 3.683424, 0.70925,
    0.201508, 3.986562, 5.109487, 4.466315, 0.672684, 0.256581, 2.169681,
    -5.440433, 4.455874, 0.600409, 0.749005, 1.395634, 5.011963, 5.316032,
    0.558266, 0.261672, 1.6195, 6.599217, 4.921106, 0.570304, 0.187871, 1.891399,
    8.236377, 4.274997, 0.588166, 0.109044, 4.195832, 2.235205, 3.375099,
    0.711045, 0.398952, 5.733342, 1.411738, 2.431726, 0.78107, 0.435405, 1.859887,
    2.355757, 3.843181, 0.587247, 0.398932, 4.988612, 3.074654, 3.083858, 0.74287,
    0.355446, 1.303263, 1.416453, 4.831091, 0.572156, 0.437652, 1.305757,
    -0.672779, 6.415959, 0.551868, 0.53657, 6.46517, 0.937119, 1.689873, 0.821442,
    0.457556, 5.258659, 0.945811, 2.974312, 0.752702, 0.457182, 4.432338,
    0.722096, 3.522615, 0.713757, 0.467627, 3.300681, 0.861641, 3.872784,
    0.667113, 0.460673, 2.430178, 1.131492, 4.039035, 0.631101, 0.447154,
    1.820731, 1.467954, 4.224124, 0.600862, 0.432473, 0.563221, 2.307693,
    5.566789, 0.523481, 0.405627, 6.338145, -0.529279, 1.881175, 0.810748,
    0.523926, 5.587698, 3.208071, 2.687839, 0.771046, 0.348959, 0.242624,
    -1.462857, 7.071491, 0.509127, 0.562718, 1.611251, 0.339326, 4.895421,
    0.595293, 0.485024, 7.743095, 2.364999, -2.005167, 0.980531, 0.401564,
    1.391142, 1.851048, 4.448999, 0.5735, 0.42, 1.785794, -0.978284, 4.85047,
    0.602995, 0.548688, 4.670959, 2.664461, 3.084075, 0.73353, 0.376977, 1.33397,
    -0.283761, 6.097047, 0.560611, 0.519017, 7.270895, -2.890917, -2.252455,
    0.967686, 0.644357, 1.856432, 2.585245, 3.757904, 0.580985, 0.38716, 0.923388,
    0.073076, 6.671944, 0.537728, 0.505385, 5.000589, -6.135128, 1.892523,
    0.760966, 0.779753, 5.085276, -7.17859, 0.714711, 0.801779, 0.831938,
    7.159291, -0.81182, -0.072044, 0.892441, 0.540761, 5.843051, -5.248023,
    0.924091, 0.816351, 0.74026, 6.847258, 3.662916, 0.724695, 0.865595, 0.333687,
    2.412942, -8.258853, 4.119213, 0.614074, 0.883246, 0.179909, -1.689864,
    6.573301, 0.508953, 0.579438, 2.103655, -0.163946, 4.566119, 0.617942,
    0.508316, 6.407571, 2.236021, 1.560843, 0.825608, 0.397675, 3.670075,
    2.360153, 3.63523, 0.681215, 0.396235, 3.177186, 2.294265, 3.775704, 0.656636,
    0.400597, 2.196121, -4.598322, 4.479786, 0.6039, 0.710217, 6.234883, -1.94443,
    1.663542, 0.812086, 0.588539, 1.292924, -9.29592, 4.094063, 0.568013,
    0.944565, 3.210651, -8.533278, 2.802001, 0.681008, 0.898285, 4.068926,
    -7.993109, 1.925119, 0.733752, 0.869701, 2.724032, 2.315802, 3.777151,
    0.63383, 0.398822, 2.28846, 2.398891, 3.697603, 0.606793, 0.395537, 1.998311,
    2.496547, 3.689148, 0.58966, 0.391062, 6.13004, 3.399261, 2.038516, 0.805016,
    0.342108, 2.28846, 2.886504, 3.775031, 0.611335, 0.362284, 2.724032, 2.96181,
    3.871767, 0.634038, 0.355971, 3.177186, 2.964136, 3.876973, 0.656636,
    0.355357, 3.670075, 2.927714, 3.724325, 0.681215, 0.35834, 4.018389, 2.857357,
    3.482983, 0.698585, 0.363156, 7.555811, 4.106811, -0.991917, 0.941867,
    0.319076, 4.018389, 2.483695, 3.440898, 0.698585, 0.387449, 1.776217,
    -2.683946, 5.213116, 0.584177, 0.624107, 1.222237, -1.182444, 5.952465,
    0.554318, 0.566077, 0.731493, -2.536683, 5.815343, 0.534154, 0.62064,
    4.135272, -6.996638, 2.67197, 0.711218, 0.819975, 3.311811, -7.660815,
    3.382963, 0.66463, 0.852871, 1.313701, -8.639995, 4.702456, 0.5591, 0.902632,
    5.940524, -6.223629, -0.631468, 0.871706, 0.791941, 1.998311, 2.743838,
    3.74403, 0.591234, 0.373894, 0.901447, 1.236992, 5.754256, 0.544341, 0.451584,
    2.308977, -8.974196, 3.60907, 0.624563, 0.924192, 6.954154, -2.439843,
    -0.131163, 0.88577, 0.615029, 1.098819, -4.458788, 5.120727, 0.551338,
    0.695278, 1.181124, -4.579996, 5.189564, 0.55198, 0.704632, 1.255818,
    -4.787901, 5.237051, 0.552888, 0.715808, 1.325085, -5.106507, 5.20501,
    0.555168, 0.730794, 1.546388, -5.819392, 4.757893, 0.569944, 0.767035,
    1.953754, -4.183892, 4.431713, 0.593203, 0.685676, 2.117802, -4.137093,
    4.555096, 0.599262, 0.681069, 2.285339, -4.051196, 4.582438, 0.6076, 0.677703,
    2.85016, -3.66572, 4.484994, 0.631938, 0.6635, 5.278538, -2.238942, 2.861224,
    0.752033, 0.601315, 0.946709, 1.907628, 5.196779, 0.547226, 0.420395,
    1.314173, 3.104912, 4.231404, 0.563544, 0.359828, 1.78, 2.86, 3.881555,
    0.583841, 0.368714, 1.84511, -4.09888, 4.247264, 0.586614, 0.692366, 5.436187,
    -4.030482, 2.109852, 0.771915, 0.683578, 0.766444, 3.182131, 4.861453,
    0.531597, 0.352483, 1.938616, -6.61441, 4.521085, 0.588371, 0.804441,
    0.516573, 1.583572, 6.148363, 0.520797, 0.442565, 1.246815, 0.230297,
    5.681036, 0.567985, 0.493479, 0.997827, -6.930921, 4.979576, 0.543283,
    0.819255, 3.288807, -5.382514, 3.795752, 0.655317, 0.745515, 2.311631,
    -1.566237, 4.590085, 0.621009, 0.574018, 2.68025, -6.111567, 4.096152,
    0.62556, 0.780312, 3.832928, -1.537326, 4.137731, 0.680198, 0.570719, 2.96186,
    -2.274215, 4.440943, 0.642764, 0.604338, 4.386901, -2.683286, 3.643886,
    0.704663, 0.62153, 1.217295, -7.834465, 4.969286, 0.552012, 0.862592,
    1.542374, -0.136843, 5.201008, 0.589072, 0.508637, 3.878377, -6.041764,
    3.311079, 0.685945, 0.775357, 3.084037, -6.809842, 3.814195, 0.645735,
    0.81264, 3.747321, -4.503545, 3.726453, 0.675343, 0.703978, 6.094129,
    -3.205991, 1.473482, 0.810858, 0.646305, 4.588995, -4.728726, 2.983221,
    0.720122, 0.714667, 6.583231, -3.941269, 0.070268, 0.866152, 0.682705,
    3.49258, -3.19582, 4.130198, 0.663187, 0.644597, 1.255543, 0.802341, 5.307551,
    0.570082, 0.466326, 1.126122, -0.933602, 6.538785, 0.544562, 0.548376,
    1.443109, -1.142774, 5.905127, 0.562759, 0.558785, 0.923043, -0.529042,
    7.003423, 0.531987, 0.53014, 1.755386, 3.529117, 4.327696, 0.585271, 0.335177,
    2.632589, 3.713828, 4.364629, 0.622953, 0.322779, 3.388062, 3.721976,
    4.309028, 0.655896, 0.320163, 4.075766, 3.675413, 4.076063, 0.687132,
    0.322346, 4.62291, 3.474691, 3.646321, 0.716482, 0.333201, 5.171755, 2.535753,
    2.670867, 0.758757, 0.382787, 7.297331, 0.763172, -0.048769, 0.897013,
    0.468769, 4.706828, 1.651, 3.109532, 0.732392, 0.424547, 4.071712, 1.476821,
    3.476944, 0.702114, 0.433163, 3.269817, 1.470659, 3.731945, 0.666525,
    0.433866, 2.527572, 1.617311, 3.865444, 0.633505, 0.426088, 1.970894,
    1.858505, 3.961782, 0.603876, 0.416587, 1.579543, 2.097941, 4.084996,
    0.579658, 0.409945, 7.664182, 0.673132, -2.435867, 0.99244, 0.480777,
    1.397041, -1.340139, 5.630378, 0.567192, 0.56942, 0.884838, 0.65874, 6.233232,
    0.541366, 0.478899, 0.767097, -0.968035, 7.077932, 0.526564, 0.546118,
    0.460213, -1.334106, 6.787447, 0.523913, 0.56383, 0.748618, -1.067994,
    6.798303, 0.531529, 0.555057, 1.236408, -1.585568, 5.48049, 0.566036,
    0.582329, 0.387306, -1.40999, 6.957705, 0.516311, 0.563054, 0.319925,
    -1.607931, 6.508676, 0.517472, 0.577877, 1.639633, 2.556298, 3.863736,
    0.573595, 0.389807, 1.255645, 2.467144, 4.2038, 0.560698, 0.395332, 1.031362,
    2.382663, 4.615849, 0.549756, 0.399751, 4.253081, 2.772296, 3.315305,
    0.710288, 0.368253, 4.53, 2.91, 3.339685, 0.72333, 0.363373,
  ];
  
  export const FACE_MESH_INDEX_BUFFER = [
    173, 155, 133, 246, 33, 7, 382, 398, 362, 263, 466, 249, 308, 415, 324, 78,
    95, 191, 356, 389, 264, 127, 34, 162, 368, 264, 389, 139, 162, 34, 267, 0,
    302, 37, 72, 0, 11, 302, 0, 11, 0, 72, 349, 451, 350, 120, 121, 231, 452, 350,
    451, 232, 231, 121, 267, 302, 269, 37, 39, 72, 303, 269, 302, 73, 72, 39, 357,
    343, 350, 128, 121, 114, 277, 350, 343, 47, 114, 121, 350, 452, 357, 121, 128,
    232, 453, 357, 452, 233, 232, 128, 299, 333, 297, 69, 67, 104, 332, 297, 333,
    103, 104, 67, 175, 152, 396, 175, 171, 152, 377, 396, 152, 148, 152, 171, 381,
    384, 382, 154, 155, 157, 398, 382, 384, 173, 157, 155, 280, 347, 330, 50, 101,
    118, 348, 330, 347, 119, 118, 101, 269, 303, 270, 39, 40, 73, 304, 270, 303,
    74, 73, 40, 9, 336, 151, 9, 151, 107, 337, 151, 336, 108, 107, 151, 344, 278,
    360, 115, 131, 48, 279, 360, 278, 49, 48, 131, 262, 431, 418, 32, 194, 211,
    424, 418, 431, 204, 211, 194, 304, 408, 270, 74, 40, 184, 409, 270, 408, 185,
    184, 40, 272, 310, 407, 42, 183, 80, 415, 407, 310, 191, 80, 183, 322, 270,
    410, 92, 186, 40, 409, 410, 270, 185, 40, 186, 347, 449, 348, 118, 119, 229,
    450, 348, 449, 230, 229, 119, 434, 432, 430, 214, 210, 212, 422, 430, 432,
    202, 212, 210, 313, 314, 18, 83, 18, 84, 17, 18, 314, 17, 84, 18, 307, 375,
    306, 77, 76, 146, 291, 306, 375, 61, 146, 76, 259, 387, 260, 29, 30, 160, 388,
    260, 387, 161, 160, 30, 286, 414, 384, 56, 157, 190, 398, 384, 414, 173, 190,
    157, 418, 424, 406, 194, 182, 204, 335, 406, 424, 106, 204, 182, 367, 416,
    364, 138, 135, 192, 434, 364, 416, 214, 192, 135, 391, 423, 327, 165, 98, 203,
    358, 327, 423, 129, 203, 98, 298, 301, 284, 68, 54, 71, 251, 284, 301, 21, 71,
    54, 4, 275, 5, 4, 5, 45, 281, 5, 275, 51, 45, 5, 254, 373, 253, 24, 23, 144,
    374, 253, 373, 145, 144, 23, 320, 321, 307, 90, 77, 91, 375, 307, 321, 146,
    91, 77, 280, 425, 411, 50, 187, 205, 427, 411, 425, 207, 205, 187, 421, 313,
    200, 201, 200, 83, 18, 200, 313, 18, 83, 200, 335, 321, 406, 106, 182, 91,
    405, 406, 321, 181, 91, 182, 405, 321, 404, 181, 180, 91, 320, 404, 321, 90,
    91, 180, 17, 314, 16, 17, 16, 84, 315, 16, 314, 85, 84, 16, 425, 266, 426,
    205, 206, 36, 423, 426, 266, 203, 36, 206, 369, 396, 400, 140, 176, 171, 377,
    400, 396, 148, 171, 176, 391, 269, 322, 165, 92, 39, 270, 322, 269, 40, 39,
    92, 417, 465, 413, 193, 189, 245, 464, 413, 465, 244, 245, 189, 257, 258, 386,
    27, 159, 28, 385, 386, 258, 158, 28, 159, 260, 388, 467, 30, 247, 161, 466,
    467, 388, 246, 161, 247, 248, 456, 419, 3, 196, 236, 399, 419, 456, 174, 236,
    196, 333, 298, 332, 104, 103, 68, 284, 332, 298, 54, 68, 103, 285, 8, 417, 55,
    193, 8, 168, 417, 8, 168, 8, 193, 340, 261, 346, 111, 117, 31, 448, 346, 261,
    228, 31, 117, 285, 417, 441, 55, 221, 193, 413, 441, 417, 189, 193, 221, 327,
    460, 326, 98, 97, 240, 328, 326, 460, 99, 240, 97, 277, 355, 329, 47, 100,
    126, 371, 329, 355, 142, 126, 100, 309, 392, 438, 79, 218, 166, 439, 438, 392,
    219, 166, 218, 381, 382, 256, 154, 26, 155, 341, 256, 382, 112, 155, 26, 360,
    279, 420, 131, 198, 49, 429, 420, 279, 209, 49, 198, 365, 364, 379, 136, 150,
    135, 394, 379, 364, 169, 135, 150, 355, 277, 437, 126, 217, 47, 343, 437, 277,
    114, 47, 217, 443, 444, 282, 223, 52, 224, 283, 282, 444, 53, 224, 52, 281,
    275, 363, 51, 134, 45, 440, 363, 275, 220, 45, 134, 431, 262, 395, 211, 170,
    32, 369, 395, 262, 140, 32, 170, 337, 299, 338, 108, 109, 69, 297, 338, 299,
    67, 69, 109, 335, 273, 321, 106, 91, 43, 375, 321, 273, 146, 43, 91, 348, 450,
    349, 119, 120, 230, 451, 349, 450, 231, 230, 120, 467, 359, 342, 247, 113,
    130, 446, 342, 359, 226, 130, 113, 282, 283, 334, 52, 105, 53, 293, 334, 283,
    63, 53, 105, 250, 458, 462, 20, 242, 238, 461, 462, 458, 241, 238, 242, 276,
    353, 300, 46, 70, 124, 383, 300, 353, 156, 124, 70, 325, 292, 324, 96, 95, 62,
    308, 324, 292, 78, 62, 95, 283, 276, 293, 53, 63, 46, 300, 293, 276, 70, 46,
    63, 447, 264, 345, 227, 116, 34, 372, 345, 264, 143, 34, 116, 352, 345, 346,
    123, 117, 116, 340, 346, 345, 111, 116, 117, 1, 19, 274, 1, 44, 19, 354, 274,
    19, 125, 19, 44, 248, 281, 456, 3, 236, 51, 363, 456, 281, 134, 51, 236, 425,
    426, 427, 205, 207, 206, 436, 427, 426, 216, 206, 207, 380, 381, 252, 153, 22,
    154, 256, 252, 381, 26, 154, 22, 391, 393, 269, 165, 39, 167, 267, 269, 393,
    37, 167, 39, 199, 428, 200, 199, 200, 208, 421, 200, 428, 201, 208, 200, 330,
    329, 266, 101, 36, 100, 371, 266, 329, 142, 100, 36, 422, 432, 273, 202, 43,
    212, 287, 273, 432, 57, 212, 43, 290, 250, 328, 60, 99, 20, 462, 328, 250,
    242, 20, 99, 258, 286, 385, 28, 158, 56, 384, 385, 286, 157, 56, 158, 342,
    446, 353, 113, 124, 226, 265, 353, 446, 35, 226, 124, 257, 386, 259, 27, 29,
    159, 387, 259, 386, 160, 159, 29, 430, 422, 431, 210, 211, 202, 424, 431, 422,
    204, 202, 211, 445, 342, 276, 225, 46, 113, 353, 276, 342, 124, 113, 46, 424,
    422, 335, 204, 106, 202, 273, 335, 422, 43, 202, 106, 306, 292, 307, 76, 77,
    62, 325, 307, 292, 96, 62, 77, 366, 447, 352, 137, 123, 227, 345, 352, 447,
    116, 227, 123, 302, 268, 303, 72, 73, 38, 271, 303, 268, 41, 38, 73, 371, 358,
    266, 142, 36, 129, 423, 266, 358, 203, 129, 36, 327, 294, 460, 98, 240, 64,
    455, 460, 294, 235, 64, 240, 294, 331, 278, 64, 48, 102, 279, 278, 331, 49,
    102, 48, 303, 271, 304, 73, 74, 41, 272, 304, 271, 42, 41, 74, 427, 436, 434,
    207, 214, 216, 432, 434, 436, 212, 216, 214, 304, 272, 408, 74, 184, 42, 407,
    408, 272, 183, 42, 184, 394, 430, 395, 169, 170, 210, 431, 395, 430, 211, 210,
    170, 395, 369, 378, 170, 149, 140, 400, 378, 369, 176, 140, 149, 296, 334,
    299, 66, 69, 105, 333, 299, 334, 104, 105, 69, 417, 168, 351, 193, 122, 168,
    6, 351, 168, 6, 168, 122, 280, 411, 352, 50, 123, 187, 376, 352, 411, 147,
    187, 123, 319, 320, 325, 89, 96, 90, 307, 325, 320, 77, 90, 96, 285, 295, 336,
    55, 107, 65, 296, 336, 295, 66, 65, 107, 404, 320, 403, 180, 179, 90, 319,
    403, 320, 89, 90, 179, 330, 348, 329, 101, 100, 119, 349, 329, 348, 120, 119,
    100, 334, 293, 333, 105, 104, 63, 298, 333, 293, 68, 63, 104, 323, 454, 366,
    93, 137, 234, 447, 366, 454, 227, 234, 137, 16, 315, 15, 16, 15, 85, 316, 15,
    315, 86, 85, 15, 429, 279, 358, 209, 129, 49, 331, 358, 279, 102, 49, 129, 15,
    316, 14, 15, 14, 86, 317, 14, 316, 87, 86, 14, 8, 285, 9, 8, 9, 55, 336, 9,
    285, 107, 55, 9, 329, 349, 277, 100, 47, 120, 350, 277, 349, 121, 120, 47,
    252, 253, 380, 22, 153, 23, 374, 380, 253, 145, 23, 153, 402, 403, 318, 178,
    88, 179, 319, 318, 403, 89, 179, 88, 351, 6, 419, 122, 196, 6, 197, 419, 6,
    197, 6, 196, 324, 318, 325, 95, 96, 88, 319, 325, 318, 89, 88, 96, 397, 367,
    365, 172, 136, 138, 364, 365, 367, 135, 138, 136, 288, 435, 397, 58, 172, 215,
    367, 397, 435, 138, 215, 172, 438, 439, 344, 218, 115, 219, 278, 344, 439, 48,
    219, 115, 271, 311, 272, 41, 42, 81, 310, 272, 311, 80, 81, 42, 5, 281, 195,
    5, 195, 51, 248, 195, 281, 3, 51, 195, 273, 287, 375, 43, 146, 57, 291, 375,
    287, 61, 57, 146, 396, 428, 175, 171, 175, 208, 199, 175, 428, 199, 208, 175,
    268, 312, 271, 38, 41, 82, 311, 271, 312, 81, 82, 41, 444, 445, 283, 224, 53,
    225, 276, 283, 445, 46, 225, 53, 254, 339, 373, 24, 144, 110, 390, 373, 339,
    163, 110, 144, 295, 282, 296, 65, 66, 52, 334, 296, 282, 105, 52, 66, 346,
    448, 347, 117, 118, 228, 449, 347, 448, 229, 228, 118, 454, 356, 447, 234,
    227, 127, 264, 447, 356, 34, 127, 227, 336, 296, 337, 107, 108, 66, 299, 337,
    296, 69, 66, 108, 151, 337, 10, 151, 10, 108, 338, 10, 337, 109, 108, 10, 278,
    439, 294, 48, 64, 219, 455, 294, 439, 235, 219, 64, 407, 415, 292, 183, 62,
    191, 308, 292, 415, 78, 191, 62, 358, 371, 429, 129, 209, 142, 355, 429, 371,
    126, 142, 209, 345, 372, 340, 116, 111, 143, 265, 340, 372, 35, 143, 111, 388,
    390, 466, 161, 246, 163, 249, 466, 390, 7, 163, 246, 352, 346, 280, 123, 50,
    117, 347, 280, 346, 118, 117, 50, 295, 442, 282, 65, 52, 222, 443, 282, 442,
    223, 222, 52, 19, 94, 354, 19, 125, 94, 370, 354, 94, 141, 94, 125, 295, 285,
    442, 65, 222, 55, 441, 442, 285, 221, 55, 222, 419, 197, 248, 196, 3, 197,
    195, 248, 197, 195, 197, 3, 359, 263, 255, 130, 25, 33, 249, 255, 263, 7, 33,
    25, 275, 274, 440, 45, 220, 44, 457, 440, 274, 237, 44, 220, 300, 383, 301,
    70, 71, 156, 368, 301, 383, 139, 156, 71, 417, 351, 465, 193, 245, 122, 412,
    465, 351, 188, 122, 245, 466, 263, 467, 246, 247, 33, 359, 467, 263, 130, 33,
    247, 389, 251, 368, 162, 139, 21, 301, 368, 251, 71, 21, 139, 374, 386, 380,
    145, 153, 159, 385, 380, 386, 158, 159, 153, 379, 394, 378, 150, 149, 169,
    395, 378, 394, 170, 169, 149, 351, 419, 412, 122, 188, 196, 399, 412, 419,
    174, 196, 188, 426, 322, 436, 206, 216, 92, 410, 436, 322, 186, 92, 216, 387,
    373, 388, 160, 161, 144, 390, 388, 373, 163, 144, 161, 393, 326, 164, 167,
    164, 97, 2, 164, 326, 2, 97, 164, 354, 370, 461, 125, 241, 141, 462, 461, 370,
    242, 141, 241, 0, 267, 164, 0, 164, 37, 393, 164, 267, 167, 37, 164, 11, 12,
    302, 11, 72, 12, 268, 302, 12, 38, 12, 72, 386, 374, 387, 159, 160, 145, 373,
    387, 374, 144, 145, 160, 12, 13, 268, 12, 38, 13, 312, 268, 13, 82, 13, 38,
    293, 300, 298, 63, 68, 70, 301, 298, 300, 71, 70, 68, 340, 265, 261, 111, 31,
    35, 446, 261, 265, 226, 35, 31, 380, 385, 381, 153, 154, 158, 384, 381, 385,
    157, 158, 154, 280, 330, 425, 50, 205, 101, 266, 425, 330, 36, 101, 205, 423,
    391, 426, 203, 206, 165, 322, 426, 391, 92, 165, 206, 429, 355, 420, 209, 198,
    126, 437, 420, 355, 217, 126, 198, 391, 327, 393, 165, 167, 98, 326, 393, 327,
    97, 98, 167, 457, 438, 440, 237, 220, 218, 344, 440, 438, 115, 218, 220, 382,
    362, 341, 155, 112, 133, 463, 341, 362, 243, 133, 112, 457, 461, 459, 237,
    239, 241, 458, 459, 461, 238, 241, 239, 434, 430, 364, 214, 135, 210, 394,
    364, 430, 169, 210, 135, 414, 463, 398, 190, 173, 243, 362, 398, 463, 133,
    243, 173, 262, 428, 369, 32, 140, 208, 396, 369, 428, 171, 208, 140, 457, 274,
    461, 237, 241, 44, 354, 461, 274, 125, 44, 241, 316, 403, 317, 86, 87, 179,
    402, 317, 403, 178, 179, 87, 315, 404, 316, 85, 86, 180, 403, 316, 404, 179,
    180, 86, 314, 405, 315, 84, 85, 181, 404, 315, 405, 180, 181, 85, 313, 406,
    314, 83, 84, 182, 405, 314, 406, 181, 182, 84, 418, 406, 421, 194, 201, 182,
    313, 421, 406, 83, 182, 201, 366, 401, 323, 137, 93, 177, 361, 323, 401, 132,
    177, 93, 408, 407, 306, 184, 76, 183, 292, 306, 407, 62, 183, 76, 408, 306,
    409, 184, 185, 76, 291, 409, 306, 61, 76, 185, 410, 409, 287, 186, 57, 185,
    291, 287, 409, 61, 185, 57, 436, 410, 432, 216, 212, 186, 287, 432, 410, 57,
    186, 212, 434, 416, 427, 214, 207, 192, 411, 427, 416, 187, 192, 207, 264,
    368, 372, 34, 143, 139, 383, 372, 368, 156, 139, 143, 457, 459, 438, 237, 218,
    239, 309, 438, 459, 79, 239, 218, 352, 376, 366, 123, 137, 147, 401, 366, 376,
    177, 147, 137, 4, 1, 275, 4, 45, 1, 274, 275, 1, 44, 1, 45, 428, 262, 421,
    208, 201, 32, 418, 421, 262, 194, 32, 201, 327, 358, 294, 98, 64, 129, 331,
    294, 358, 102, 129, 64, 367, 435, 416, 138, 192, 215, 433, 416, 435, 213, 215,
    192, 455, 439, 289, 235, 59, 219, 392, 289, 439, 166, 219, 59, 328, 462, 326,
    99, 97, 242, 370, 326, 462, 141, 242, 97, 326, 370, 2, 97, 2, 141, 94, 2, 370,
    94, 141, 2, 460, 455, 305, 240, 75, 235, 289, 305, 455, 59, 235, 75, 448, 339,
    449, 228, 229, 110, 254, 449, 339, 24, 110, 229, 261, 446, 255, 31, 25, 226,
    359, 255, 446, 130, 226, 25, 449, 254, 450, 229, 230, 24, 253, 450, 254, 23,
    24, 230, 450, 253, 451, 230, 231, 23, 252, 451, 253, 22, 23, 231, 451, 252,
    452, 231, 232, 22, 256, 452, 252, 26, 22, 232, 256, 341, 452, 26, 232, 112,
    453, 452, 341, 233, 112, 232, 413, 464, 414, 189, 190, 244, 463, 414, 464,
    243, 244, 190, 441, 413, 286, 221, 56, 189, 414, 286, 413, 190, 189, 56, 441,
    286, 442, 221, 222, 56, 258, 442, 286, 28, 56, 222, 442, 258, 443, 222, 223,
    28, 257, 443, 258, 27, 28, 223, 444, 443, 259, 224, 29, 223, 257, 259, 443,
    27, 223, 29, 259, 260, 444, 29, 224, 30, 445, 444, 260, 225, 30, 224, 260,
    467, 445, 30, 225, 247, 342, 445, 467, 113, 247, 225, 250, 309, 458, 20, 238,
    79, 459, 458, 309, 239, 79, 238, 290, 305, 392, 60, 166, 75, 289, 392, 305,
    59, 75, 166, 460, 305, 328, 240, 99, 75, 290, 328, 305, 60, 75, 99, 376, 433,
    401, 147, 177, 213, 435, 401, 433, 215, 213, 177, 250, 290, 309, 20, 79, 60,
    392, 309, 290, 166, 60, 79, 411, 416, 376, 187, 147, 192, 433, 376, 416, 213,
    192, 147, 341, 463, 453, 112, 233, 243, 464, 453, 463, 244, 243, 233, 453,
    464, 357, 233, 128, 244, 465, 357, 464, 245, 244, 128, 412, 343, 465, 188,
    245, 114, 357, 465, 343, 128, 114, 245, 437, 343, 399, 217, 174, 114, 412,
    399, 343, 188, 114, 174, 363, 440, 360, 134, 131, 220, 344, 360, 440, 115,
    220, 131, 456, 420, 399, 236, 174, 198, 437, 399, 420, 217, 198, 174, 456,
    363, 420, 236, 198, 134, 360, 420, 363, 131, 134, 198, 361, 401, 288, 132, 58,
    177, 435, 288, 401, 215, 177, 58, 353, 265, 383, 124, 156, 35, 372, 383, 265,
    143, 35, 156, 255, 249, 339, 25, 110, 7, 390, 339, 249, 163, 7, 110, 261, 255,
    448, 31, 228, 25, 339, 448, 255, 110, 25, 228, 14, 317, 13, 14, 13, 87, 312,
    13, 317, 82, 87, 13, 317, 402, 312, 87, 82, 178, 311, 312, 402, 81, 178, 82,
    402, 318, 311, 178, 81, 88, 310, 311, 318, 80, 88, 81, 318, 324, 310, 88, 80,
    95, 415, 310, 324, 191, 95, 80,
  ];
  
  export const FACE_MESH_UV = [
    [0.499976992607117, 0.652534008026123],
    [0.500025987625122, 0.547487020492554],
    [0.499974012374878, 0.602371990680695],
    [0.482113003730774, 0.471979022026062],
    [0.500150978565216, 0.527155995368958],
    [0.499909996986389, 0.498252987861633],
    [0.499523013830185, 0.40106201171875],
    [0.289712011814117, 0.380764007568359],
    [0.499954998493195, 0.312398016452789],
    [0.499987006187439, 0.269918978214264],
    [0.500023007392883, 0.107050001621246],
    [0.500023007392883, 0.666234016418457],
    [0.5000159740448, 0.679224014282227],
    [0.500023007392883, 0.692348003387451],
    [0.499976992607117, 0.695277988910675],
    [0.499976992607117, 0.70593398809433],
    [0.499976992607117, 0.719385027885437],
    [0.499976992607117, 0.737019002437592],
    [0.499967992305756, 0.781370997428894],
    [0.499816000461578, 0.562981009483337],
    [0.473773002624512, 0.573909997940063],
    [0.104906998574734, 0.254140973091125],
    [0.365929991006851, 0.409575998783112],
    [0.338757991790771, 0.41302502155304],
    [0.311120003461838, 0.409460008144379],
    [0.274657994508743, 0.389131009578705],
    [0.393361985683441, 0.403706014156342],
    [0.345234006643295, 0.344011008739471],
    [0.370094001293182, 0.346076011657715],
    [0.319321990013123, 0.347265005111694],
    [0.297903001308441, 0.353591024875641],
    [0.24779200553894, 0.410809993743896],
    [0.396889001131058, 0.842755019664764],
    [0.280097991228104, 0.375599980354309],
    [0.106310002505779, 0.399955987930298],
    [0.2099249958992, 0.391353011131287],
    [0.355807989835739, 0.534406006336212],
    [0.471751004457474, 0.65040397644043],
    [0.474155008792877, 0.680191993713379],
    [0.439785003662109, 0.657229006290436],
    [0.414617002010345, 0.66654098033905],
    [0.450374007225037, 0.680860996246338],
    [0.428770989179611, 0.682690978050232],
    [0.374971002340317, 0.727805018424988],
    [0.486716985702515, 0.547628998756409],
    [0.485300987958908, 0.527395009994507],
    [0.257764995098114, 0.314490020275116],
    [0.401223003864288, 0.455172002315521],
    [0.429818987846375, 0.548614978790283],
    [0.421351999044418, 0.533740997314453],
    [0.276895999908447, 0.532056987285614],
    [0.483370006084442, 0.499586999416351],
    [0.33721199631691, 0.282882988452911],
    [0.296391993761063, 0.293242990970612],
    [0.169294998049736, 0.193813979625702],
    [0.447580009698868, 0.302609980106354],
    [0.392390012741089, 0.353887975215912],
    [0.354490011930466, 0.696784019470215],
    [0.067304998636246, 0.730105042457581],
    [0.442739009857178, 0.572826027870178],
    [0.457098007202148, 0.584792017936707],
    [0.381974011659622, 0.694710969924927],
    [0.392388999462128, 0.694203019142151],
    [0.277076005935669, 0.271932005882263],
    [0.422551989555359, 0.563233017921448],
    [0.385919004678726, 0.281364023685455],
    [0.383103013038635, 0.255840003490448],
    [0.331431001424789, 0.119714021682739],
    [0.229923993349075, 0.232002973556519],
    [0.364500999450684, 0.189113974571228],
    [0.229622006416321, 0.299540996551514],
    [0.173287004232407, 0.278747975826263],
    [0.472878992557526, 0.666198015213013],
    [0.446828007698059, 0.668527007102966],
    [0.422762006521225, 0.673889994621277],
    [0.445307999849319, 0.580065965652466],
    [0.388103008270264, 0.693961024284363],
    [0.403039008378983, 0.706539988517761],
    [0.403629004955292, 0.693953037261963],
    [0.460041999816895, 0.557139039039612],
    [0.431158006191254, 0.692366003990173],
    [0.452181994915009, 0.692366003990173],
    [0.475387006998062, 0.692366003990173],
    [0.465828001499176, 0.779190003871918],
    [0.472328990697861, 0.736225962638855],
    [0.473087012767792, 0.717857003211975],
    [0.473122000694275, 0.704625964164734],
    [0.473033010959625, 0.695277988910675],
    [0.427942007780075, 0.695277988910675],
    [0.426479011774063, 0.703539967536926],
    [0.423162013292313, 0.711845993995667],
    [0.4183090031147, 0.720062971115112],
    [0.390094995498657, 0.639572978019714],
    [0.013953999616206, 0.560034036636353],
    [0.499913990497589, 0.58014702796936],
    [0.413199990987778, 0.69539999961853],
    [0.409626007080078, 0.701822996139526],
    [0.468080013990402, 0.601534962654114],
    [0.422728985548019, 0.585985004901886],
    [0.463079988956451, 0.593783974647522],
    [0.37211999297142, 0.47341400384903],
    [0.334562003612518, 0.496073007583618],
    [0.411671012639999, 0.546965003013611],
    [0.242175996303558, 0.14767599105835],
    [0.290776997804642, 0.201445996761322],
    [0.327338010072708, 0.256527006626129],
    [0.399509996175766, 0.748921036720276],
    [0.441727995872498, 0.261676013469696],
    [0.429764986038208, 0.187834024429321],
    [0.412198007106781, 0.108901023864746],
    [0.288955003023148, 0.398952007293701],
    [0.218936994671822, 0.435410976409912],
    [0.41278201341629, 0.398970007896423],
    [0.257135003805161, 0.355440020561218],
    [0.427684992551804, 0.437960982322693],
    [0.448339998722076, 0.536936044692993],
    [0.178560003638268, 0.45755398273468],
    [0.247308000922203, 0.457193970680237],
    [0.286267012357712, 0.467674970626831],
    [0.332827985286713, 0.460712015628815],
    [0.368755996227264, 0.447206974029541],
    [0.398963987827301, 0.432654976844788],
    [0.476410001516342, 0.405806005001068],
    [0.189241006970406, 0.523923993110657],
    [0.228962004184723, 0.348950982093811],
    [0.490725994110107, 0.562400996685028],
    [0.404670000076294, 0.485132992267609],
    [0.019469000399113, 0.401564002037048],
    [0.426243007183075, 0.420431017875671],
    [0.396993011236191, 0.548797011375427],
    [0.266469985246658, 0.376977026462555],
    [0.439121007919312, 0.51895797252655],
    [0.032313998788595, 0.644356966018677],
    [0.419054001569748, 0.387154996395111],
    [0.462783008813858, 0.505746960639954],
    [0.238978996872902, 0.779744982719421],
    [0.198220998048782, 0.831938028335571],
    [0.107550002634525, 0.540755033493042],
    [0.183610007166862, 0.740257024765015],
    [0.134409993886948, 0.333683013916016],
    [0.385764002799988, 0.883153975009918],
    [0.490967005491257, 0.579378008842468],
    [0.382384985685349, 0.508572995662689],
    [0.174399003386497, 0.397670984268188],
    [0.318785011768341, 0.39623498916626],
    [0.343364000320435, 0.400596976280212],
    [0.396100014448166, 0.710216999053955],
    [0.187885001301765, 0.588537991046906],
    [0.430987000465393, 0.944064974784851],
    [0.318993002176285, 0.898285031318665],
    [0.266247987747192, 0.869701027870178],
    [0.500023007392883, 0.190576016902924],
    [0.499976992607117, 0.954452991485596],
    [0.366169989109039, 0.398822009563446],
    [0.393207013607025, 0.39553701877594],
    [0.410373002290726, 0.391080021858215],
    [0.194993004202843, 0.342101991176605],
    [0.388664990663528, 0.362284004688263],
    [0.365961998701096, 0.355970978736877],
    [0.343364000320435, 0.355356991291046],
    [0.318785011768341, 0.35834002494812],
    [0.301414996385574, 0.363156020641327],
    [0.058132998645306, 0.319076001644135],
    [0.301414996385574, 0.387449026107788],
    [0.499987989664078, 0.618434011936188],
    [0.415838003158569, 0.624195992946625],
    [0.445681989192963, 0.566076993942261],
    [0.465844005346298, 0.620640993118286],
    [0.49992299079895, 0.351523995399475],
    [0.288718998432159, 0.819945991039276],
    [0.335278987884521, 0.852819979190826],
    [0.440512001514435, 0.902418971061707],
    [0.128294005990028, 0.791940987110138],
    [0.408771991729736, 0.373893976211548],
    [0.455606997013092, 0.451801002025604],
    [0.499877005815506, 0.908990025520325],
    [0.375436991453171, 0.924192011356354],
    [0.11421000212431, 0.615022003650665],
    [0.448662012815475, 0.695277988910675],
    [0.4480200111866, 0.704632043838501],
    [0.447111994028091, 0.715808033943176],
    [0.444831997156143, 0.730794012546539],
    [0.430011987686157, 0.766808986663818],
    [0.406787008047104, 0.685672998428345],
    [0.400738000869751, 0.681069016456604],
    [0.392399996519089, 0.677703022956848],
    [0.367855995893478, 0.663918972015381],
    [0.247923001646996, 0.601333022117615],
    [0.452769994735718, 0.420849978923798],
    [0.43639200925827, 0.359887003898621],
    [0.416164010763168, 0.368713974952698],
    [0.413385987281799, 0.692366003990173],
    [0.228018000721931, 0.683571994304657],
    [0.468268007040024, 0.352671027183533],
    [0.411361992359161, 0.804327011108398],
    [0.499989002943039, 0.469825029373169],
    [0.479153990745544, 0.442654013633728],
    [0.499974012374878, 0.439637005329132],
    [0.432112008333206, 0.493588984012604],
    [0.499886006116867, 0.866917014122009],
    [0.49991300702095, 0.821729004383087],
    [0.456548988819122, 0.819200992584229],
    [0.344549000263214, 0.745438992977142],
    [0.37890899181366, 0.574010014533997],
    [0.374292999505997, 0.780184984207153],
    [0.319687992334366, 0.570737957954407],
    [0.357154995203018, 0.604269981384277],
    [0.295284003019333, 0.621580958366394],
    [0.447750002145767, 0.862477004528046],
    [0.410986006259918, 0.508723020553589],
    [0.31395098567009, 0.775308012962341],
    [0.354128003120422, 0.812552988529205],
    [0.324548006057739, 0.703992962837219],
    [0.189096003770828, 0.646299958229065],
    [0.279776990413666, 0.71465802192688],
    [0.1338230073452, 0.682700991630554],
    [0.336768001317978, 0.644733011722565],
    [0.429883986711502, 0.466521978378296],
    [0.455527991056442, 0.548622965812683],
    [0.437114000320435, 0.558896005153656],
    [0.467287987470627, 0.529924988746643],
    [0.414712011814117, 0.335219979286194],
    [0.37704598903656, 0.322777986526489],
    [0.344107985496521, 0.320150971412659],
    [0.312875986099243, 0.32233202457428],
    [0.283526003360748, 0.333190023899078],
    [0.241245999932289, 0.382785975933075],
    [0.102986000478268, 0.468762993812561],
    [0.267612010240555, 0.424560010433197],
    [0.297879010438919, 0.433175981044769],
    [0.333433985710144, 0.433878004550934],
    [0.366427004337311, 0.426115989685059],
    [0.396012008190155, 0.416696012020111],
    [0.420121014118195, 0.41022801399231],
    [0.007561000064015, 0.480777025222778],
    [0.432949006557465, 0.569517970085144],
    [0.458638995885849, 0.479089021682739],
    [0.473466008901596, 0.545744001865387],
    [0.476087987422943, 0.563830018043518],
    [0.468472003936768, 0.555056989192963],
    [0.433990985155106, 0.582361996173859],
    [0.483518004417419, 0.562983989715576],
    [0.482482999563217, 0.57784903049469],
    [0.42645001411438, 0.389798998832703],
    [0.438998997211456, 0.39649498462677],
    [0.450067013502121, 0.400434017181396],
    [0.289712011814117, 0.368252992630005],
    [0.276670008897781, 0.363372981548309],
    [0.517862021923065, 0.471948027610779],
    [0.710287988185883, 0.380764007568359],
    [0.526226997375488, 0.573909997940063],
    [0.895093023777008, 0.254140973091125],
    [0.634069979190826, 0.409575998783112],
    [0.661242008209229, 0.41302502155304],
    [0.688880026340485, 0.409460008144379],
    [0.725341975688934, 0.389131009578705],
    [0.606630027294159, 0.40370500087738],
    [0.654766023159027, 0.344011008739471],
    [0.629905998706818, 0.346076011657715],
    [0.680678009986877, 0.347265005111694],
    [0.702096998691559, 0.353591024875641],
    [0.75221198797226, 0.410804986953735],
    [0.602918028831482, 0.842862963676453],
    [0.719901978969574, 0.375599980354309],
    [0.893692970275879, 0.399959981441498],
    [0.790081977844238, 0.391354024410248],
    [0.643998026847839, 0.534487962722778],
    [0.528249025344849, 0.65040397644043],
    [0.525849997997284, 0.680191040039062],
    [0.560214996337891, 0.657229006290436],
    [0.585384011268616, 0.66654098033905],
    [0.549625992774963, 0.680860996246338],
    [0.57122802734375, 0.682691991329193],
    [0.624852001667023, 0.72809898853302],
    [0.513050019741058, 0.547281980514526],
    [0.51509702205658, 0.527251958847046],
    [0.742246985435486, 0.314507007598877],
    [0.598631024360657, 0.454979002475739],
    [0.570338010787964, 0.548575043678284],
    [0.578631997108459, 0.533622980117798],
    [0.723087012767792, 0.532054007053375],
    [0.516445994377136, 0.499638974666595],
    [0.662801027297974, 0.282917976379395],
    [0.70362401008606, 0.293271005153656],
    [0.830704987049103, 0.193813979625702],
    [0.552385985851288, 0.302568018436432],
    [0.607609987258911, 0.353887975215912],
    [0.645429015159607, 0.696707010269165],
    [0.932694971561432, 0.730105042457581],
    [0.557260990142822, 0.572826027870178],
    [0.542901992797852, 0.584792017936707],
    [0.6180260181427, 0.694710969924927],
    [0.607590973377228, 0.694203019142151],
    [0.722943007946014, 0.271963000297546],
    [0.577413976192474, 0.563166975975037],
    [0.614082992076874, 0.281386971473694],
    [0.616907000541687, 0.255886018276215],
    [0.668509006500244, 0.119913995265961],
    [0.770092010498047, 0.232020974159241],
    [0.635536015033722, 0.189248979091644],
    [0.77039098739624, 0.299556016921997],
    [0.826722025871277, 0.278755009174347],
    [0.527121007442474, 0.666198015213013],
    [0.553171992301941, 0.668527007102966],
    [0.577238023281097, 0.673889994621277],
    [0.554691970348358, 0.580065965652466],
    [0.611896991729736, 0.693961024284363],
    [0.59696102142334, 0.706539988517761],
    [0.596370995044708, 0.693953037261963],
    [0.539958000183105, 0.557139039039612],
    [0.568841993808746, 0.692366003990173],
    [0.547818005084991, 0.692366003990173],
    [0.52461302280426, 0.692366003990173],
    [0.534089982509613, 0.779141008853912],
    [0.527670979499817, 0.736225962638855],
    [0.526912987232208, 0.717857003211975],
    [0.526877999305725, 0.704625964164734],
    [0.526966989040375, 0.695277988910675],
    [0.572058022022247, 0.695277988910675],
    [0.573521018028259, 0.703539967536926],
    [0.57683801651001, 0.711845993995667],
    [0.581691026687622, 0.720062971115112],
    [0.609944999217987, 0.639909982681274],
    [0.986046016216278, 0.560034036636353],
    [0.5867999792099, 0.69539999961853],
    [0.590372025966644, 0.701822996139526],
    [0.531915009021759, 0.601536989212036],
    [0.577268004417419, 0.585934996604919],
    [0.536915004253387, 0.593786001205444],
    [0.627542972564697, 0.473352015018463],
    [0.665585994720459, 0.495950996875763],
    [0.588353991508484, 0.546862006187439],
    [0.757824003696442, 0.14767599105835],
    [0.709249973297119, 0.201507985591888],
    [0.672684013843536, 0.256581008434296],
    [0.600408971309662, 0.74900496006012],
    [0.55826598405838, 0.261672019958496],
    [0.570303976535797, 0.187870979309082],
    [0.588165998458862, 0.109044015407562],
    [0.711045026779175, 0.398952007293701],
    [0.781069993972778, 0.435405015945435],
    [0.587247014045715, 0.398931980133057],
    [0.742869973182678, 0.355445981025696],
    [0.572156012058258, 0.437651991844177],
    [0.55186802148819, 0.536570012569427],
    [0.821442008018494, 0.457556009292603],
    [0.752701997756958, 0.457181990146637],
    [0.71375697851181, 0.467626988887787],
    [0.66711300611496, 0.460672974586487],
    [0.631101012229919, 0.447153985500336],
    [0.6008620262146, 0.432473003864288],
    [0.523481011390686, 0.405627012252808],
    [0.810747981071472, 0.523926019668579],
    [0.771045982837677, 0.348959028720856],
    [0.509127020835876, 0.562718033790588],
    [0.595292985439301, 0.485023975372314],
    [0.980530977249146, 0.401564002037048],
    [0.573499977588654, 0.420000016689301],
    [0.602994978427887, 0.548687994480133],
    [0.733529984951019, 0.376977026462555],
    [0.560611009597778, 0.519016981124878],
    [0.967685997486115, 0.644356966018677],
    [0.580985009670258, 0.387160003185272],
    [0.537728011608124, 0.505385041236877],
    [0.760966002941132, 0.779752969741821],
    [0.801778972148895, 0.831938028335571],
    [0.892440974712372, 0.54076099395752],
    [0.816350996494293, 0.740260004997253],
    [0.865594983100891, 0.333687007427216],
    [0.614073991775513, 0.883246004581451],
    [0.508952975273132, 0.579437971115112],
    [0.617941975593567, 0.508316040039062],
    [0.825608015060425, 0.397674977779388],
    [0.681214988231659, 0.39623498916626],
    [0.656635999679565, 0.400596976280212],
    [0.603900015354156, 0.710216999053955],
    [0.81208598613739, 0.588539004325867],
    [0.56801301240921, 0.944564998149872],
    [0.681007981300354, 0.898285031318665],
    [0.733752012252808, 0.869701027870178],
    [0.633830010890961, 0.398822009563446],
    [0.606792986392975, 0.39553701877594],
    [0.589659988880157, 0.391062021255493],
    [0.805015981197357, 0.342108011245728],
    [0.611334979534149, 0.362284004688263],
    [0.634037971496582, 0.355970978736877],
    [0.656635999679565, 0.355356991291046],
    [0.681214988231659, 0.35834002494812],
    [0.698584973812103, 0.363156020641327],
    [0.941866993904114, 0.319076001644135],
    [0.698584973812103, 0.387449026107788],
    [0.584177017211914, 0.624107003211975],
    [0.554318010807037, 0.566076993942261],
    [0.534153997898102, 0.62064003944397],
    [0.711217999458313, 0.819975018501282],
    [0.664629995822906, 0.852871000766754],
    [0.559099972248077, 0.902631998062134],
    [0.871706008911133, 0.791940987110138],
    [0.591234028339386, 0.373893976211548],
    [0.544341027736664, 0.451583981513977],
    [0.624562978744507, 0.924192011356354],
    [0.88577002286911, 0.615028977394104],
    [0.551338016986847, 0.695277988910675],
    [0.551980018615723, 0.704632043838501],
    [0.552887976169586, 0.715808033943176],
    [0.555167973041534, 0.730794012546539],
    [0.569944024085999, 0.767035007476807],
    [0.593203008174896, 0.685675978660583],
    [0.599261999130249, 0.681069016456604],
    [0.607599973678589, 0.677703022956848],
    [0.631937980651855, 0.663500010967255],
    [0.752032995223999, 0.601315021514893],
    [0.547226011753082, 0.420395016670227],
    [0.563543975353241, 0.359827995300293],
    [0.583841025829315, 0.368713974952698],
    [0.586614012718201, 0.692366003990173],
    [0.771915018558502, 0.683578014373779],
    [0.531597018241882, 0.352482974529266],
    [0.588370978832245, 0.804440975189209],
    [0.52079701423645, 0.442565023899078],
    [0.567984998226166, 0.493479013442993],
    [0.543282985687256, 0.819254994392395],
    [0.655317008495331, 0.745514988899231],
    [0.621008992195129, 0.574018001556396],
    [0.625559985637665, 0.78031200170517],
    [0.680198013782501, 0.570719003677368],
    [0.64276397228241, 0.604337990283966],
    [0.704662978649139, 0.621529996395111],
    [0.552012026309967, 0.862591981887817],
    [0.589071989059448, 0.508637011051178],
    [0.685944974422455, 0.775357007980347],
    [0.645735025405884, 0.812640011310577],
    [0.675342977046967, 0.703978002071381],
    [0.810858011245728, 0.646304965019226],
    [0.72012197971344, 0.714666962623596],
    [0.866151988506317, 0.682704985141754],
    [0.663187026977539, 0.644596993923187],
    [0.570082008838654, 0.466325998306274],
    [0.544561982154846, 0.548375964164734],
    [0.562758982181549, 0.558784961700439],
    [0.531987011432648, 0.530140042304993],
    [0.585271000862122, 0.335177004337311],
    [0.622952997684479, 0.32277899980545],
    [0.655896008014679, 0.320163011550903],
    [0.687132000923157, 0.322345972061157],
    [0.716481983661652, 0.333200991153717],
    [0.758756995201111, 0.382786989212036],
    [0.897013008594513, 0.468769013881683],
    [0.732392013072968, 0.424547016620636],
    [0.70211398601532, 0.433162987232208],
    [0.66652500629425, 0.433866024017334],
    [0.633504986763, 0.426087975502014],
    [0.603875994682312, 0.416586995124817],
    [0.579657971858978, 0.409945011138916],
    [0.992439985275269, 0.480777025222778],
    [0.567192018032074, 0.569419980049133],
    [0.54136598110199, 0.478899002075195],
    [0.526564002037048, 0.546118021011353],
    [0.523913025856018, 0.563830018043518],
    [0.531529009342194, 0.555056989192963],
    [0.566035985946655, 0.582329034805298],
    [0.51631098985672, 0.563053965568542],
    [0.5174720287323, 0.577877044677734],
    [0.573594987392426, 0.389806985855103],
    [0.560697972774506, 0.395331978797913],
    [0.549755990505219, 0.399751007556915],
    [0.710287988185883, 0.368252992630005],
    [0.723330020904541, 0.363372981548309],
  ];

export const SUPPORTED_LANGUAGES = ["es", "en", "pt"]