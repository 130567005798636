const hmacSHA256 = require('crypto-js/hmac-sha256')

export const signModelToken = (token: string) => {
  const encoded = hmacSHA256(
    token,
    process.env.REACT_APP_JWT_SECRET
  ).toString();
  return encoded;
};

export const transformKeys = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeys(item)) as any;
  } else if (typeof obj === "object" && obj !== null) {
    const transformedObj: any = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const transformedKey = key.replace(/_(\w)/g, (_, p1) =>
          p1.toUpperCase()
        );
        transformedObj[transformedKey] = transformKeys(obj[key]);
      }
    }

    return transformedObj as T;
  }
  return obj;
};

export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}