export const nearestColor = (options: Array<string>, hex: ColorRGB) => {
    var lowest = Number.POSITIVE_INFINITY;
    var tmp = Number.POSITIVE_INFINITY;
    var index = 0
    options.forEach((option, i) => {
        tmp = distanceBetweenColorsRGB(hex, hexToRGB(option))

        if (tmp < lowest) {
            lowest = tmp
            index = i
        }
    })

    return {
        color: options[index],
        index: index
    }
}

const distanceBetweenColorsRGB = (a: ColorRGB, b: ColorRGB) => {
    return Math.sqrt(Math.pow(a.r - b.r, 2) + Math.pow(a.g - b.g, 2) + Math.pow(a.b - b.b, 2))
}

const hexToRGB = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return {
        r: result ? parseInt(result[1], 16) : -1,
        g: result ? parseInt(result[2], 16) : -1,
        b: result ? parseInt(result[3], 16) : -1
    };
}

export const stringRGBToColorRGB = (color: string) => {
    const colorValues = color.replaceAll("rgb(", "").replaceAll(")", "").replaceAll(" ", "").split(',');
    return {
        r: parseInt(colorValues[0]),
        g: parseInt(colorValues[1]),
        b: parseInt(colorValues[2])
    }
}
