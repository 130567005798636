import { useEffect, useState } from "react";
import { ModelViewerUtils } from '../../utils'
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import './style.css'

type Props = {
  /**
   * Array of annotations that will displayed over the 3D model.
   * @type {Array<Annotation>}
   */
  annotations: Array<Annotation>,
  color: Color
}
const Annotations = ({annotations, color}: Props) => {

  const { t, ready } = useTranslation()

  const [show, setShow] = useState(true);

  useEffect(() => {
    loadAnnotations();    
  }, []);

  useEffect(() => {
    toogleAnnotations();
  }, [show]);

  const toogleAnnotations = () => {
    const viewer = document.getElementById("model-viewer");
    const hotspots: HTMLElement[] = Array.from(viewer!.getElementsByClassName("hotspot") as HTMLCollectionOf<HTMLElement>);
    hotspots.forEach((hotspot: HTMLElement) => {
      hotspot.style.display = show ? "block" : "none"
      hotspot.style.color = color
      hotspot.style.borderColor = color
    });
  };

  const loadAnnotations = () => {
    const viewer = document.getElementById("model-viewer");

    const createdPoints = [];

    annotations.map((annotation) => {
      createdPoints.push(annotation.annotationId);
      if (!document.getElementById(annotation.annotationId.toString())) {
        const hotspot = ModelViewerUtils.createHotspot(annotation);
        viewer!.appendChild(hotspot);
        ModelViewerUtils.createAnnotation(
          annotation.annotationId,
          hotspot,
          annotation.message
        );
      }
    });
  };

  return (
    <>
      {ready &&
        <div
          className="div-switch hotspot-button"
          onClick={() => {
            setShow(!show);
          }}
        >
          <i
            className="fas fa-message"
            style={{ color: show ? color : "#d4d4d4" }}
          ></i>
          <p className="text-switch">{t('common.annotations')}</p>
        </div>
      }
    </>
  );
};

Annotations.propTypes = {
  annotations: PropTypes.array,
};

Annotations.defaultProps = {
  annotations: [],
};

export default Annotations;
