import { useEffect, useState } from "react";
import { ProductRequest } from "../requests";
import { useTranslation } from "react-i18next";
import useURL from "./useURL";

const useProduct = (modelId: ID) => {
  const { i18n } = useTranslation();

  const { getURLLanguage, hasParameter } = useURL();

  const [product, setProduct] = useState<Product>();
  const [error, setError] = useState<string>();
  const [visitAdded, setVisitAdded] = useState(false);

  useEffect(() => {
    getModel();
  }, []);

  const getModel: () => Promise<void> = async () => {
    await getURLLanguage()

    try {
      const res = await ProductRequest.getProduct(modelId, i18n.language);
      if (res.data) {
        setProduct(res.data!);
        window.parent.postMessage({ "ok-response": true }, "*");
      } else {
        setError(res.error!);
        window.parent.postMessage({ "ok-response": false }, "*");
      }
    } catch (e: any) {
      console.error("[AppAR] Error getting data:", e);
      setError(e);
      window.parent.postMessage({ "ok-response": false }, "*");
    }
  };

  const addVisit = async () => {
    if (!hasParameter("novisit")) {
      try {
        const res = await ProductRequest.addVisit(product!.productId);
        if (res.status === 200) {
          setVisitAdded(true);
          window.parent.postMessage(
            { type: "stat", name: "appar-stat-visit" },
            "*"
          );
        }
      } catch (e: any) {
        console.error("[AppAR] Error adding visit:", e);
      }
    }
  };

  const addAugmentation = async () => {
    try {
      const res = await ProductRequest.addAugmentation(product!.productId);
      if (res.status === 200) {
        window.parent.postMessage(
          { type: "stat", name: "appar-stat-augmentation" },
          "*"
        );
      }
    } catch (e: any) {
      console.error("[AppAR] Error adding visit:", e);
    }
  };

  return {
    product,
    getModel,
    error,
    addAugmentation,
    addVisit,
    visitAdded,
  };
};

export default useProduct;
