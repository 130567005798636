import { useEffect } from "react";

const PosterGenerator = () => {
  let modelReceived = false;

  useEffect(() => {
    window.viewerReady.postMessage("");

    document.addEventListener("changemodel", (e) => {
      modelReceived = true;
      document.getElementById("model-viewer").src = e.detail;
    });

    const viewer = document.getElementById("model-viewer");
    viewer.addEventListener("load", async (e) => {
      if (modelReceived) {
        e.preventDefault();

        setTimeout(() => {
          const image = viewer.toDataURL();
          console.log("Trying to send message");
          console.log("Char sent: " + image.length);
          console.log("last char: " + image[image.length - 1]);
          window.posterGenerated.postMessage(image);
        }, 1000);
      }
    });
  }, []);

  return (
    <>
      <model-viewer id={"model-viewer"} class="model-viewer" />
    </>
  );
};

export default PosterGenerator;
