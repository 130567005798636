import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  color: Color,
  onClick: (show: boolean) => void
}
const ModelDimensions = ({ color, onClick }: Props) => {

  const { t, ready } = useTranslation()

  const [points, setPoints] = useState<any>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const modelViewer = document.getElementById("model-viewer")
    if (modelViewer) {
      if (show) {
        loadDimensions(modelViewer);
      } else if (points.length > 0) {
        hidePoints();
      }
    }
  }, [show]);

  const loadDimensions = (modelViewer: JSX.IntrinsicElements["model-viewer"]) => {
    const center = modelViewer.getCameraTarget();
    const size = modelViewer.getDimensions();

    const x = size.x / 2;
    const y = size.y / 2;
    const z = size.z / 2;
    const offset = 0.1;

    modelViewer.updateHotspot({
      name: "hotspot-dim+X-Y",
      position: `${center.x + x} ${center.y - y + offset} ${center.z}`,
    });

    modelViewer.querySelector(
      'button[slot="hotspot-dim+X-Y"]'
    ).textContent = `${(size.z * 100).toFixed(0)} cm`;

    modelViewer.updateHotspot({
      name: "hotspot-dim+Y-Z",
      position: `${center.x} ${center.y + y + offset} ${center.z - z}`,
    });
    modelViewer.querySelector(
      'button[slot="hotspot-dim+Y-Z"]'
    ).textContent = `${(size.x * 100).toFixed(0)} cm`;

    modelViewer.updateHotspot({
      name: "hotspot-dim-X-Z",
      position: `${center.x + x} ${center.y} ${center.z - z - offset}`,
    });
    modelViewer.querySelector(
      'button[slot="hotspot-dim-X-Z"]'
    ).textContent = `${(size.y * 100).toFixed(0)} cm`;

    if (points.length > 0) {
      hidePoints(false);
    } else {
      createPoints(
        [center.x + x, center.y - y, center.z - z],
        [center.x + x, center.y - y, center.z + z],
        modelViewer
      );
      createPoints(
        [center.x + x, center.y - y, center.z - z],
        [center.x + x, center.y + y, center.z - z],
        modelViewer
      );
      createPoints(
        [center.x - x, center.y + y, center.z - z],
        [center.x + x, center.y + y, center.z - z],
        modelViewer
      );
    }
  };

  const createPoints = (startPos: number[], endPos: number[], modelViewer: HTMLElement) => {
    const distance = [
      endPos[0] - startPos[0],
      endPos[1] - startPos[1],
      endPos[2] - startPos[2],
    ];

    const pointSize = 0.056;
    const pointSpacing = 19;

    const maxIndex = Math.ceil((distance[0] + distance[1] + distance[2]) / pointSize);

    for (let index = 1; index < maxIndex; index++) {
      const id = (Math.random() + 1).toString(36).substring(5);
      const slot = `hotspot-${index}-${id}`;

      const point = document.createElement("div");
      modelViewer.appendChild(point);

      point.classList.add("dot");
      point.style.backgroundColor = color
      point.id = id;
      point.dataset.position = `${distance[0] > 0 ? startPos[0] + index / pointSpacing : startPos[0]} 
      ${distance[1] > 0 ? startPos[1] + index / pointSpacing : startPos[1]} 
      ${distance[2] > 0 ? startPos[2] + index / pointSpacing : startPos[2]
        }`;
      point.dataset.normal = "1 0 -1";
      point.slot = slot;

      setPoints((points: any) => [...points, id]);
    }
  };

  const hidePoints = (hide = true) => {
    points.forEach((pointID: string) => {
      document.getElementById(pointID)!.hidden = hide;
    });
  };

  return (
    <>
      {ready &&
        <div
          className="div-switch measurement-button"
          onClick={() => {
            setShow(!show);
            onClick(!show)
          }}
        >
          <i
            className="fas fa-ruler-combined"
            style={{ color: show ? color : "#d4d4d4" , marginLeft:'1px', marginBottom:'5px'}}
          ></i>
          <p className="text-switch">{t('common.measures')}</p>
        </div>
      }
    </>
  );
};

export default ModelDimensions;
