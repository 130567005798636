import { AwesomeQRCode, AwesomeQRCodeProps } from "@awesomeqr/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

type Props = {
  message: string,
  useQr: boolean
}

const Error = ({ message, useQr }: Props) => {
  const { t, ready } = useTranslation();


  const [qr, setQr] = useState<AwesomeQRCodeProps["options"]>({
    text: "https://webviewer.appar.io/",
    margin: 1,
    correctLevel: 1,
    maskPattern: 0,
    colorDark: "#000000", //Personalizable
    colorLight: "#ffffff",
    autoColor: true,
    whiteMargin: true,
    logoImage: undefined, //Personalizable
    logoScale: 0.2,
    logoMargin: 0,
    logoCornerRadius: 0,
    components: {
      data: {
        scale: 0.75,
      },
      timing: {
        scale: 0.75,
        protectors: false,
      },
      alignment: {
        scale: 0.75,
        protectors: false,
      },
      cornerAlignment: {
        scale: 0.75,
        protectors: true,
      },
    },
  });


  useEffect(() => {
    setQr({ ...qr, text: window.location.href });
  }, []);

  return (
    <>
      {ready && (
        <div style={{ height: "95%", width: "100%", marginTop: "5%" }}>
          {useQr ? (
            <div
              style={{
                minHeight: "130px",
                minWidth: "130px",
                display: "grid",
              }}
              id="qrcode"
              className="qr"
            >
              <AwesomeQRCode options={qr} />
            </div>
          ) : (
            <img
              style={{
                width: "20%",
                marginBottom: 5,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src="/images/ar-error.png"
            />
          )}
          <h3 style={{ textAlign: "center" }}>{t("common.error")} :( </h3>
          <h6 style={{ textAlign: "center" }}>{message}</h6>
          {useQr && <h6 style={{ textAlign: "center" }}>{t("common.try_scan_qr")}</h6>}
        </div>
      )}
    </>
  );
};

Error.propTypes = {
  message: PropTypes.string,
  useQr: PropTypes.bool,
};

Error.defaultProps = {
  message: "",
  useQr: false,
};

export default Error;
