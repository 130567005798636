import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import { isMobile } from "react-device-detect";
import {
  HelpModal,
  QRModal,
  Error,
  ARButton,
  BottomModal,
  ModelViewer,
  CallToActionButton,
  DropodownMenu,
  LanguageSelector,
  Annotations,
  ModelDimensions,
} from "../../components";
import { HelpMessages } from "../../utils";
import { useTranslation } from "react-i18next";
import { nearestColor, stringRGBToColorRGB } from "../../utils/colorHelper";
import { CollectionRequest } from "../../requests";
import { DEFAULT_COLOR } from "../../constants";
import { useURL } from "../../hooks";

type Modal = {
  qr: boolean,
  instructions: boolean
}

const CollectiontViewer = () => {
  const { collection_id } = useParams();
  const { t, ready, i18n } = useTranslation();
  const url = useURL()

  const [product, setProduct] = useState<Product>();
  const [collection, setCollection] = useState<Collection>();
  const [modalOpen, setModalOpen] = useState<Modal>({
    qr: false,
    instructions: false
  });
  const [error, setError] = useState<string>();
  const [optionOpen, setOptionOpen] = useState(true);

  const [isSmallWindow, setIsSmallWindow] = useState(false);
  const [useBottomModal, setUseBottomModal] = useState(true);

  const [buttonsAreVisible, setButtonsAreVisible] = useState(false)

  const [showMeasurements, setShowMeasurements] = useState<boolean>(false)

  const [hideOptions, setHideOptions] = useState<boolean>(false)

  const maxWidth = 300;
  const maxHeight = 250;

  useEffect(() => {
    if (url.hasParameter("noselector")) {
      setUseBottomModal(false)
    }
    if (url.hasParameter("hideoptions")) {
      setHideOptions(true)
    }

    window.addEventListener("message", (e) => {
      if (typeof e.data != "object") {
        if (e.data == "disOp") {
          setUseBottomModal(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (collection) {
      window.addEventListener("message", (e) => {
        if (e.data && typeof e.data === "string") {
          if (e.data.includes("rgb")) {
            const colors: Color[] = [];
            collection?.products?.forEach((product) => {
              if (product.variants.length > 0 && product.variants[0].color) {
                colors.push(product.variants[0].color);
              }
            });

            try {
              const nearColor = nearestColor(colors, stringRGBToColorRGB(e.data));

              if (collection?.products && nearColor.index < collection.products.length) {
                setProduct(collection.products[nearColor.index]);
              }
            } catch (error) {
              console.error(error);
            }
          }
        }

      });
    }
  }, [collection])

  useEffect(() => {
    if (ready) {
      checkWindowSize();
      if (collection_id) {
        getData();
      } else {
        setError(t("error.you_must_indicate_a_product") as string);
        window.parent.postMessage({ "ok-response": false }, "*");
      }
    }
  }, [ready]);

  window.onresize = () => {
    checkWindowSize();
  };

  const checkWindowSize = () => {
    setIsSmallWindow(
      window.innerWidth <= maxWidth || window.innerHeight <= maxHeight
    );
  };

  const getData = async () => {
    url.getURLLanguage()
    const res = await CollectionRequest.getCollection(collection_id!, i18n.language)
    if (res.data) {
      setCollection(res.data)
      setProduct(res.data.products[0]);
      window.parent.postMessage({ "ok-response": true }, "*");
    }
    if (res.error) {
      window.parent.postMessage({ "ok-response": true }, "*");
      switch (res.error) {
        case "model_not_found":
          setError(t("error.product_not_found") as string);
          break;
        case "product_not_available":
          setError(t("error.augmentation_not_available") as string);
          break;
        default:
          setError(t(`error.${res.error}`) as string);
          break;
      }
    }
  }

  const addAugmentation = async () => {
    await axios.put(
      process.env.REACT_APP_API_URL +
      "collection/" +
      collection_id +
      "/augmentation"
    );
  };


  return (
    <div>
      {product ? (
        <ModelViewer
          glb={product.androidModelUrl}
          usdz={product.iosModelUrl}
          placement={product.arPlacement}
          allowResize={product.allowResize == 1}
          onClick={() => {
            if (isSmallWindow && !modalOpen.qr) {
              if (isMobile) {
                (document.getElementById("model-viewer") as any)!.activateAR();
              } else {
                setModalOpen({ ...modalOpen, qr: true });
              }
            }
          }}
          onLoad={(e) => {
            window.parent.postMessage(true, "*");
            setButtonsAreVisible(true);
          }}
        >
          {useBottomModal && (
            <div>
              <BottomModal
                content={collection!.products}
                closeOnUnfocus={false}
                onSlideSelect={(prod) => setProduct(prod)}
                hidden={isSmallWindow}
                onOpen={(open) => setOptionOpen(open)}
                color={collection!.augmentationButton?.color || DEFAULT_COLOR}
              />
              <div className="extra-nav"></div>
            </div>
          )}

          {collection &&
            <ARButton
              placement={product.arPlacement}
              isVisible={true}
              isSmallWindow={isSmallWindow}
              text={collection.augmentationButton?.text}
              color={collection.augmentationButton?.color || DEFAULT_COLOR}
              onClick={() => {
                isMobile
                  ? addAugmentation()
                  : setModalOpen({ ...modalOpen, qr: true });
              }}
            />
          }

          {window.top === window.self && product.posterUrl && (
            <CallToActionButton
              actionUrl={collection!.url || product.productUrl}
              buttonColor={collection!.augmentationButton?.color || DEFAULT_COLOR}
              message={collection?.callToAction}
              displaced={optionOpen}
            />
          )}

          <div className="appar-btn-container">
            {!hideOptions &&
              <DropodownMenu
                color={collection!.augmentationButton?.color || DEFAULT_COLOR}
              >
                <LanguageSelector
                  color={collection!.augmentationButton?.color || DEFAULT_COLOR}
                />
                <HelpModal
                  color={collection!.augmentationButton?.color || DEFAULT_COLOR}
                  title={HelpMessages.viewer.title}
                  options={HelpMessages.viewer.body}
                  hidden={isSmallWindow}
                />
              </DropodownMenu>
            }

            {product.annotations && product.annotations.length > 0 &&
              <>
                <Annotations
                  annotations={product.annotations}
                  color={collection!.augmentationButton?.color || DEFAULT_COLOR}
                />
              </>
            }

            {product!.showDimensions && (
              <ModelDimensions
                color={collection!.augmentationButton?.color || DEFAULT_COLOR}
                onClick={(show) => {
                  setShowMeasurements(show);
                }}
              />
            )}
          </div>

          <div id="lazy-load-poster" slot="poster"></div>
          <p className="sty-p display-p">
            Powered by&nbsp;
            <a href="https://appar.io/" target="_blank">
              AppAR SpA
            </a>
          </p>

          {modalOpen.qr && (
            <QRModal
              onClose={() => setModalOpen({ ...modalOpen, qr: false })}
              storeId={collection?.storeId!}
            />
          )}
        </ModelViewer>
      ) : (
        error && <Error message={error} />
      )}
    </div>
  );
};

export default CollectiontViewer;
