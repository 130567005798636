import axios from "axios";
import { HmacSHA256 } from "crypto-js";

/**
 * Creates a SKU (Stock Keeping Unit) for a product.
 * @param {string} provider - The provider identifier for the SKU.
 * @param {any} productInfo - The product information object.
 * @returns {Promise<ResponseBody>} - A Promise that resolves to a ResponseBody object containing the response data and error (if any).
 */
export const createSKU = async (
  provider: string,
  productInfo: any
): Promise<ResponseBody> => {
  const encoded = HmacSHA256(
    JSON.stringify(productInfo),
    process.env.REACT_APP_TEAMWEAR_SECRET!
  );
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TW_API_URL}sku/${provider}`,
      productInfo,
      {
        headers: {
          hmac: encoded.toString(),
        },
      }
    );
    return { data: response.data, error: undefined };
  } catch (e: any) {
    return { data: null, error: e.response.data };
  }
};

/**
 * Retrieves SKUs (Stock Keeping Units) from a provider using an HTTP GET request.
 * @param provider - The name of the provider to retrieve SKUs from.
 * @returns A promise that resolves to a `ResponseBody` object containing the retrieved data or an error message.
 * @example
 * const response = await getSKUs("providerA");
 * console.log(response.data); // Retrieved SKUs
 * console.log(response.error); // Error message, if any
 */
export const getSKUs = async (provider: string): Promise<ResponseBody> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TW_API_URL}sku/${provider}`
    );
    return { data: response.data, error: undefined };
  } catch (e: any) {
    return { data: null, error: e.response.data };
  }
};

/**
 * Retrieves an array of variant IDs from the ProviderAPI endpoint.
 * @param provider - The provider name used to construct the ProviderAPI endpoint URL.
 * @returns A promise that resolves to an object containing the response data or an error message.
 * @example
 * const response = await getVariantIds("provider");
 * console.log(response.data); // Output: variant IDs
 * console.log(response.error); // Output: null or error message
 */
export const getVariantIds = async (
  provider: string
): Promise<ResponseBody> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TW_API_URL}sku/${provider}/variants`
    );
    return { data: response.data, error: undefined };
  } catch (e: any) {
    return { data: null, error: e.response.data };
  }
};
