import { Loader, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef } from "react";
import { Archetype, Cloth } from "./components";
import { useAppSelector } from "../../../../app/hooks";
import { TeamwearSelector } from "../../teamwearSlice";
import "./style.css";

const ThreeViewer = () => {
  const teamwear = useAppSelector(TeamwearSelector);

  const cameraRef = useRef<any>(null);

  return (
    <>
      <Canvas
        camera={{ position: [0, 0, 1.5], ref: cameraRef }}
        shadows={{ type: 1 }}
      >
        <ambientLight intensity={0.7} />
        <directionalLight position={[10, 10, 10]} intensity={0.3} castShadow />
        <directionalLight position={[-10, 10, 10]} intensity={0.3} castShadow />
        <directionalLight position={[0, 10, -10]} intensity={0.25} castShadow />

        <OrbitControls
          enablePan={false}
          maxDistance={2}
          minDistance={0.7}
          autoRotate
        />
        <Suspense fallback={null}>
          <group position={[0, 0.15, 0]}>
            <Archetype type={teamwear.selectedArchetype} />
            {teamwear.top.modelUrl.length > 0 &&
              <Cloth
                archetype={teamwear.selectedArchetype}
                section="top"
                scale={1}
                modelUrl={teamwear.top.modelUrl}
                preset={teamwear.top.presets[teamwear.top.variants[teamwear.selectedTopVariantIndex].preset]}
                position={[0, -1, 0]}
              />
            }

            {teamwear.bottom.modelUrl.length > 0 &&
              <Cloth
                archetype={teamwear.selectedArchetype}
                section="bottom"
                scale={1}
                modelUrl={teamwear.bottom.modelUrl}
                preset={teamwear.bottom.presets[teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].preset]}
                position={[0, -1, 0]}
              />
            }

            {teamwear.feet.modelUrl.length > 0 &&
              teamwear.selectedArchetype !== "child" &&
              <Cloth
                archetype={teamwear.selectedArchetype}
                section="feet"
                scale={1}
                modelUrl={teamwear.feet.modelUrl}
                preset={teamwear.feet.presets[teamwear.selectedFeetVariantIndex]}
                position={[0, -1, 0]}
              />
            }

          </group>
        </Suspense>
      </Canvas>
      <Loader
        dataInterpolation={(p) => `Cargando modelos ${Math.round(p)}%`}
        dataStyles={{ "fontSize": "12px", "marginTop": "-50px" }}
        containerStyles={{ 
          "background": "#1d1d1d", 
          "opacity": "0.4",
          "position": "fixed",
          "top": 0,
          "left": 0,
          "width": "100%",
          "display": "flex"
        }}
        barStyles={{ "width": "200px", "height": "5px", "marginTop": "-55px" }}
        innerStyles={{ "width": "200px", "height": "5px" }}
      />
    </>
  );
};

export default ThreeViewer;
