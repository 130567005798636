import { useEffect, ReactNode } from "react";

type Props = {
  allowResize: boolean;
  autoRotateDelay?: number;
  autoRotationSpeed?: `${number}deg` | `${number}rad` | `${number}%`;
  children?: ReactNode;
  glb: string;
  interactionPrompt?: "auto" | "none"
  onClick: () => void;
  onLoad: (e: Event) => void;
  onCameraChange?: (e: CustomEvent) => void;
  placement: ARPlacement;
  usdz?: string;
}

const ModelViewer = ({
  allowResize,
  autoRotateDelay = 3000,
  autoRotationSpeed = "100%",
  children,
  glb,
  interactionPrompt = "auto",
  onClick,
  onLoad,
  onCameraChange,
  placement,
  usdz,
}: Props) => {



useEffect(() => {
  const viewer = document.getElementById("model-viewer")
  
  const loadListener = (e: Event) => {
    onLoad(e);
  }
  
  const cameraChangeListener = (e: Event) => {        
    onCameraChange && onCameraChange(e as CustomEvent)
  }

  viewer!.addEventListener("load", loadListener);

  if (onCameraChange) {
    viewer!.addEventListener("camera-change", cameraChangeListener);
  }

  return () => {
    viewer!.removeEventListener("load", loadListener);
    
    if (onCameraChange) {
      viewer!.removeEventListener("camera-change", cameraChangeListener);
    }
  }

}, [onLoad, onCameraChange]);

  return (
    <model-viewer
      class="model-viewer"
      id="model-viewer"
      src={glb}
      ios-src={usdz}
      alt="AppAR"
      ar
      ar-modes="webxr scene-viewer quick-look"
      ar-scale={allowResize ? "auto" : "fixed"}
      ar-placement={placement}
      camera-controls
      interaction-prompt={interactionPrompt}
      auto-rotate-delay={autoRotateDelay}
      rotation-per-second={autoRotationSpeed}
      touch-action="pan-y pan-x"
      ar-status="not-presenting"
      autoplay
      onClick={() => {
        onClick();
      }}
      auto-rotate
      load={onLoad}
    >
      {children}
    </model-viewer>
  )
};

export default ModelViewer;
