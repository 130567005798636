import axios from "axios";
import { transformKeys } from "../utils/generic";

export const getCollection = async (
  id: ID,
  lang: string = "es"
): Promise<ResponseBody<Collection>> => {
  let data: Collection | undefined = undefined;
  let error: string | undefined = undefined;
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + `collection/${id}?lang=${lang}`
    );
    if (res) {
      data = transformKeys<Collection>(res.data);
    }
  } catch (e: any) {
    error = e!.response.data as string;
  }
  return { data, error };
};

export const addAugmentation = async (collectionId: ID): Promise<ResponseBody<any>> => {
    let data 
    let error
    try {
        const res = await axios.put(
          process.env.REACT_APP_API_URL +
          "collection/" +
          collectionId +
          "/augmentation"
        );
        if (res) {
          data = res.data
        }
    } catch (e: any) {
      error = e.responde.data as string
    }

    return { data, error}
};
