import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { TeamwearSelector, setBottomVariantIndex, setFeetVariantIndex, setTopVariantIndex, setVisibility } from "../../teamwearSlice";
import ColorButton from "../ColorButton";
import "./style.css";
import { useState, useEffect } from "react";
import NumericStepperInput from "../NumericStepperInput";
import { capitalize } from "../../../../utils/generic";
import PriceTag from "../PriceTag";

type Props = {
  isMobile?: boolean
}

const InfoPanel = ({ isMobile }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [quantities, setQuantities] = useState({
    top: 0,
    bottom: 0,
    feet: 0
  })
  const teamwear = useAppSelector(TeamwearSelector);

  useEffect(() => {
    switch (teamwear.activeSection) {
      case "top":
        dispatch(setVisibility({ ...teamwear.visibility, top: true }));
        break;
      case "bottom":
        dispatch(setVisibility({ ...teamwear.visibility, bottom: true }));
        break;
      case "feet":
        dispatch(setVisibility({ ...teamwear.visibility, feet: true }));
        break;
    }

    if (teamwear.selectedArchetype === "child") {
      dispatch(setVisibility({
        ...teamwear.visibility,
        feet: false
      }))
    }
  }, [teamwear.activeSection]);

  const loadSizeButtons = (section: Section): JSX.Element[] => {
    const sizeButtons: JSX.Element[] = [];

    const variants = teamwear[section].variants.filter((variant) => variant.name === teamwear[section].variants[getVariantIndex(section)].name)

    variants.forEach((variant, index) => {
      sizeButtons.push(
        <button
          key={`size-btn-${index}`}
          className={
            variant.prodId === teamwear[section].variants[getVariantIndex(section)].prodId
              ? "tw-btn-size btn-size-active" : "tw-btn-size"
          }
          onClick={() => {
            setVariantIndex(section, variant)
          }}
        >
          {variant.size.toUpperCase()}
        </button>
      )

    })
    return sizeButtons
  }

  // Mapping of section to the respective dispatch function
  const variantDispatchers = {
    bottom: setBottomVariantIndex,
    feet: setFeetVariantIndex,
    top: setTopVariantIndex,
  };

  const getVariantIndex = (section: Section): number => {
    return teamwear[
      `selected${capitalize(section)}VariantIndex` as keyof typeof teamwear
    ] as number;
  };

  const setVariantIndex = (section: Section, variant: SkuVariation) => {
    const dispatcher = variantDispatchers[section];
    if (dispatcher) {
      dispatch(dispatcher(teamwear[section].variants.indexOf(variant)));
    }
  };

  return (
    <>
      <div className="tw-info-container">
        <div>
          <h5 className="summary-text">{t("teamwear.summary")}</h5>
        </div>
        <hr />
        {teamwear.top!.modelUrl!.length > 0 &&
          teamwear.top!.name!.length > 0 &&
          teamwear.visibility.top && (
            <>
              <div className="tw-card-container">
                <div className="container-btn-close-infoPanel-item">
                  <button
                    className="btn-close-infoPanel-item"
                    onClick={() => dispatch(setVisibility({ ...teamwear.visibility, top: false }))}
                  >
                    <img
                      src="teamwear/icons/close.svg"
                      alt="close-button"
                      className="tw-close-btn-infopanel"
                    />
                  </button>
                </div>
                <h6 className="title-section-infoPanel">
                  {teamwear.top.name}
                </h6>
                <div>
                  <PriceTag
                    price={teamwear.top.variants[teamwear.selectedTopVariantIndex].price}
                    listPrice={teamwear.top.variants[teamwear.selectedTopVariantIndex].listPrice}
                  />
                </div>
                <p className="tw-sku-section">SKU: {teamwear.top.variants[teamwear.selectedTopVariantIndex].prodId}</p>
                <div className="tw-color-section-div">
                  Color: {teamwear.top.variants[teamwear.selectedTopVariantIndex].name}{" "}
                  <ColorButton
                    color={teamwear.top.variants[teamwear.selectedTopVariantIndex].color}
                    style={{
                      height: "18px",
                      width: "18px",
                      pointerEvents: "none",
                      cursor: "default !important",
                      margin: 0,
                      marginLeft: "10px",
                    }}
                    clickable={false}
                  />
                </div>
                <p className="tw-title-resume-item">Talla:</p>
                <div className="tw-size-section">
                  {loadSizeButtons("top")}
                </div>
                <div>
                  <p className="tw-title-resume-item mt-1">Cantidad:</p>
                  {teamwear.top.variants[teamwear.selectedTopVariantIndex].quantity ?
                    <NumericStepperInput
                      value={quantities.top}
                      onChange={(value) => {
                        setQuantities({ ...quantities, top: value })
                      }}
                      maxNumber={teamwear.top.variants[teamwear.selectedTopVariantIndex].quantity}
                    />
                    :
                    <p className="tw-no-stock-text mt-1">No hay stock en el producto seleccionado</p>
                  }
                </div>
              </div>
              <hr />
            </>
          )}
        {teamwear.bottom.modelUrl.length > 0 &&
          teamwear.bottom.name.length > 0 &&
          teamwear.visibility.bottom && (
            <>
              <div className="tw-card-container">
                <div className="container-btn-close-infoPanel-item">
                  <button
                    className="btn-close-infoPanel-item"
                    onClick={() =>
                      dispatch(setVisibility({ ...teamwear.visibility, bottom: false }))
                    }
                  >
                    <img
                      src="teamwear/icons/close.svg"
                      alt="close-button"
                      className="tw-close-btn-infopanel"
                    />
                  </button>
                </div>
                <h6 className="title-section-infoPanel">
                  {teamwear.bottom.name}
                </h6>
                <div>
                  <PriceTag
                    price={teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].price}
                    listPrice={teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].listPrice}
                  />
                </div>
                <p className="tw-sku-section">SKU: {teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].prodId}</p>
                <div className="tw-color-section-div">
                  Color: {teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].name}{" "}
                  <ColorButton
                    color={teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].color}
                    style={{
                      height: "18px",
                      width: "18px",
                      pointerEvents: "none",
                      cursor: "default !important",
                      margin: 0,
                      marginLeft: "10px",
                    }}
                    clickable={false}
                  />
                </div>
                <p className="tw-title-resume-item">Talla:</p>
                <div className="tw-size-section">
                  {loadSizeButtons("bottom")}
                </div>
                <div>
                  <p className="tw-title-resume-item mt-1">Cantidad:</p>
                  {teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].quantity ?
                    <NumericStepperInput
                      value={quantities.bottom}
                      onChange={(value) => {
                        setQuantities({ ...quantities, bottom: value })
                      }}
                      maxNumber={teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].quantity}
                    />
                    :
                    <p className="tw-no-stock-text mt-1">No hay stock en el producto seleccionado</p>
                  }

                </div>
              </div>
              <hr />
            </>
          )}
        {teamwear.feet.modelUrl.length > 0 &&
          teamwear.feet.name.length > 0 &&
          teamwear.visibility.feet && (
            <>
              <div className="tw-card-container">
                <div className="container-btn-close-infoPanel-item">
                  <button
                    className="btn-close-infoPanel-item"
                    onClick={() => dispatch(setVisibility({ ...teamwear.visibility, feet: false }))}
                  >
                    <img
                      src="teamwear/icons/close.svg"
                      alt="close-button"
                      className="tw-close-btn-infopanel"
                    />
                  </button>
                </div>
                <h6 className="title-section-infoPanel">
                  {teamwear.feet.name}
                </h6>
                <div>
                  <PriceTag
                    price={teamwear.feet.variants[teamwear.selectedFeetVariantIndex].price}
                    listPrice={teamwear.feet.variants[teamwear.selectedFeetVariantIndex].listPrice}
                  />
                </div>

                <p className="tw-sku-section">SKU: {teamwear.feet.variants[teamwear.selectedFeetVariantIndex].prodId}</p>
                <div className="tw-color-section-div">
                  Color: {teamwear.feet.variants[teamwear.selectedFeetVariantIndex].name}{" "}
                  <ColorButton
                    color={teamwear.feet.variants[teamwear.selectedFeetVariantIndex].color}
                    style={{
                      height: "18px",
                      width: "18px",
                      pointerEvents: "none",
                      cursor: "default !important",
                      margin: 0,
                      marginLeft: "10px",
                    }}
                    clickable={false}
                  />
                </div>
                <p className="tw-title-resume-item">Talla:</p>
                <div className="tw-size-section">
                  {loadSizeButtons("feet")}
                </div>
                <div>
                  <p className="tw-title-resume-item mt-1">Cantidad:</p>
                  {teamwear.feet.variants[teamwear.selectedFeetVariantIndex].quantity ?
                    <NumericStepperInput
                      value={quantities.feet}
                      onChange={(value) => {
                        setQuantities({ ...quantities, feet: value })
                      }}
                      maxNumber={teamwear.feet.variants[teamwear.selectedFeetVariantIndex].quantity}
                    />
                    :
                    <p className="tw-no-stock-text mt-1">No hay stock en el producto seleccionado</p>

                  }

                </div>
              </div>
            </>
          )}
        <button
          className="tw-info-panel-add-to-cart-button"
          onClick={() => {
            window.parent?.postMessage({
              type: "ADD_TO_CART",
              content: {
                top: {
                  id: teamwear.visibility.top && quantities.top > 0 ? teamwear.top.variants[teamwear.selectedTopVariantIndex].prodId : null,
                  sellerId: teamwear.top.variants[teamwear.selectedTopVariantIndex].sellerId,
                  quantity: quantities.top
                },
                bottom: {
                  id: teamwear.visibility.bottom && quantities.bottom > 0 ? teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].prodId : null,
                  sellerId: teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].sellerId,
                  quantity: quantities.bottom
                },
                feet: {
                  id: teamwear.visibility.feet && quantities.feet > 0 ? teamwear.feet.variants[teamwear.selectedFeetVariantIndex].prodId : null,
                  sellerID: teamwear.selectedArchetype !== "child" ? teamwear.feet.variants[teamwear.selectedFeetVariantIndex].sellerId : '',
                  quantity: quantities.feet
                }
              }
            }, "*")
            window.postMessage({
              type: "OPEN_MOBILE_SUMMARY",
              content: false
            })
          }}
        >
          {t("teamwear.add_to_cart")}
        </button>
        <button
          className="tw-info-panel-go-to-store-button"
          onClick={() => {
            window.open(
              "https://www.underarmour.cl/153?map=productClusterIds",
              "_blank",
              "noreferrer"
            );
          }}
        >
          {t("teamwear.see_all_teamwear")}
        </button>
        {/* <button
            className="tw-info-panel-share-button"
            onClick={() => {
              window.parent.postMessage({
                type: "SHARE_URL",
                content: "Se ha copiado la URL del conjunto seleccionado."
              }, '*')
            }}
          >
            <img
              src="teamwear/icons/share.png" alt="compartir"
              className="share-image"
            />
          </button> */}
      </div>
    </>
  );
};

export default InfoPanel;
