import { useEffect, useState } from 'react'
import './style.css'

type Props = {
    price: number,
    listPrice?: number
}

const PriceTag = ({ price, listPrice }: Props) => {

    const [hasDiscount, setHasDiscount] = useState<boolean>(false)

    useEffect(() => {
        if (listPrice! > price) {
            setHasDiscount(true)
        } else {
            setHasDiscount(false)
        }
    }, [listPrice, price])

    const formatPrice = (amount: number) => {
        let strAmount = amount.toFixed(2);
        let [whole, decimal] = strAmount.split(".");
        whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return `$${whole}`;
    }

    const getPriceDiscount = () => {
        const discount = Math.floor(100 - ((price * 100) / listPrice!))
        return (`${discount}% OFF`)
    }

    return (
        <div className='price-tag'>
            {price > 0 ?
                <p className={`tw-price-label ${hasDiscount ? 'discount' : ''}`}>{formatPrice(price)}</p>
                :
                <p className='price-not-available'>Precio no disponible.</p>
            }
            {hasDiscount &&
                <>
                    <p className="tw-list-price-label">{formatPrice(listPrice!)}</p>

                    <div className='discount-container'>
                        <p>{getPriceDiscount()}</p>
                    </div>
                </>
            }
        </div>
    )
}

export default PriceTag