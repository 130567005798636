import { useTranslation } from "react-i18next"
import { DEFAULT_COLOR } from "../../constants"
import "./style.css"

type Props = {
    buttonColor?: Color,
    message?: string,
    actionUrl?: string,
    displaced?: boolean
}

const CallToActionButton = ({ buttonColor = DEFAULT_COLOR, message, actionUrl, displaced }: Props) => {
    const { t, ready } = useTranslation()

    return (
        <>
            {ready && actionUrl &&
                <button
                    className={`show-store-collect ${displaced && "open-store-btn"}`}
                    style={{
                        maxWidth: 175,
                        minWidth: 175,
                        fontSize: 15,
                        width: 175,
                        color: buttonColor,
                        borderColor: buttonColor
                    }}
                    onClick={() => window.open(actionUrl)}
                >
                    {message || t("common.see_in_store")}
                </button>
            }

        </>
    )
}

export default CallToActionButton