import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ProductViewer from "./screens/ProductViewer";
import RoomBuilder from "./screens/RoomBuilder";
import FaceTryOn from "./screens/FaceTryOn";

import CollectiontViewer from "./screens/CollectionViewer";
import { Error } from "./components";
import PosterGenerator from "./screens/PosterGenerator";
import TeamwearViewer from "./screens/TeamwearViewer";

function App() {

  const [error, setError] = useState(null);

  return (
    <>
      <Suspense fallback={null}>
        {error === null ? (
          <Router>
            <Routes>
              <Route path="/face-try-on/:model_id?" element={<FaceTryOn />} />
              <Route path="/room-builder/:collection_id?" element={<RoomBuilder />} />
              <Route path="/collection/:collection_id?" element={<CollectiontViewer />} />
              <Route path="/poster" element={<PosterGenerator />} />
              <Route path="/teamwear-viewer" element={<TeamwearViewer />} />
              <Route path="/:model_id?" element={<ProductViewer />} />
            </Routes>
          </Router>
        ) : (
          <>
            <Error message={error} />
          </>
        )}
      </Suspense>

    </>
  );
}

export default App;
