import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { FilesetResolver, FaceLandmarker, FaceLandmarkerResult } from '@mediapipe/tasks-vision'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import { EyeElement } from './components';
import { eventDispatcher } from '../../utils/tools';
import { useProduct } from '../../hooks';
import { Link, useParams } from 'react-router-dom';

import './style.css'
import { DEFAULT_COLOR } from '../../constants';

interface CameraSettings {
  aspectRatio: number,
  height: number,
  width: number
}

const FaceTryOn = () => {

  const videoRef = useRef<ElementRef<"video">>(null)
  const canvasRef = useRef<ElementRef<"canvas">>(null)

  const { model_id } = useParams()

  const { product } = useProduct(model_id!)

  const [faceLandmarker, setFaceLandmarker] = useState<FaceLandmarker>();
  const [cameraSettings, setCameraSettings] = useState<CameraSettings>();

  const [hasMultipleCameras, setHasMultipleCameras] = useState(false);

  const landscape = window.innerWidth > window.innerHeight

  const constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      width: {
        //ideal: landscape ? 1920 : 1080,
        min: landscape ? 640 : 480,
      },
      height: {
        //ideal: landscape ? 1080: 1920,
        min: landscape ? 480 : 640,
      },
      facingMode: {
        ideal: "user"
      },
      advanced: [
        { aspectRatio: landscape ? 4 / 3 : 16 / 9 },
        { frameRate: { min: 30 } },
        { frameRate: { min: 20 } },
      ]
    },
  }

  let lastVideoTime = -1;
  let results: FaceLandmarkerResult | undefined = undefined

  useEffect(() => {
    checkForMultipleCameras()
    initFaceLandmarker()
    localStorage.setItem("color", product?.augmentationButton?.color!)

  }, [])

  useEffect(() => {
    if (faceLandmarker && videoRef) {
      initCamera()
    }
  }, [faceLandmarker])

  const initCamera = async () => {
    try {

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoTrackSettings = stream.getVideoTracks()[0].getSettings();

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.addEventListener("loadeddata", predictWebcam);
        videoRef.current.play()
      }

      setCameraSettings({
        aspectRatio: videoTrackSettings.aspectRatio || 
          (landscape ? 
            videoTrackSettings.height! / videoTrackSettings.width! :
            videoTrackSettings.width! / videoTrackSettings.height!),
        height: videoTrackSettings.height!,
        width: videoTrackSettings.width!,
      });
    } catch (error) {
      console.error('Error initializing camera: ', error);
    }
  };

  const switchCamera = () => {
    (constraints.video! as MediaTrackConstraints).facingMode = (constraints.video! as MediaTrackConstraints).facingMode === "user" ? "environment" : "user";
    initCamera();
  }

  const checkForMultipleCameras = async () => {
    try {
      videoRef.current?.setAttribute('autoplay', '')
      videoRef.current?.setAttribute('muted', '')
      videoRef.current?.setAttribute('playsinline', '')
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      if (videoDevices.length > 1) {
        setHasMultipleCameras(true);
      }
    } catch (error) {
      console.error('Error checking for multiple cameras: ', error);
    }
  };

  const initFaceLandmarker = async () => {
    console.log("initfacelandmarker");

    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm"
    )

    const result = await FaceLandmarker.createFromOptions(
      vision,
      {
        baseOptions: {
          modelAssetPath: "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task",
          delegate: "GPU",
        },
        runningMode: "VIDEO",
        numFaces: 1,
        canvas: canvasRef.current!,
        minFaceDetectionConfidence: 0.7,
        minFacePresenceConfidence: 0.7,
        minTrackingConfidence: 0.7
      }
    )

    setFaceLandmarker(result)
  }

  const predictWebcam = () => {
    let nowInMs = Date.now();
    if (lastVideoTime !== videoRef.current!.currentTime) {
      lastVideoTime = videoRef.current!.currentTime;
      results = faceLandmarker!.detectForVideo(videoRef.current!, nowInMs)
    }

    if (results?.faceLandmarks) {
      for (const landmarks of results.faceLandmarks) {
        eventDispatcher("setNosePos", landmarks[6]);
        eventDispatcher("setREyePos", landmarks[33]);
        eventDispatcher("setLEyePos", landmarks[263]);
        eventDispatcher("setUpperLipPos", landmarks[164]);
        eventDispatcher("updateLandmarks", landmarks)
      }
    }
    window.requestAnimationFrame(predictWebcam)
  }

  return (
    <>
      <div className='fto-navigation-bar'>
        <button className='back-button'
          onClick={() => {
            window.requestAnimationFrame(() => { })
          }}
        >
          <Link to={`/${model_id}`}>
            <ArrowBackIosNewIcon
              style={{
                color: localStorage.getItem("color") || DEFAULT_COLOR,
                width: "30px",
                height: "30px"
              }}
            />
          </Link>
        </button>
      </div>
      <div className='fto-options-bar'>
        {hasMultipleCameras &&
          <button className='change-camera-button'
            onClick={() => {
              switchCamera()
            }}
          >
            <FlipCameraIosIcon
              className='change-camera-icon'
              style={{
                color: localStorage.getItem("color") || DEFAULT_COLOR,
                width: "40px",
                height: "40px"
              }}
            />
          </button >
        }
      </div>
      <video
        ref={videoRef}
        muted
        autoPlay
        playsInline
        style={{
          clear: "both",
          display: "none",
          transform: "rotateY(180deg)",
          WebkitTransform: "rotateY(180deg)",
          position: "relative",
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
          transform: "rotateY(180deg)",
          WebkitTransform: "rotateY(180deg)",
          display: "none"
        }}
      />
      {videoRef.current && cameraSettings && product &&
        <EyeElement
          videoRef={videoRef!}
          cameraSettings={cameraSettings}
          product={product}
        />
      }
    </>
  )
}

export default FaceTryOn;