import { useTranslation } from "react-i18next";

import "./style.css"
import ImageButton from "../ImageButton";
import ColorButton from "../ColorButton";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    TeamwearSelector,
    setActiveSection,
    setBottom,
    setBottomVariantIndex,
    setFeet,
    setFeetVariantIndex,
    setTop,
    setTopVariantIndex
} from "../../teamwearSlice";
import { useEffect, useRef } from "react";
import { Option } from "../../types";
import { useTeamWear } from "../../../../hooks";

type Props = {
    data: Sku[];
}

const Selector = ({ data }: Props) => {

    const { t } = useTranslation()

    const teamwear = useAppSelector(TeamwearSelector)

    const dispatch = useAppDispatch()

    const { setInitialData } = useTeamWear()

    const colorSectionRef = useRef<HTMLDivElement>(null)
    const mainContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (data) {
            loadColors()
        }
    }, [teamwear.selectedArchetype, data])

    const setSection = (element: Sku): void => {
        const actionMap = {
            top: () => {
                dispatch(setTop(element));
                dispatch(setTopVariantIndex(0));
            },
            bottom: () => {
                dispatch(setBottom(element));
                dispatch(setBottomVariantIndex(0));
            },
            feet: () => {
                if (teamwear.selectedArchetype !== "child") {
                    dispatch(setFeet(element));
                    dispatch(setFeetVariantIndex(0));
                }
            },
        };

        const action = actionMap[teamwear.activeSection];

        if (typeof action === 'function') {
            action();
        }
    };


    const setColor = (element: any): void => {
        const firstVariant = teamwear[teamwear.activeSection].variants.findIndex((variant) => variant.prodId == element.sizes[0].prodId)
        const actionMap = {
            top: () => dispatch(setTopVariantIndex(firstVariant)),
            bottom: () => dispatch(setBottomVariantIndex(firstVariant)),
            feet: () => dispatch(setFeetVariantIndex(firstVariant)),
        };

        if (actionMap[teamwear.activeSection]) {
            actionMap[teamwear.activeSection]();
        }
    };

    const nameIsEqual = (name: string) => {
        const variantMap = {
            bottom: () => name === teamwear.bottom.variants[teamwear.selectedBottomVariantIndex].name,
            feet: () => name === teamwear.feet.variants[teamwear.selectedFeetVariantIndex].name,
            top: () => name === teamwear.top.variants[teamwear.selectedTopVariantIndex].name
        };

        return (variantMap[teamwear.activeSection] || (() => false))();
    };

    const loadModels = (): Array<JSX.Element> => {
        const modelOptions: Array<JSX.Element> = [];
        data.forEach((element: Sku, index: number) => {
            if (teamwear.activeSection === element.section && teamwear.selectedArchetype === element.archetype) {

                modelOptions.push(
                    <ImageButton
                        className="tw-selector-image-button"
                        key={`model-${index}`}
                        selected={teamwear[teamwear.activeSection].sku! === element.sku}
                        imageSrc={element.preview}
                        onClick={() => {
                            setSection(element)
                            mainContainerRef.current?.scrollTo({
                                top: colorSectionRef.current?.offsetTop,
                                behavior: "smooth"
                            })
                        }}
                    >{element.name}</ImageButton>
                )
            }
        })

        if (modelOptions.length < 1) {
            modelOptions.push(<h6 key={'empty'}>{t("teamwear.no_models")}</h6>)
        }

        return modelOptions;
    }

    const loadColors = (): JSX.Element[] => {
        const colorElementOptions: JSX.Element[] = [];
        const options: Option[] = []

        const sku = teamwear[teamwear.activeSection]
        const variants: SkuVariation[] = sku.variants.reduce((acc: any, variant: SkuVariation, index: number) => {
            const { color, size, prodId, name } = variant

            if (!acc[color]) {
                acc[color] = { name, color, sizes: [] }
            }
            acc[color].index = index
            acc[color].name = name
            acc[color].sizes.push({
                size: size,
                prodId: prodId
            })
            return acc
        }, {})

        options.push({
            name: sku.name,
            sku: sku.sku,
            section: sku.section,
            archetype: sku.archetype,
            variants: Object.values(variants)
        })

        const cloth = options.filter(
            (element: Option) =>
                element.archetype === teamwear.selectedArchetype
                && element.section === teamwear.activeSection);

        if (cloth[0]) {
            colorElementOptions.push(
                ...cloth[0].variants.map((element: any, index: number) => (
                    <ColorButton
                        key={`model-${index}`}
                        color={element.color}
                        onClick={() => {
                            setColor(element)
                        }}
                        text={element.name}
                        selected={nameIsEqual(element.name)}
                        style={{
                            width: '50px', height: '50px',
                            cursor: 'pointer'
                        }}
                    />
                ))
            );
        }

        return colorElementOptions;
    };

    return (
        <>
            <div className="tw-selector-main-container" ref={mainContainerRef}>
                <div className="tw-selector-section">
                    <h5 className="tw-title-selector-section">{t("teamwear.gender")}</h5>
                    <div className="tw-selector-button-container">
                        <ImageButton
                            className="tw-selector-image-button"
                            key={`archetypes-female`}
                            selected={teamwear.selectedArchetype === "female"}
                            imageSrc={"https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/images/archetypes/female.jpg"}
                            onClick={() => {
                                setInitialData("female", data)
                            }}
                        >{t("teamwear.female") as string}</ImageButton>
                        <ImageButton
                            className="tw-selector-image-button"
                            key={`archetypes-male`}
                            selected={teamwear.selectedArchetype === "male"}
                            imageSrc={"https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/images/archetypes/male.jpg"}
                            onClick={() => {
                                setInitialData("male", data)
                            }}
                        >{t("teamwear.male") as string}</ImageButton>
                        <ImageButton
                            className="tw-selector-image-button"
                            key={`archetypes-children`}
                            selected={teamwear.selectedArchetype === "child"}
                            imageSrc={"https://appar-data.s3.us-west-1.amazonaws.com/teamwear/under-armour/images/archetypes/child.png"}
                            onClick={() => {
                                setInitialData("child", data)
                            }}
                        >{t("teamwear.child") as string}</ImageButton>
                    </div>
                </div>
                <br />
                <div className="tw-selector-section">
                    <hr />
                    <h5 className="tw-title-selector-section">{t("teamwear.product")}</h5>
                    <div className="tw-selector-button-container">
                        <ImageButton
                            className="tw-selector-image-button"
                            key={`section-top`}
                            selected={teamwear.activeSection === "top"}
                            imageSrc={"teamwear/images/section/top.jpg"}
                            onClick={() => {
                                dispatch(setActiveSection("top"))
                                mainContainerRef.current?.scrollTo({
                                    top: colorSectionRef.current?.offsetTop,
                                    behavior: "smooth"
                                })
                            }}
                        >{t("teamwear.t-shirt") as string}</ImageButton>
                        <ImageButton
                            className="tw-selector-image-button"
                            key={`section-bottom`}
                            selected={teamwear.activeSection === "bottom"}
                            imageSrc={"teamwear/images/section/bottom.png"}
                            onClick={() => {
                                dispatch(setActiveSection("bottom"))
                                mainContainerRef.current?.scrollTo({
                                    top: colorSectionRef.current?.offsetTop,
                                    behavior: "smooth"
                                })
                            }}
                        >{t("teamwear.short") as string}</ImageButton>
                        <ImageButton
                            className="tw-selector-image-button"
                            key={`section-feet`}
                            selected={teamwear.activeSection === "feet"}
                            imageSrc={"teamwear/images/section/feet.png"}
                            onClick={() => {
                                dispatch(setActiveSection("feet"))
                                mainContainerRef.current?.scrollTo({
                                    top: colorSectionRef.current?.offsetTop,
                                    behavior: "smooth"
                                })
                            }}
                        >{t("teamwear.sock") as string}</ImageButton>
                    </div>
                </div>
                <br />
                <div className="tw-selector-section">
                    <hr />
                    <h5 className="tw-title-selector-section">{t("teamwear.model")}</h5>
                    <div className="tw-selector-button-container">
                        {
                            loadModels()
                        }
                    </div>
                </div>
                {!(teamwear.activeSection === "feet" && teamwear.selectedArchetype === "child") &&
                    teamwear[teamwear.activeSection]!.modelUrl && (
                        <div className="tw-selector-section">
                            <hr />
                            <h5 className="tw-title-selector-section">{t("teamwear.colour")}</h5>
                            <div className="tw-selector-color-selector" ref={colorSectionRef}>
                                {
                                    loadColors()
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default Selector;